import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { Specialty } from "@/types/general";
import { useBoundStore } from "@/hooks";
import { updateSpecialtiesConfig } from "@/actions/configs";

export const DeleteSpecialty = ({
  specialty,
}: {
  specialty: Specialty | undefined;
}) => {
  const { startLoading, stopLoading, loading } = useBoundStore();
  const { closeDialog } = useDialog();

  const onSubmit = async () => {
    startLoading();
    const response = await updateSpecialtiesConfig({
      ...specialty,
      specialty_code: specialty?.specialty_code,
      rowversion: specialty?.rowversion,
      option: "eliminar",
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Especialidad eliminada con éxito",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar especialidad</DialogTitle>
      </DialogHeader>
      <span>
        Estás por eliminar una especialidad y esta acción es irreversible.
      </span>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit}>Eliminar</Button>
        )}
      </div>
    </DialogContent>
  );
};
