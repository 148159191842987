import { Patient } from "@/types/patients";
import { INITIAL_PATIENT_DATA } from "@/libs/patients";

type State = {
  patient: typeof INITIAL_PATIENT_DATA | Patient;
  patient_list: Patient[];
  ref_header: any;
};

type Actions = {
  setPatientData: (patient: Patient) => void;
  setPatientsList: (list: Patient[]) => void;
  resetPatiensList: () => void;
  resetPatienData: () => void;
  updatePatient: (key: string, data: any) => void;
  setChildProps: (props: { ref: React.RefObject<HTMLDivElement> }) => void;
};

export const INIT_STATE: State = {
  patient: INITIAL_PATIENT_DATA,
  patient_list: [],
  ref_header: null,
};

type PatienstStore = State & Actions;

export const PatientsSlice = (
  set: (partial: Partial<PatienstStore>) => void,
  get: () => PatienstStore
): PatienstStore => ({
  ...INIT_STATE,
  setPatientsList: (list: Patient[]) => {
    const STATE = get();
    set({
      ...STATE,
      patient_list: list,
    });
  },
  resetPatiensList: () => {
    const STATE = get();
    set({
      ...STATE,
      patient_list: [],
    });
  },
  setPatientData: (patient: Patient) => {
    const STATE = get();

    set({
      ...STATE,
      patient: { ...STATE.patient, ...patient },
    });
  },
  updatePatient: (key: string, data: any) => {
    const STATE = get();

    set({
      ...STATE,
      patient: { ...STATE.patient, [key]: data },
    });
  },
  resetPatienData: () => {
    const STATE = get();
    set({
      ...STATE,
      patient: INITIAL_PATIENT_DATA,
    });
  },
  setChildProps: (ref: any) => {
    const STATE = get();

    set({
      ...STATE,
      ref_header: ref,
    });
  },
});
