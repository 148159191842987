"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import Link from "next/link";
import classNames from "classnames";
import { LuChevronLeft, LuInfo } from "react-icons/lu";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  Offices,
  Permissions,
  Process,
  Professional,
  User,
} from "@/types/general";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TooltipComponent,
} from "@/components/molecules";
import {
  Button,
  buttonVariants,
  Card,
  CardDescription,
  CardTitle,
  Checkbox,
  Combobox,
  Input,
  Separator,
} from "@/components/atoms";
import { toast, useIsDemo, useKeypress } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { updateUsersConfig } from "@/actions/configs";
import { ActionsPermissions } from "./actions";
import { formSchema, resolver } from "./schema";
import { Loading } from "./loading";

export function UsersForm({
  professionals,
  offices,
  permissions,
  idUser,
  users,
}: {
  professionals: Professional[];
  offices: Offices[];
  permissions: Process[];
  idUser?: string;
  users: User[];
}) {
  const { startLoading, stopLoading, loading, user } = useBoundStore();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const userInputField = React.useRef<HTMLInputElement | null>(null);
  const USER = users?.find((user) => user.id_user === Number(idUser));
  const { isDemo } = useIsDemo(user.name || "");
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_user: USER?.id_user,
      name: USER?.name || "",
      permissions:
        USER?.permission.map((p: Permissions) => p.id_process_navigation) || [],
      user: USER?.user || "",
      enabled: USER?.enabled || true,
      is_dentist: USER?.is_dentist || false,
      is_dentist_data: USER?.is_dentist_data || false,
      is_dentist_guard: USER?.is_dentist_guard || false,
      is_dentist_schedule: USER?.is_dentist_schedule || false,
      is_supervisor: USER?.is_supervisor || false,
      use_usd: USER?.use_usd || false,
      id_office: USER?.id_office,
      all_offices: !USER?.id_office || false,
      id_professional: USER?.id_professional || null,
      password: "",
    },
  });
  const { id_user } = form.getValues();
  const { all_offices, is_dentist, is_supervisor } = form.watch();
  async function onSubmit(values: z.infer<typeof formSchema>) {
    if (isDemo) {
      return toast({
        variant: "warning",
        title: "Usuario de demostración",
        description: "Este usuario no puede realizar esta acción.",
      });
    }
    startLoading();
    const PERMISSIONS = values.permissions?.map((permission: Process) => {
      return {
        id_proceso: permission.id_process,
        item_sistema: permission.system_item,
        opcion: permission.option,
        orden: permission.order,
        descripcion: permission.description,
        rowversion: permission.rowversion,
      };
    });
    const OPTION = USER?.rowversion ? "modificar" : "nuevo";
    const response = await updateUsersConfig({
      ...values,
      permissions: PERMISSIONS,
      option: OPTION,
      prescriptions_tyc: USER?.prescriptions_tyc || null,
      rowversion: USER?.rowversion,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: USER
          ? "Usuario actualizado con exito"
          : "Usuario creado con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
    }

    stopLoading();
  }
  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return loading ? (
    <Loading />
  ) : (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col items-end"
      >
        <Card className="w-full grid grid-cols-12 gap-4 p-4 overflow-hidden bg-white h-[calc(100vh-140px)] overflow-y-scroll">
          <div className="col-span-full w-full flex justify-between items-start">
            <div>
              <CardTitle className="flex items-center gap-2 mb-2">
                <Link
                  href="/dashboard/configuration/sistem/users"
                  className={classNames(
                    buttonVariants({ variant: "outline", size: "icon" })
                  )}
                >
                  <LuChevronLeft className="size-4" />
                  <span className="sr-only">Back</span>
                </Link>
                Usuarios
              </CardTitle>
              <CardDescription className="flex flex-col mt-5">
                <span className="!font-bold text-slate-900 text-lg flex items-center gap-x-2 mb-1">
                  <p className="rounded-full size-7 border-2 border-slate-900 flex items-center justify-center">
                    1
                  </p>
                  Datos del usuario
                </span>
                Agrega la información del nuevo usuario.
              </CardDescription>
            </div>
            <TooltipComponent
              trigger={<LuInfo />}
              content={<p>* Campos obligatorios.</p>}
            />
          </div>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>Nombre*</FormLabel>
                <FormControl>
                  <Input {...field} ref={userInputField} autoFocus />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="user"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Usuario*</FormLabel>
                <FormControl>
                  <Input {...field} disabled={!!id_user} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="enabled"
            render={({ field }) => (
              <FormItem className="col-span-4 flex flex-row-reverse gap-2 items-center justify-end">
                <div className="space-y-1 leading-none">
                  <FormLabel>Habilitado</FormLabel>
                  <FormDescription className="text-xs">
                    Indica que el usuario se encuentra habilitado.
                  </FormDescription>
                </div>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Contraseña*</FormLabel>
                <FormControl>
                  <div className="relative flex justify-between">
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="******"
                      {...field}
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-3 cursor-pointer text-slate-600"
                    >
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password_confirm"
            render={({ field }) => (
              <FormItem className="col-span-4">
                <FormLabel>Repetir contraseña*</FormLabel>
                <FormControl>
                  <div className="relative flex justify-between">
                    <Input
                      type={showPassword ? "text" : "password"}
                      {...field}
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-3 cursor-pointer text-slate-600"
                    >
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_supervisor"
            render={({ field }) => (
              <FormItem className="col-span-3 col-start-1 flex flex-row-reverse gap-2 items-center justify-end">
                <div className="space-y-1 leading-none">
                  <FormLabel>Es supervisor</FormLabel>
                  <FormDescription className="text-xs">
                    Cuenta con todos lo permisos.
                  </FormDescription>
                </div>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(value) => {
                      const {
                        id_professional,
                        is_dentist_schedule,
                        is_dentist_guard,
                      } = form.watch();
                      field.onChange(value);
                      if (value) {
                        form.setValue("is_dentist", false);
                        id_professional &&
                          form.setValue("id_professional", null);
                        is_dentist_schedule &&
                          form.setValue("is_dentist_schedule", false);
                        is_dentist_guard &&
                          form.setValue("is_dentist_guard", false);
                      }
                    }}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_dentist"
            render={({ field }) => (
              <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                <div className="space-y-1 leading-none">
                  <FormLabel>Es odontólogo</FormLabel>
                  <FormDescription className="text-xs">
                    Indica si ya esta en la cartilla de profesionales.
                  </FormDescription>
                </div>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(value: boolean) => {
                      const {
                        id_professional,
                        is_dentist_schedule,
                        is_dentist_guard,
                      } = form.watch();
                      field.onChange(value);
                      if (!value) {
                        id_professional &&
                          form.setValue("id_professional", null);
                        is_dentist_schedule &&
                          form.setValue("is_dentist_schedule", false);
                        is_dentist_guard &&
                          form.setValue("is_dentist_guard", false);
                        return;
                      } else {
                        form.setValue("is_supervisor", false);
                      }
                    }}
                    ref={field.ref}
                    disabled={is_supervisor}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Combobox
            data={professionals}
            dataKey="id_professional"
            form={form}
            value="name"
            title="profesional"
            showInputSearch={false}
            className="col-span-3"
            classContent="!w-80"
            label="Profesional"
            disabled={!is_dentist}
          />
          <FormField
            control={form.control}
            name="is_dentist_schedule"
            render={({ field }) => (
              <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                <div className="space-y-1 leading-none">
                  <FormLabel>Agenda de Turnos</FormLabel>
                  <FormDescription className="text-xs">
                    Puede ver solo su agenda
                  </FormDescription>
                </div>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                    disabled={!is_dentist}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_dentist_guard"
            render={({ field }) => (
              <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                <div className="space-y-1 leading-none">
                  <FormLabel>Acceso a guardias</FormLabel>
                  <FormDescription className="text-xs">
                    El usuario puede acceder a la agenda de la guardia.
                  </FormDescription>
                </div>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                    disabled={!is_dentist}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="use_usd"
            render={({ field }) => (
              <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                <div className="space-y-1 leading-none">
                  <FormLabel>Puede operar con otra moneda</FormLabel>
                  <FormDescription className="text-xs">
                    Permite a los usuarios cargar prestaciones y pagos en U$D.
                  </FormDescription>
                </div>
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    ref={field.ref}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {offices?.length > 0 && (
            <>
              <FormField
                control={form.control}
                name="all_offices"
                render={({ field }) => (
                  <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                    <div className="space-y-1 leading-none">
                      <FormLabel>Todas las sucursales</FormLabel>
                      <FormDescription className="text-xs">
                        Indica que el usuario trabaja en todas las sucursales.
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(value) => {
                          field.onChange(value);
                          value && form.setValue("id_office", null);
                        }}
                        ref={field.ref}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Combobox
                data={offices}
                dataKey="id_office"
                form={form}
                value="name"
                placeholder="Selecciona una sucursal..."
                title="sucursal"
                showInputSearch={false}
                className="col-span-3"
                classContent="!w-80"
                label="Sucursales"
                disabled={all_offices}
              />
              <FormField
                control={form.control}
                name="is_dentist_data"
                render={({ field }) => (
                  <FormItem className="col-span-3 flex flex-row-reverse gap-2 items-center justify-end">
                    <div className="space-y-1 leading-none">
                      <FormLabel>Datos Pacientes</FormLabel>
                      <FormDescription className="text-xs">
                        Este usuario puede modificar solamente sus datos.
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        ref={field.ref}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          <Separator className="col-span-12" />
          <CardDescription className="flex flex-col mt-5 col-span-full">
            <span className="!font-bold text-slate-900 text-lg flex items-center gap-x-2 mb-1">
              <p className="rounded-full size-7 border-2 border-slate-900 flex items-center justify-center">
                2
              </p>
              Permisos de usuario
            </span>
            Puede elegir si el usuario va a usar permisos de otro o definirlos
            individualmente.
          </CardDescription>
          <ActionsPermissions
            permissions={permissions}
            form={form}
            users={users}
          />
        </Card>
        <Button className="mt-4" type="submit">
          Guardar
        </Button>
      </form>
    </Form>
  );
}
