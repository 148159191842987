import { Benefit } from "@/types/general";

type ConfigurationData = {
  benefits: Array<Benefit>;
};

type State = {
  configuration: {
    [key: string]: ConfigurationData;
  };
};

type Actions = {
  setBenefits: (payload: { data: any }) => void;
};

type ConfigurationStore = State & Actions;

export const initState: State = {
  configuration: {
    medicalinsurances: {
      benefits: [],
    },
  },
};

export const ConfigurationSlice = (
  set: (partial: Partial<ConfigurationStore>) => void
): ConfigurationStore => ({
  ...initState,
  setBenefits: ({ data }: { data: any }) => {
    set({
      configuration: {
        medicalinsurances: {
          benefits: data,
        },
      },
    });
  },
});
