import React from "react";
import { useBoundStore } from "@/hooks";
import { Loading } from "@/components/organisms/reports/loading";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<any> = ({ action }) => {
  const {
    patient,
    general_params: { translations, user_params },
    permissions,
    user,
    loading,
    patient: {
      plan_navigation: { medical_insurance_navigation },
    },
  } = useBoundStore();
  const USE_USD = {
    params: user_params?.use_usd,
    user: user?.use_usd,
  };

  return (
    <div className="w-full col-span-full">
      {loading ? (
        <Loading heigth="300px" />
      ) : (
        <Data
          columns={Columns({
            translations,
            action,
            config: {
              permissions,
              USE_USD,
              is_supervisor: user.is_supervisor,
              medical_insurance_navigation,
            },
          })}
          data={patient.alls?.reverse() || []}
          config={USE_USD}
        />
      )}
    </div>
  );
};
