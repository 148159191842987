import React from "react";
import classNames from "classnames";
import { useBoundStore } from "@/hooks";
import { useStateAcordeon } from "@/hooks";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Button,
} from "@/components/atoms";
import { Form, Offices, Professional } from "@/types/general";
import { Patient } from "@/types/patients";
import { MedicinesPatientTable } from "@/components/organisms";
import { Medications } from "@/types/recipes";
import { LoadMedicine } from "../load-medicine";
import { formSchema } from "../schema";

export const Second: React.FC<{
  data: {
    financiers: any[];
    offices: Offices[];
    professionals: Professional[];
    patient: Patient;
  };
  onClick: () => void;
  resetProgressBar?: () => void;
  viewLoadMedicine: {
    hiddenLoadMedicine: boolean;
    setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
  };
  handlerClickProgressBar?: (value: number) => void;
  onSubmit?: (data: FormData) => void;
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
}> = ({
  form,
  setStep,
  viewLoadMedicine: { hiddenLoadMedicine, setHiddenLoadMedicine },
}) => {
  const { list_medications, selected_medication } = form.watch();
  const { ref_header } = useBoundStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const TABLE_VALIDATION = list_medications && list_medications.length >= 1;
  const onLoadMedicine = () => {
    setHiddenLoadMedicine(!hiddenLoadMedicine);
    form.setValue("selected_medication", {
      code: null,
      product_name: "",
      presentation: "",
      id_medicine_recipe: Math.random(),
      quantity: 1,
      only_generic: false,
      not_replace: false,
      diagnosis: { description: "", code: "", id_diagnosis: -1 },
      observations: "",
      prolonged_treatment: false,
      dosage: "",
      has_coverage: false,
      requires_approval: false,
      discount: 0,
      is_psychopharmaceutical: false,
      is_narcotic: false,
      is_controlled_sale: false,
      is_for_hiv: false,
      requires_duplicate: false,
      drug_name: "",
      register_number: "",
    });
  };
  const actions: any = {
    edit: (selectedRow: { [key: string]: any }) => {
      const MEDICINE_SELECTED = list_medications?.find(
        (medicine: { [key: string]: any }) =>
          medicine.id_medicine_recipe === selectedRow.id_medicine_recipe
      );

      form.setValue("selected_medication", {
        ...selected_medication,
        ...MEDICINE_SELECTED,
      });
      setHiddenLoadMedicine(!hiddenLoadMedicine);
    },
    delete: (selectedRow: { [key: string]: any }) => {
      const FILTER_MEDICINES = list_medications?.filter(
        (medicine: any) =>
          medicine.id_medicine_recipe !== selectedRow.id_medicine_recipe
      );

      form.setValue("list_medications", FILTER_MEDICINES);
    },
  };
  const action = (medicine: Medications, action: string) => {
    actions[action](medicine);
  };

  return (
    <Card className="!rounded-t-none m-w-h-[calc(100vh-350px)] !pb-0">
      <CardHeader>
        <CardTitle>Lista de medicamentos</CardTitle>
        <CardDescription>
          Carga los medicamentos relacionados a esta receta. Al guardar, podrás
          visualizar la lista completa de medicamentos.
        </CardDescription>
      </CardHeader>
      {!hiddenLoadMedicine ? (
        <div
          className={classNames(
            "!rounded-t-none !h-[calc(100vh-370px)] overflow-y-scroll",
            {
              "!h-[calc(100vh-315px)]":
                STATE_ACCORDION && STATE_ACCORDION !== "closed",
            }
          )}
        >
          <CardContent>
            <>
              {TABLE_VALIDATION && (
                <MedicinesPatientTable
                  data={list_medications}
                  action={action}
                />
              )}
              <div className="gap-5 mt-5 w-full flex justify-end">
                <Button
                  variant="ghost"
                  onClick={onLoadMedicine}
                  className="col-span-3 col-start-8"
                  disabled={list_medications?.length >= 3}
                >
                  Cargar medicamentos
                </Button>
                <Button
                  type="button"
                  disabled={!TABLE_VALIDATION}
                  onClick={() => setStep(2)}
                  className="col-span-2"
                >
                  Siguiente
                </Button>
              </div>
            </>
          </CardContent>
          <CardDescription className="flex justify-center">
            {!TABLE_VALIDATION && "Todavía no posee ningún medicamento cargado"}
          </CardDescription>
        </div>
      ) : (
        <LoadMedicine
          setHiddenLoadMedicine={setHiddenLoadMedicine}
          form={form}
        />
      )}
    </Card>
  );
};
