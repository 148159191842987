import React from "react";
import classNames from "classnames";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Input,
  Label,
  Switch,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  CheckboxForm,
} from "@/components/molecules";
import { useBoundStore } from "@/hooks";
import { useStateAcordeon } from "@/hooks";
import { ButtonsMedicines } from "./save";
import { SearchMedicine } from "./search-medicine";
import { SearchDiagnosis } from "./search-diagnosis";

export const LoadMedicine: React.FC<{
  form: any;
  setHiddenLoadMedicine: (value: boolean) => void;
}> = ({ setHiddenLoadMedicine, form }) => {
  const { add_diagnosis, selected_medication, is_manual_diagnosis } =
    form.watch();
  const { ref_header } = useBoundStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);

  return (
    <>
      <CardContent
        className={classNames(
          "grid grid-cols-12 gap-4 overflow-y-auto h-[calc(100vh-430px)] !rounded-t-none",
          {
            "!h-[calc(100vh-500px)]":
              STATE_ACCORDION && STATE_ACCORDION !== "closed",
          }
        )}
      >
        <SearchMedicine
          form={form}
          name="medicine_from_list.productName"
          label="Medicamento"
          placeholder="Escribe aquí el nombre del medicamento"
        />
        <div className="space-y-1 col-span-5">
          <Label>Presentación</Label>
          <Input
            disabled
            type="text"
            value={selected_medication?.presentation || "-"}
          />
        </div>
        <FormField
          control={form.control}
          name="selected_medication.quantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Cantidad</FormLabel>
              <FormControl>
                <Input type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="col-span-3 flex space-x-2">
          <Switch
            disabled={selected_medication.not_replace}
            checked={selected_medication.only_generic}
            onCheckedChange={(check) =>
              form.setValue("selected_medication.only_generic", check)
            }
          />
          <label htmlFor="input" className="ml-2 block font-semibold text-base">
            Receta solo genérico
            <p className="text-sm font-regular text-gray-400">
              El medicamento puede ser una versión genérica, no de marca.
            </p>
          </label>
        </div>
        <div className="col-span-3 flex space-x-2">
          <Switch
            checked={selected_medication.not_replace}
            disabled={selected_medication.only_generic}
            onCheckedChange={(check) =>
              form.setValue("selected_medication.not_replace", check)
            }
          />
          <label htmlFor="input" className="ml-2 block font-semibold text-base">
            No sustituir
            <p className="text-sm font-regular text-gray-400">
              Se debe dispensar exactamente el medicamento prescrito, sin
              cambios.
            </p>
          </label>
        </div>
        <div className="col-span-full border-t border-t-slate-200">
          <CardHeader className="!px-0">
            <CardTitle>Diagnóstico para el medicamento</CardTitle>
            <CardDescription>
              Puedes complementar la información de los requerimientos de tu
              paciente y el diagnóstico del medicamento.
            </CardDescription>
          </CardHeader>
          <div className="grid grid-cols-12 gap-5 mb-5">
            <CheckboxForm
              classname="col-span-3"
              label="Agregar diagnóstico"
              subLabel="Podrá incluir un diagnóstico para este medicamento."
              onCheckedChange={(check: boolean) => {
                form.setValue("add_diagnosis", check);
                if (!add_diagnosis) {
                  form.setValue("selected_medication.diagnosis", {
                    description: "",
                    code: "",
                    id_diagnosis: -1,
                  });
                }
              }}
              checked={add_diagnosis}
            />
            <CheckboxForm
              classname="col-span-3"
              label="Diagnóstico manual"
              subLabel="Cargar la información de forma manual."
              onCheckedChange={(check: boolean) => {
                form.setValue("selected_medication.diagnosis", {
                  description: "",
                  code: "",
                  id_diagnosis: -1,
                });
                form.setValue("is_manual_diagnosis", check);
              }}
              checked={is_manual_diagnosis}
              disabled={!add_diagnosis}
            />
            {is_manual_diagnosis ? (
              <FormField
                control={form.control}
                name="selected_medication.diagnosis.description"
                render={({ field }) => (
                  <FormItem className="col-span-6">
                    <FormLabel>Diagnóstico</FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        disabled={!add_diagnosis}
                        placeholder="Escribe aquí el diagnóstico"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              <SearchDiagnosis
                name="selected_medication.diagnosis"
                form={form}
                label="Diagnóstico"
                placeholder="Escribe aquí el diagnóstico"
                disabled={!add_diagnosis}
              />
            )}
            <FormField
              control={form.control}
              name="selected_medication.observations"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Observación</FormLabel>
                  <FormControl>
                    <Input type="text" className="h-14" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="selected_medication.dosage"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Tratamiento</FormLabel>
                  <FormControl>
                    <Input type="text" className="h-14" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <CheckboxForm
              classname="col-span-3"
              label="Tratamiento prolongado"
              subLabel="El medicamento es para un tratamiento prolongado."
              onCheckedChange={(checked) => {
                form.setValue(
                  "selected_medication.prolonged_treatment",
                  checked
                );
              }}
              checked={selected_medication.prolonged_treatment}
            />
          </div>
        </div>
      </CardContent>
      <ButtonsMedicines
        form={form}
        setHiddenLoadMedicine={setHiddenLoadMedicine}
      />
    </>
  );
};
