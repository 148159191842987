import React from "react";
import { Professional } from "@/types/general";
import {
  filterProfByHoursSchedules,
  filterDaysProfessional,
} from "@/utils/filters";
import { getProfessionalWorkDays } from "@/utils/helpers";
import { useBoundStore } from "@/hooks";

export const useFilteredProfessionals = ({
  professionals,
  form,
  onSubmit,
}: {
  professionals: Professional[];
  form: any;
  onSubmit?: any;
}) => {
  const { setDataSchedule } = useBoundStore();

  const { FILTERED_PROFS: PROFESSIONALS }: any = React.useMemo(() => {
    const {
      id_office,
      id_specialty,
      date,
      day_of_week,
      filter_professional_by_day,
    } = form.getValues();
    const DAY_OF_WEEK_FILTER =
      filter_professional_by_day && day_of_week ? day_of_week : null;

    let FILTERED_PROFS =
      filterProfByHoursSchedules({
        professionals,
        office: id_office,
        dayOfWeekToFilter: DAY_OF_WEEK_FILTER,
        id_specialty,
        ignoreDateRange: false,
        date: date,
        filter_professional_by_day,
      }) || [];

    FILTERED_PROFS = filter_professional_by_day
      ? FILTERED_PROFS?.filter((prof: Professional) =>
          prof.professional_timetable.some(
            (time_table: any) => time_table.day === day_of_week
          )
        )
      : FILTERED_PROFS;

    return { FILTERED_PROFS };
  }, [
    form.watch([
      "id_specialty",
      "id_office",
      "date",
      "day_of_week",
      "filter_professional_by_day",
    ]),
  ]);

  React.useEffect(() => {
    const { id_professional } = form.getValues();
    if (PROFESSIONALS?.length) {
      const selectedProfessional = PROFESSIONALS.find(
        (p: any) => p.id_professional === id_professional
      );

      if (!selectedProfessional) {
        form.setValue("id_professional", PROFESSIONALS[0].id_professional);
        onSubmit();
      }
    } else if (id_professional) {
      form.setValue("id_professional", null);
      setDataSchedule({ data: [] });
    }
  }, [form.watch(["id_professional"])]);

  return { PROFESSIONALS };
};

export const useDaysAvailability = ({
  professionals,
  form,
}: {
  professionals: Professional[];
  form: any;
  onSubmit?: any;
}) => {
  const { id_office, id_specialty, date, id_professional } = form.getValues();
  const { AVAILABLE_OFFICE_PROF_DAYS, CURRENT }: any = React.useMemo(() => {
    const CURRENT =
      professionals?.find((p: any) => p.id_professional === id_professional) ||
      null;

    const AVAILABLE_OFFICE_PROF_DAYS = filterDaysProfessional({
      currentProfessional: CURRENT,
      date,
      id_specialty,
      id_office,
    });

    return { CURRENT, AVAILABLE_OFFICE_PROF_DAYS };
  }, [form.watch(["id_specialty", "id_office", "date"])]);

  const WORK_DAYS: number[] = getProfessionalWorkDays({
    id_office,
    currentProfessional: CURRENT,
  });

  return { AVAILABLE_OFFICE_PROF_DAYS, WORK_DAYS };
};
