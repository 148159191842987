import * as React from "react";
import { z } from "zod";
import { HiDotsVertical } from "react-icons/hi";
import { LuPen, LuTrash2 } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuGroup,
} from "@/components/atoms";
import { numberToCurrency } from "@/utils/currency";
import { formSchema } from "@/components/organisms/forms/patients/laboratory/schema";
import { useRestrictedPatientAction } from "@/hooks";
import { Form } from "@/types/general";

const Dropdown = ({
  concept,
  action,
  config: { DELETE, EDIT, professional },
}: {
  concept: z.infer<typeof formSchema>["order_from_list"];
  action: (
    concept: z.infer<typeof formSchema>["order_from_list"],
    type: string
  ) => void;
  config: {
    DELETE: boolean;
    EDIT: boolean;
    professional: number;
  };
}) => {
  const Action = ({
    children,
    type,
  }: {
    children: React.ReactNode;
    type: string;
  }) => {
    return (
      <DropdownMenuItem
        className="hover:cursor-pointer"
        onSelect={useRestrictedPatientAction({
          callback: () => action(concept, type),
          id: professional,
        })}
      >
        {children}
      </DropdownMenuItem>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          {EDIT && (
            <Action type="edit">
              <LuPen className="mr-2 size-4" />
              <span>Modificar</span>
            </Action>
          )}
          {DELETE && (
            <Action type="delete">
              <LuTrash2 className="mr-2 size-4" />
              <span>Eliminar</span>
            </Action>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Columns = ({
  action,
  config,
}: {
  action: (
    order: z.infer<typeof formSchema>["order_from_list"],
    action: string
  ) => void;
  config: {
    permissions: Record<string, boolean>;
    is_supervisor: boolean;
    form: Form<typeof formSchema>;
  };
}) => {
  const columns = [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const { permissions, is_supervisor, form } = config;
        const { id_professional, id_order } = form.getValues();
        const DELETE = !(
          id_order &&
          !is_supervisor &&
          !permissions.hasOwnProperty("Eliminar Pedido")
        );
        const EDIT = !(
          id_order &&
          !is_supervisor &&
          !permissions.hasOwnProperty("Modificar Pedido")
        );
        return (
          (DELETE || EDIT) && (
            <Dropdown
              action={action}
              concept={row.original}
              config={{ DELETE, EDIT, professional: id_professional }}
            />
          )
        );
      },
    },
    {
      accessorKey: "code",
      header: "Código",
      cell: ({ row }: { row: any }) => {
        const { code } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{code}</p>
        );
      },
    },
    {
      accessorKey: "description",
      header: "Concepto",
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: "quantity",
      header: "Cantidad",
      cell: ({ row }: { row: any }) => {
        const { quantity } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: "tooth_section",
      header: "Pieza/s",
      cell: ({ row }: { row: any }) => {
        const { tooth_section } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {tooth_section}
          </p>
        );
      },
    },
    {
      accessorKey: "color",
      header: "Color",
      cell: ({ row }: { row: any }) => {
        const { color } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{color}</p>
        );
      },
    },
    {
      accessorKey: "individual_amount",
      header: "Imp. indiv.",
      cell: ({ row }: { row: any }) => {
        const { amount } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {numberToCurrency(amount)}
          </p>
        );
      },
    },
    {
      accessorKey: "total_amount",
      header: "Imp. total",
      cell: ({ row }: { row: any }) => {
        const { total_amount } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-green-500">
            {numberToCurrency(total_amount)}
          </p>
        );
      },
    },
  ];
  !action && columns.splice(0, 1);

  return columns;
};
