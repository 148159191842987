"use client";

import * as React from "react";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import Image from "next/image";
import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms";
import { Banner as BannerT } from "@/types/home";
import { useBoundStore } from "@/hooks";

export function Banner({
  autoplay = true,
  data,
  className,
}: {
  autoplay?: boolean;
  data: BannerT[];
  className?: string;
}) {
  const router = useRouter();
  const plugin = React.useRef(
    Autoplay({ delay: 5000, stopOnInteraction: true }) || null
  );
  const { user } = useBoundStore();

  return (
    <Carousel
      opts={{
        align: "start",
      }}
      plugins={autoplay ? [plugin.current] : []}
      className={classNames("w-full", className)}
    >
      <CarouselContent>
        {data?.map((banner: BannerT, index: number) => {
          const URL_FORMATTED = banner.url
            ?.replace("xxwebuser", user.web_user)
            .replace("xxusername", user.name);

          return (
            <CarouselItem
              className="hover:cursor-pointer"
              key={index}
              onClick={() => router.push(URL_FORMATTED)}
            >
              <Image
                key={index}
                src={`data:image/jpg;base64, ${banner.image_data}`}
                alt={banner.image_data}
                width={100}
                height={100}
                priority
                className="w-full h-40"
              />
            </CarouselItem>
          );
        })}
      </CarouselContent>
      {data?.length > 1 && (
        <>
          <CarouselPrevious className="hidden xl:flex" />
          <CarouselNext className="hidden xl:flex" />
        </>
      )}
    </Carousel>
  );
}
