import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog, useIsDemo } from "@/hooks";
import { ButtonLoading, FreemiumForm } from "@/components/molecules";
import { User } from "@/types/general";
import { updateUsersConfig } from "@/actions/configs";
import { useBoundStore } from "@/hooks";

export function DeleteUser({ user }: { user: User | undefined }) {
  const { openDialog, closeDialog } = useDialog();
  const {
    user: user_param,
    startLoading,
    stopLoading,
    loading,
  } = useBoundStore();
  const { isDemo } = useIsDemo(user_param?.user || "");

  const onSubmit = async () => {
    if (isDemo) {
      return toast({
        variant: "warning",
        title: "Usuario de demostración",
        description: "Este usuario no puede realizar esta acción.",
      });
    }
    startLoading();
    const response = await updateUsersConfig({ ...user, option: "eliminar" });

    if (response?.error) {
      response?.error.includes("40000")
        ? openDialog({
            content: <FreemiumForm />,
          })
        : toast({
            variant: "destructive",
            title: "Ups! Parece que hubo un error",
            description: response?.error,
          });
    } else {
      toast({
        variant: "successful",
        title: "Usuario eliminado con éxito",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar usuario</DialogTitle>
      </DialogHeader>
      <span>
        Estás por eliminar este usuario y esta acción es irreversible, el/la
        usuario se eliminará definitivamente.
      </span>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-patient">
            Eliminar
          </Button>
        )}
      </div>
    </DialogContent>
  );
}
