"use client";

import React from "react";
import {
  Button,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { toast } from "@/hooks";
import { deleteCard } from "@/actions/administration";
import { getTableParams } from "@/actions/configs";
import { useBoundStore } from "@/hooks";
import { useDialog } from "@/hooks";
import { Card } from "@/types/general";

export function DeleteCardForm({ card }: { card: Card }) {
  const { closeDialog } = useDialog();
  const { startLoading, stopLoading, setDataAdministration, loading } =
    useBoundStore();

  const onSubmit = async () => {
    startLoading();
    const response = await deleteCard(card);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const {
        cards,
      }: {
        cards: Card[];
      } = await getTableParams(["TARJETAS"]);

      setDataAdministration({
        data: cards,
        section: "cards",
      });

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "La tarjeta se eliminó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar tarjeta</DialogTitle>
        <p className="text-slate-500 text-base">
          Está por eliminar una tarjeta, esta acción es irreversible, ¿desea
          continuar?
        </p>
      </DialogHeader>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose asChild className="col-span-2 col-start-9">
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading className="col-span-2" />
        ) : (
          <Button className="col-span-2" onClick={onSubmit}>
            Continuar
          </Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
