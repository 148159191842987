import * as React from "react";
import {
  LuCheck as Check,
  LuChevronsUpDown as ChevronsUpDown,
} from "react-icons/lu";
import classNames from "classnames";
import { Form, Professional } from "@/types/general";
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
} from "@/components/atoms";
import { FormField, FormItem, FormControl } from "./form";

export function ProfessionalsSelectSchedule({
  professionals,
  form,
  className,
  disabled,
}: {
  professionals: Professional[];
  className?: string;
  disabled?: boolean;
  form: Form<any>;
}) {
  const [open, setOpen] = React.useState<boolean>(false);
  const PLACEHOLDER = !professionals.length
    ? "Sin profesionales disponibles"
    : "Seleccionar profesional";

  return (
    <FormField
      control={form.control}
      name="id_professional"
      render={({ field }) => (
        <FormItem className={classNames("flex flex-col", className)}>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  disabled={disabled}
                  className={classNames(
                    "justify-between h-9 text-ellipsis truncate",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  {field.value
                    ? professionals.find(
                        (professional) =>
                          professional.id_professional === field.value
                      )?.name
                    : PLACEHOLDER}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="p-0">
              <Command>
                <CommandInput placeholder="Buscar profesional..." />
                <CommandList>
                  <CommandEmpty>No se encontró profesional.</CommandEmpty>
                  <CommandGroup>
                    <ScrollArea className="h-auto max-h-[300px] overflow-y-auto">
                      {professionals.map((professional) => (
                        <CommandItem
                          value={professional.name}
                          key={professional.id_professional}
                          onSelect={() => {
                            field.onChange(professional.id_professional);
                            form.setValue(
                              "id_professional",
                              professional.id_professional
                            );
                            setOpen(false);
                          }}
                        >
                          {professional.name}
                          <Check
                            className={classNames(
                              "ml-auto",
                              professional.id_professional === field.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))}
                    </ScrollArea>
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
        </FormItem>
      )}
    />
  );
}
