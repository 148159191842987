import { IconType } from "react-icons/lib";
import {
  LuHouse as Home,
  LuCalendar as Calendar,
  LuUserCog as UserCog,
  LuCog as Cog,
  LuUsers as Users,
  LuChartColumnBig as BarChartBig,
  LuFileText as FileText,
} from "react-icons/lu";
import { HiOutlineBanknotes as Banknotes } from "react-icons/hi2";
import { Permissions, PermissionsView } from "@/types/permissions";
import { getFirstAvailableUrl } from "@/libs/sidebars";
import {
  ADMINISTRATION_ITEMS,
  CONFIGURATION_ITEMS,
  PATIENTS_ITEMS,
  STATISTICS_ITEMS,
} from "@/libs/nav";

type CreateNavigationT = {
  permissions: Permissions;
  plan: string;
  isSupervisor: boolean;
  isOwner: boolean;
};

const hasFullFeaturesVersions = (version: string) =>
  ["premium", "standard", "full"].includes(version);

export function createNavigation({
  permissions,
  plan,
  isSupervisor,
  isOwner,
}: CreateNavigationT) {
  const renderStatistics =
    (hasFullFeaturesVersions(plan) && isSupervisor) ||
    (hasFullFeaturesVersions(plan) &&
      (Object.keys(permissions).includes("Estadísticas") ||
        (Object.keys(permissions).includes("Informes") &&
          (Object.keys(permissions).includes("Indicadores") ||
            Object.keys(permissions).includes("Presupuestos") ||
            Object.keys(permissions).includes("Prestaciones")))));

  const renderLiquidation =
    Object.keys(permissions).includes("Liquidacion Bilog") || isSupervisor;
  const renderConfiguration =
    Object.keys(permissions).includes("Tablas") ||
    Object.keys(permissions).includes("Sistema") ||
    isSupervisor;
  const renderPatients =
    Object.keys(permissions).includes("Pacientes") || isSupervisor;
  const renderAdministration =
    Object.keys(permissions).includes("Administracion") || isSupervisor;
  const renderSchedule =
    Object.keys(permissions).includes("Agenda Turnos") || isSupervisor;
  const CONFIG_URL =
    (Object.keys(permissions).includes("Tablas") &&
      getFirstAvailableUrl(permissions, "tables")) ||
    (Object.keys(permissions).includes("Sistema") &&
      getFirstAvailableUrl(permissions, "sistem")) ||
    null;

  const links: {
    title: string;
    new: boolean;
    url: string | null;
    icon: IconType;
    styles: string;
    subItems?: string[];
  }[] = [
    {
      title: "Inicio",
      new: false,
      url: "/dashboard",
      icon: Home,
      styles: "px-4 flex justify-between text-slate-500",
    },
  ];

  renderSchedule &&
    links.push({
      title: "Agenda de turnos",
      new: false,
      url: "/dashboard/schedule",
      icon: Calendar,
      styles: "px-4 flex justify-between text-slate-500",
    });

  renderPatients &&
    links.push({
      title: "Pacientes",
      new: false,
      url: "/dashboard/patients/personal-data",
      icon: Users,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: PATIENTS_ITEMS,
    });

  renderAdministration &&
    links.push({
      title: "Administración",
      new: true,
      url: "/dashboard/administration/dailycashflow/patient_payments",
      icon: Banknotes,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: ADMINISTRATION_ITEMS,
    });

  renderStatistics &&
    links.push({
      title: "Estadísticas",
      new: false,
      url: "/dashboard/statistics/finances",
      icon: BarChartBig,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: STATISTICS_ITEMS,
    });

  renderLiquidation &&
    links.push({
      title: "Liquidaciones",
      new: false,
      url: "/dashboard/reports/auditor",
      icon: FileText,
      styles: "px-4 flex justify-between text-slate-500",
    });

  renderConfiguration &&
    links.push({
      title: "Configuración",
      new: false,
      url: isSupervisor
        ? "/dashboard/configuration/tables/professionals"
        : CONFIG_URL,
      icon: Cog,
      styles: "px-4 flex justify-between text-slate-500",
      subItems: CONFIGURATION_ITEMS,
    });

  isOwner &&
    ["freemium", "premium"].includes(plan) &&
    links.push({
      title: "Mi cuenta",
      new: false,
      url: "/dashboard/my-account/general",
      icon: UserCog,
      styles: "px-4 flex justify-between text-slate-500",
    });

  return links;
}

export function getPermissionsView(
  permission: { [key: string]: boolean },
  user: { is_owner: boolean; plan_version: string }
) {
  const { is_owner, plan_version} = user;

  const hasPermission = (key: string) => !!permission[key];
  const HAS_FULL_FEATURES = hasFullFeaturesVersions(plan_version);
  const MY_ACCOUNT_CONDITION =
    is_owner && ["freemium", "premium"].includes(plan_version);

  const PERMISSIONS: PermissionsView = {
    schedule: {
      enabled: hasPermission("Agenda Turnos"),
      sections: null,
    },
    reminders: {
      enabled:
        hasPermission("Enviar SMS Ag.") || hasPermission("Enviar Wapp Ag."),
      sections: null,
    },
    administration: {
      enabled: hasPermission("Administracion"),
      sections: {
        dailycashflow: {
          enabled: true,
          sections: {
            patient_payments: { enabled: true },
            incomes: { enabled: hasPermission("Adm. Ingresos") },
            expenses: { enabled: hasPermission("Adm. Egresos") },
          },
        },
        transactions: {
          enabled:
            hasPermission("Adm. Ingresos") || hasPermission("Adm. Egresos"),
          sections: {
            patient_payments: { enabled: true },
            incomes: { enabled: hasPermission("Adm. Ingresos") },
            expenses: { enabled: hasPermission("Adm. Egresos") },
          },
        },
        banks: {
          enabled: hasPermission("Adm. Bancos"),
          sections: {
            movements: { enabled: hasPermission("Adm. Bancos") },
            accounts: { enabled: hasPermission("Adm. Bancos") },
          },
        },
        suppliers: {
          enabled: hasPermission("Adm. Proveedores"),
          sections: null,
        },
        cards: {
          enabled: hasPermission("Adm. Tarjetas"),
          sections: null,
        },
      },
    },
    patients: {
      enabled: hasPermission("Pacientes"),
      sections: {
        "personal-data": { enabled: true },
        odontogram: { enabled: hasPermission("Odontograma") },
        benefits: { enabled: hasPermission("Prestaciones") },
        "medical-history": { enabled: hasPermission("Historia Clínica") },
        prescriptions: { enabled: hasPermission("Ver Recetas") },
        budget: { enabled: hasPermission("Presupuestos") },
        payments: { enabled: hasPermission("Pagos-Cta.Cte.") },
        laboratory: { enabled: hasPermission("Laboratorios") },
        appointments: { enabled: hasPermission("Ver Turnos") },
        gallery: { enabled: hasPermission("Imagenes") },
      },
    },
    statistics: {
      enabled:
        HAS_FULL_FEATURES &&
        (hasPermission("Estadísticas") ||
          hasPermission("Informes") ||
          hasPermission("Indicadores")),
      sections: {
        finances: { enabled: hasPermission("Estadísticas") },
        control: { enabled: hasPermission("Estadísticas") },
        "benefits-made": { enabled: hasPermission("Estadísticas") },
        budgets: { enabled: hasPermission("Estadísticas") },
      },
    },
    configuration: {
      enabled: hasPermission("Tablas") || hasPermission("Sistema"),
      sections: {
        tables: {
          enabled: hasPermission("Tablas"),
          sections: {
            professionals: {
              enabled: hasPermission("Tabla de Profesionales"),
              sections: {
                new: { enabled: true },
              },
            },
            "medical-insurances": {
              enabled: hasPermission("Tabla de Obras Sociales"),
              sections: {
                new: { enabled: true },
                copy: { enabled: true },
              },
            },
            laboratories: {
              enabled:
                HAS_FULL_FEATURES && hasPermission("Tabla de Laboratorios"),
              sections: {
                "new-laboratory": { enabled: true },
                "copy-laboratories": { enabled: true },
              },
            },
            specialties: { enabled: hasPermission("Tabla de Especialidades") },
            holidays: { enabled: hasPermission("Feriados") },
            supplier: {
              enabled:
                HAS_FULL_FEATURES && hasPermission("Tabla de Proveedores"),
            },
            offices: { enabled: hasPermission("Tabla de Sucursales") },
          },
        },
        sistem: {
          enabled: hasPermission("Sistema"),
          sections: {
            "general-data": {
              enabled: hasPermission("Parametros del Sistema"),
            },
            users: {
              enabled:
                HAS_FULL_FEATURES && hasPermission("Usuarios del Sistema"),
              sections: {
                new: { enabled: true },
              },
            },
            "whatsapp-data": {
              enabled: hasPermission("Parametros del Sistema"),
            },
            "sms-data": { enabled: hasPermission("Parametros del Sistema") },
            "various-data": {
              enabled: hasPermission("Parametros del Sistema"),
            },
          },
        },
      },
    },
    reports: {
      enabled: hasPermission("Liquidacion Bilog"),
      sections: {
        auditor: { enabled: true },
      },
    },
    "my-account": {
      enabled: MY_ACCOUNT_CONDITION,
      sections: {
        general: { enabled: MY_ACCOUNT_CONDITION },
        subscription: { enabled: MY_ACCOUNT_CONDITION },
        products: { enabled: MY_ACCOUNT_CONDITION },
      },
    },
  };

  return PERMISSIONS;
}
