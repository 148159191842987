"use client";

import React from "react";
import classNames from "classnames";
import { Card, CardDescription, Checkbox } from "@/components/atoms";
import { useBoundStore } from "@/hooks";
import { checkFileType, formatDate } from "@/utils/helpers";

export function Archive({
  index,
  archive,
  openModal,
  icon,
}: {
  index: number;
  archive: any;
  openModal: (archive: { [key: string]: any }, type: string) => void;
  icon: (value: string) => JSX.Element | null;
}) {
  const {
    setArchivesSelected,
    patient_gallery: { archives_selected },
  } = useBoundStore();
  const [checked, setChecked] = React.useState<boolean>(false);
  const FILE_TYPE = checkFileType(archive.rute);
  const isSelected = archives_selected.some(
    (selected: any) => selected.id === archive.id
  );
  const onChangeHandler = () => {
    setChecked(!checked);
    setArchivesSelected(archive);
  };

  return (
    <Card
      key={index}
      className={classNames(
        "hover:cursor-pointer hover:bg-slate-50 w-full col-span-3",
        isSelected && "border-2 !border-blue-500"
      )}
    >
      <div className="flex justify-between items-center">
        <div
          className="flex gap-x-4 items-center"
          onClick={() => openModal(archive, "edit")}
        >
          {icon(FILE_TYPE)}
          <div>
            <div className="flex gap-x-2 items-center">
              <h4 className="text-lg font-bold whitespace-nowrap description-archive">
                {archive.description?.length > 15
                  ? archive.description.slice(0, 15) + "..."
                  : archive.description}
              </h4>
              <CardDescription>
                {archive.size ? `(${archive.size} MB)` : "(>1 MB)"}
              </CardDescription>
            </div>
            <CardDescription>{formatDate(archive.date)}</CardDescription>
            <CardDescription>
              Ubicacion:{" "}
              <strong>
                {archive.rute.startsWith("http") ? "Cloud" : "Local"}
              </strong>
            </CardDescription>
          </div>
        </div>
        <Checkbox
          checked={isSelected}
          onCheckedChange={onChangeHandler}
          className="align-self-end"
        />
      </div>
    </Card>
  );
}
