"use client";

import React from "react";
import classNames from "classnames";
import { LuChevronLeft } from "react-icons/lu";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import { ButtonLoading, Form } from "@/components/molecules";
import { toast } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { MedicalInsurance } from "@/types/general";
import { Button, buttonVariants } from "@/components/atoms";
import { postServicePerformed } from "@/actions/budgets";
import { updateSection } from "@/actions/patients";
import { transformDateToZoneTime } from "@/utils/date";
import { AmountsBar } from "./amount-bar";
import { formSchema, resolver } from "./schema";
import { CardDetailTooth } from "./detail-tooth";

export const PerformanceIndicator = ({
  medicalInsurances,
  setActiveTab,
}: {
  medicalInsurances: MedicalInsurance[];
  setActiveTab: (value: string) => void;
}) => {
  const {
    patient,
    startLoading,
    stopLoading,
    budget_selected,
    service_performed,
    setBudgetSelected,
    updatePatient,
    loading,
  } = useBoundStore();
  const {
    total_amount,
    id_benefit,
    tooth_section,
    selected_quantity,
    needs_thoot,
    thoot_face,
    amount_patient,
    amount_med_ins,
    quantity,
  } = service_performed;
  const {
    interest_percentage,
    vat_percentage,
    discount_value,
    budget_items,
    id_professional,
    id_patient,
    id_user,
    id_office,
    is_usd,
    id_budget,
    discount_percentage,
  } = budget_selected;
  const VALIDATE_FORM_THOOTS =
    ["DIE", "ALG"].includes(thoot_face || "") || needs_thoot;
  const TOTAL_AMOUNT =
    total_amount *
      (1 + (interest_percentage + vat_percentage) / 100) *
      (1 - discount_percentage / 100) -
    discount_value;
  const medical_insurance = medicalInsurances.find(
    (med_ins: MedicalInsurance) => {
      return (
        med_ins.id_med_ins === budget_selected.id_plan_navigation.id_med_ins
      );
    }
  );

  const todoList = () => {
    let list: any = [];
    for (let i = 0; i < selected_quantity; i++) {
      list.push({
        period: medical_insurance?.load_for_period
          ? format(new TZDate(new Date().toISOString(), TIMEZONES.AR), "yyyyMM")
          : null,
        date: transformDateToZoneTime(),
        id_professional,
        id_patient,
        id_benefit,
        id_user,
        id_office,
        tooth_faces: {
          v: false,
          p: false,
          o: false,
          d: false,
          m: false,
          l: false,
          i: false,
          g: false,
        },
        tooth_section,
        is_usd,
        values_to_charge: {
          from_medical_insurance: amount_med_ins,
          from_patient: amount_patient,
        },
      });
    }
    return list;
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      total_amount: TOTAL_AMOUNT,
      amount: TOTAL_AMOUNT / quantity,
      tax: vat_percentage,
      discount:
        discount_value ||
        total_amount *
          (1 + (interest_percentage + vat_percentage) / 100) *
          (discount_percentage / 100),
      interest: interest_percentage,
      id_benefit: service_performed.id_benefit,
      todo_list: todoList(),
      id_budget_benefit: service_performed.id_budget_benefit,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();
    const BENEFITS = values?.todo_list.map((item) => ({
      ...item,
      values_to_charge: {
        ...item.values_to_charge,
        from_patient: values.amount,
      },
    }));
    const response = await postServicePerformed({
      benefits: BENEFITS,
      budgetServiceid: values.id_budget_benefit,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const response_budget = await updateSection(
        ["presup"],
        patient.id_patient
      );

      if (response_budget?.error) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response?.error,
        });
      } else {
        const selected = response_budget.budgets?.find(
          (b: { [key: string]: any }) => b.id_budget === id_budget
        );
        updatePatient("budgets", response_budget.budgets);
        if (response?.account) {
          updatePatient("balance_usd", response?.account?.balance_usd);
          updatePatient("balance", response?.account?.balance);
          updatePatient("alls", response?.account?.alls);
        }
        setBudgetSelected(selected);
        setActiveTab("form");
        toast({
          variant: "successful",
          title: "Prestación creada con éxito",
          description: "Los datos se veran reflejados en el resumen.",
        });
      }
    }

    stopLoading();
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col items-end"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="w-full gap-10 mt-0 overflow-hidden items-center">
          <div className="col-span-full flex flex-row w-full gap-2 items-center pb-2">
            <span
              onClick={() => setActiveTab("form")}
              className={classNames(
                "cursor-pointer",
                buttonVariants({ variant: "outline", size: "icon" })
              )}
            >
              <LuChevronLeft className="size-4" />
              <span className="sr-only">Back</span>
            </span>
          </div>
          <p className="text-base pb-5">
            {VALIDATE_FORM_THOOTS && (
              <>
                Selecciona los dientes, secciones y caras que requiera tu
                prestación para ser incluida en el odontograma.
                <br />
              </>
            )}
            El valor del importe unitario final está compuesto por el valor del
            importe unitario más los intereses menos los descuentos.
          </p>
          <span className="text-2xl pb-5">{`${service_performed?.benefit_code} - ${service_performed?.description}`}</span>
          <AmountsBar form={form} />
          <div className=" max-h-[calc(100vh-520px)] overflow-y-auto mb-10">
            {VALIDATE_FORM_THOOTS ? (
              <CardDetailTooth
                selectedQuantity={service_performed?.selected_quantity}
                benefits={budget_items}
                form={form}
              />
            ) : (
              <div className="mb-12 text-slate-600 border-b border-b-slate-200">
                Esta prestación no requiere indicar diente, sector o cara.
              </div>
            )}
          </div>
        </div>
        {loading ? <ButtonLoading /> : <Button type="submit">Guardar</Button>}
      </form>
    </Form>
  );
};
