import { Checkbox, Combobox } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/molecules";
import { User } from "@/types/general";

export const Users = ({ form, users }: { form: any; users: User[] }) => {
  const { all_users } = form.getValues();
  const enabledUsers = users?.filter((item) => item.enabled);

  return (
    <>
      <FormField
        control={form.control}
        name="all_users"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-slate-800 !text-lg !font-bold">
              Usuarios
            </FormLabel>
            <div className="!mt-2 flex flex-row items-center space-x-2 justify-start">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked);
                    checked
                      ? form.setValue("id_user", null)
                      : form.setValue("id_user", enabledUsers?.[0]?.id_user);
                  }}
                />
              </FormControl>
              <div className="leading-none flex flex-col">
                <FormLabel className="!m-0">Todos los usuarios</FormLabel>
                <FormDescription className="text-sm font-regular text-gray-400">
                  Se incluirán todos los usuarios en el reporte
                </FormDescription>
              </div>
            </div>
          </FormItem>
        )}
      />
      <FormItem>
        <FormControl>
          <Combobox
            form={form}
            data={enabledUsers}
            dataKey="id_user"
            value="name"
            placeholder="Busca un usuario"
            title="Usuario"
            disabled={all_users}
          />
        </FormControl>
        <FormMessage />
      </FormItem>
    </>
  );
};
