import React from "react";
import { Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { ToothFaces } from "@/components/organisms/forms/patients/benefits/tooth-faces";
import { Benefit } from "@/types/general";
import { validationToothSection } from "@/utils/benefits";
import { BenefitBudget } from "@/types/patients";

export const BenefitRow: React.FC<{
  position: number;
  benefits: BenefitBudget[];
  form: any;
}> = ({ position, benefits, form }) => {
  const { id_benefit } = form.getValues();
  const BENEFIT_STATE = React.useMemo(() => {
    const FILTER_BENEFIT =
      benefits?.find((b: BenefitBudget) => b.id_benefit === id_benefit) || null;

    return FILTER_BENEFIT;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_benefit]);

  return (
    <div className="grid grid-cols-2 gap-10 mb-4 items-center">
      <FormField
        control={form.control}
        name={`todo_list[${position}].tooth_section`}
        disabled={validationToothSection(BENEFIT_STATE?.benefit_navigation?.tooth)}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Diente/Sector</FormLabel>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <ToothFaces
        checksCss="w-60 items-center mt-3 gap-2"
        form={form}
        name={`todo_list[${position}].tooth_faces`}
        benefit={BENEFIT_STATE?.benefit_navigation}
      />
    </div>
  );
};
