"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  Button,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  Input,
} from "@/components/atoms";
import { toast, useDialog, useKeypress } from "@/hooks";
import {
  ButtonLoading,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/molecules";
import { useBoundStore } from "@/hooks";
import { MedicalInsurance, Group } from "@/types/general";
import { updateGroupConfig } from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export function NewMedicalInsuranceGroupsForm({
  selected,
  medicalInsurance,
}: {
  selected?: Group;
  medicalInsurance: MedicalInsurance;
}) {
  const { loading, startLoading, stopLoading } = useBoundStore();
  const { closeDialog } = useDialog();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_group: selected?.id_group || 0,
      id_med_ins: selected?.id_med_ins || medicalInsurance.id_med_ins,
      code_group: selected?.code_group || "",
      description: selected?.description || "",
      rowversion: selected?.rowversion || null,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await updateGroupConfig({
      medicalInsurance,
      group: values as Group,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El grupo se guardó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {selected?.id_group ? "Modificar grupo" : "Nuevo grupo"}
        </DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form className="flex items-end gap-x-4 gap-y-6">
          <FormField
            control={form.control}
            name="code_group"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Código</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nombre</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
      <DialogFooter>
        <DialogClose asChild>
          <Button
            type="button"
            onClick={() => form.reset()}
            variant="destructive"
          >
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={form.handleSubmit(onSubmit)}>Guardar</Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
