import React from "react";
import { Combobox, Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { Benefit, Form } from "@/types/general";
import { useBoundStore } from "@/hooks";
import { formSchema } from "../schema";

export const SearchBenefit: React.FC<{
  form: Form<typeof formSchema>;
  benefits: Benefit[];
}> = ({ form, benefits }) => {
  const { is_manual_benefit, id_benefit } = form.watch();
  const { is_usd, selected_benefit } = form.getValues();
  const {
    general_params: { user_params },
  } = useBoundStore();
  const firstMount = React.useRef<boolean>(true);
  const BENEFITS_BY_STRING = benefits?.filter(
    (b) =>
      !b.drawing ||
      (!b.drawing?.trim().toUpperCase().endsWith("_R") &&
        !b.drawing?.trim().toUpperCase().endsWith("_X") &&
        b.drawing?.trim().toUpperCase() !== "TA" &&
        b.drawing?.trim().toUpperCase() !== "OA")
  );
  const BENEFITS_FILTER = () => {
    if (!user_params.use_usd)
      return BENEFITS_BY_STRING?.filter(
        (b) => b.amount_mi === 0 || b.amount_patient > 0
      );
    return is_usd
      ? BENEFITS_BY_STRING?.filter(
          (b) => (b.amount_mi === 0 || b.amount_patient > 0) && !!b.is_usd
        )
      : BENEFITS_BY_STRING?.filter(
          (b) => (b.amount_mi === 0 || b.amount_patient > 0) && !b.is_usd
        );
  };
  React.useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
    if (!is_manual_benefit) {
      const benefit_selected = benefits.find(
        (benefit: Benefit) => benefit?.id_benefit === id_benefit
      );

      return form.setValue("selected_benefit", {
        ...selected_benefit,
        ...benefit_selected,
        quantity: 1,
        total_amount:
          selected_benefit.quantity * (benefit_selected?.amount_patient || 0),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_benefit]);

  React.useEffect(() => {
    selected_benefit.id_benefit &&
      form.setValue("id_benefit", selected_benefit.id_benefit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="col-span-2">
      {is_manual_benefit ? (
        <FormField
          control={form.control}
          name="selected_benefit.description"
          render={({ field }) => (
            <FormItem className="col-start-1">
              <FormLabel>Prestación</FormLabel>
              <FormControl>
                <Input type="text" {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      ) : (
        <Combobox
          form={form}
          data={BENEFITS_FILTER()}
          dataKey="id_benefit"
          value="description"
          placeholder="Buscar prestación"
          title="prestación"
          className="col-span-3"
          classContent="!w-[600px]"
          label="Prestación"
        />
      )}
    </div>
  );
};
