"use client";

import React from "react";
import { Loading } from "@/components/organisms/reports/loading";
import { useBoundStore } from "@/hooks";
import { Data } from "./data";
import { Columns } from "./columns";

export const Table = ({
  data,
  action,
}: {
  data: any;
  action: any;
}) => {
  const {
    general_params: { translations, user_params },
    permissions,
    user,
    loading
  } = useBoundStore();
  const USE_USD = {
    params: user_params?.use_usd,
    user: user?.use_usd,
  };

  return (
    <div className="w-full col-span-full">
      {loading ? (
        <Loading heigth="300px" />
      ) : (
        <Data
          config={USE_USD}
          data={data?.budgets.reverse()}
          columns={Columns({
            translations,
            config: { permissions, is_supervisor: user.is_supervisor },
            action,
          })}
        />
      )}
    </div>
  );
};
