"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { useBoundStore } from "@/hooks";
import {
  HiDotsVertical,
  HiOutlineTrash,
  HiOutlinePencil,
  HiOutlineBan,
  HiOutlineCheckCircle,
} from "react-icons/hi";
import { LuMapPin, LuPhoneCall, LuMail, LuEye } from "react-icons/lu";
import { useDialog } from "@/hooks";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  Badge,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Button,
} from "@/components/atoms";
import { DeleteMedicalInsuranceForm } from "@/components/organisms/forms";
import { MedicalInsurance, Plan } from "@/types/general";

type RowDataT = {
  original: any;
};

const MedicalInsurancesDropdown = ({
  selected,
}: {
  selected: MedicalInsurance;
}) => {
  const router = useRouter();
  const { openDialog } = useDialog();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <span className="sr-only">Abrir menu</span>
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 size-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() =>
              router.push(
                `/dashboard/configuration/tables/medical-insurances/new?id=${selected.id_med_ins}`
              )
            }
          >
            <HiOutlinePencil className="mr-2 size-4" />
            <span>Modificar</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="hover:cursor-pointer text-red-500"
            onSelect={() =>
              openDialog({
                content: <DeleteMedicalInsuranceForm selected={selected} />,
              })
            }
          >
            <HiOutlineTrash className="mr-2 size-4" />
            <span>Eliminar</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Columns = () => {
  const {
    general_params: { translations },
  } = useBoundStore();

  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => (
        <MedicalInsurancesDropdown selected={row.original} />
      ),
    },
    {
      accessorKey: "medical_insurance",
      header: translations?.medical_insurance,
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { medical_insurance, code_med_ins } = row.original;

        return (
          <>
            <Badge className="w-auto 2xl:ml-3">{`Cód. ${code_med_ins}`}</Badge>
            <p className="font-semibold max-w-48 text-sm text-slate-800 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm my-1">
              {medical_insurance}
            </p>
          </>
        );
      },
    },
    {
      accessorKey: "plans",
      header: "Planes",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { plans } = row.original;

        return (
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button
                variant="link"
                className="flex gap-x-2 hover:text-blue-500"
              >
                <LuEye className="size-4" />
                <p>Ver planes</p>
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="!w-full" align="start">
              {plans.map((plan: Plan) => (
                <div
                  className="flex items-center gap-x-1 my-1"
                  key={plan.id_plan}
                >
                  <Badge variant="secondary" className="w-auto lg:text-xs">
                    {plan.code_plan}
                  </Badge>
                  <p className="lg:text-xs text-slate-600 my-1">{plan.name}</p>
                </div>
              ))}
            </HoverCardContent>
          </HoverCard>
        );
      },
    },
    {
      accessorKey: "city",
      header: "Dirección",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { city, state, address } = row.original;

        return (
          <div className="whitespace-nowrap">
            <div className="flex items-center gap-x-1">
              <LuMapPin className="size-4 text-red-500" />
              <p className="lg:text-sm text-slate-600 my-1">{state || "-"}</p>
            </div>
            <div className="flex items-center">
              {city && <p className="lg:text-xs text-slate-600 my-1">{city}</p>}
              {address && (
                <p className="lg:text-xs text-slate-600 my-1">, {address}</p>
              )}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "phone",
      header: "Contacto",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { phone, email } = row.original;

        return (
          <div className="whitespace-nowrap max-w-60">
            <div className="flex items-center gap-x-1">
              <LuPhoneCall className="size-4" />
              <p className="lg:text-sm text-slate-600 my-1 overflow-hidden text-ellipsis text-nowrap">
                {phone || "-"}
              </p>
            </div>
            <div className="flex items-center gap-x-1">
              <LuMail className="size-4" />
              <p className="lg:text-sm text-slate-600 my-1 overflow-hidden text-ellipsis text-nowrap">
                {email || "-"}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "tax_number",
      header: "C.U.I.T.",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { tax_number } = row.original;

        return (
          <p className="lg:text-sm text-nowrap text-slate-600 my-1">
            {tax_number}
          </p>
        );
      },
    },
    {
      accessorKey: "observations",
      header: "Observaciones",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { observations } = row.original;

        return (
          <Tooltip delayDuration={300}>
            <TooltipTrigger asChild>
              <p className="lg:text-sm max-w-40 hover:underline text-slate-600 my-1 overflow-hidden text-ellipsis text-nowrap">
                {observations}
              </p>
            </TooltipTrigger>
            <TooltipContent side="bottom">
              <p>{observations}</p>
            </TooltipContent>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "disabled",
      header: "Habilitado",
      enableHiding: false,
      enableGrouping: false,
      cell: ({ row }: { row: any }) => {
        const { disabled } = row.original;

        return disabled ? (
          <HiOutlineBan className="size-5 text-red-500" />
        ) : (
          <HiOutlineCheckCircle className="size-5 text-green-500" />
        );
      },
    },
  ];
};
