import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/molecules";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";

export const GroupMedIns = ({ form, translations }: any) => {
  const CHECKBOX_LIST = [
    {
      key: "medIns",
      label: translations?.medical_insurance,
    },
    { key: "prof", label: "Profesional" },
  ];

  return (
    <FormField
      control={form.control}
      name="group_by_med_ins"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-base">Agrupar por</FormLabel>
          <FormControl>
            <Select
              key="key"
              value={field.value}
              onValueChange={(value: string) => {
                form.setValue("group_by_med_ins", value);
              }}
            >
              <SelectTrigger className="w-full !h-10 !text-sm">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {CHECKBOX_LIST.map((item: { key: string; label: string }) => (
                  <SelectItem key={item.key} value={"" + item.key}>
                    <p>{item.label}</p>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
        </FormItem>
      )}
    />
  );
};
