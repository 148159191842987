"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { toast, useKeypress, useDialog } from "@/hooks";
import {
  Button,
  Checkbox,
  SheetClose,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetContent,
  Input,
  ScrollArea,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormItem,
  FormLabel,
  FormField,
  FormMessage,
} from "@/components/molecules";
import { useBoundStore } from "@/hooks";
import { Supplier } from "@/types/general";
import { createSupplier } from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export function NewSupplierForm({ supplier }: { supplier?: Supplier }) {
  const { closeDialog } = useDialog();
  const { loading, startLoading, stopLoading } = useBoundStore();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_supplier: supplier?.id_supplier || 0,
      name: supplier?.name || "",
      address: supplier?.address || "",
      city: supplier?.city || "",
      phone: supplier?.phone || "",
      fax: supplier?.fax || "",
      email: supplier?.email || "",
      contact: supplier?.contact || "",
      balance: supplier?.balance || 0,
      tax_number: supplier?.tax_number || "",
      observation: supplier?.observation || "",
      enabled: supplier?.enabled || true,
      id_professional: supplier?.id_professional || 0,
      id_laboratory: supplier?.id_laboratory || 0,
      rowversion: supplier?.rowversion || null,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await createSupplier(values);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      closeDialog();
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <SheetContent>
      <SheetHeader>
        <SheetTitle className="p-2 font-semibold text-xl">
          {supplier?.id_supplier ? "Modificar proveedor" : "Nuevo proveedor"}
        </SheetTitle>
      </SheetHeader>
      <div
        className="space-y-4 bg-transparent rounded-md justify-start flex flex-col h-dvh"
        style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}
      >
        <ScrollArea className="h-dvh">
          <Form {...form}>
            <form className="flex flex-col gap-4 overflow-hidden bg-white p-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nombre</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Teléfono</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Dirección</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Localidad/Provincia</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="balance"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Saldo inicial</FormLabel>
                    <FormControl>
                      <Input type="number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="tax_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>C.U.I.T.</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="observation"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Observaciones</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="enabled"
                render={({ field }) => (
                  <FormItem className="!mt-2 flex flex-row items-center space-x-2 justify-start">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="leading-none flex flex-col">
                      <FormLabel className="!m-0">
                        Proveedor habilitado
                      </FormLabel>
                      <p className="text-sm font-regular text-gray-400">
                        De no estar habilitado, no se ejecutaran operaciones con
                        el proveedor.
                      </p>
                    </div>
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </ScrollArea>
      </div>
      <SheetFooter className="pt-4">
        <SheetClose>
          <Button variant="destructive" type="button">
            Cancelar
          </Button>
        </SheetClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button className="bg-blue-600" onClick={form.handleSubmit(onSubmit)}>
            Guardar
          </Button>
        )}
      </SheetFooter>
    </SheetContent>
  );
}
