import React from "react";
import Image from "next/image";
import { HiOutlineCloudArrowUp } from "react-icons/hi2";
import { LuTrash2 } from "react-icons/lu";
import { checkArchives, uploadArchives } from "@/actions/archives";
import { Button, Input } from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { toast, useIsDemo } from "@/hooks";
import { archivesMapper, checkMapper } from "@/utils/helpers";
import { UploadGallery } from "@/types/patients";
import { useBoundStore } from "@/hooks";

export function Uploader({
  patientId,
  refresh,
  loading,
  setLoading,
}: {
  patientId: number;
  refresh: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}) {
  const [fileData, setFileData] = React.useState<{
    selectedFiles: File[];
    base64strings: { name: string; base64: string }[];
  }>({
    selectedFiles: [],
    base64strings: [],
  });
  const {
    user: { web_user },
  } = useBoundStore();
  const { isDemo } = useIsDemo(web_user);
  const allowExtensions =
    "image/jpeg,image/jpg,image/png,image/webp,text/*,video/mp4,application/pdf";

  const handleChangeStatus = (file: File) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      const string = reader.result.replace("data:", "").replace(/^.+,/, "");
      setFileData((state) => {
        if (!state.base64strings.some((item) => item.name === file.name)) {
          return {
            ...state,
            base64strings: [
              ...state.base64strings,
              { name: file.name, base64: string },
            ],
          };
        }
        return state;
      });
    };
    reader.readAsDataURL(file);
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    const MAX_FILE_SIZE = 1 * 1024 * 1024;
    if (files?.[0]?.size > MAX_FILE_SIZE) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: "La imagen no debe pesar más de 1MB.",
      });
    } else {
      setFileData((state) => ({
        ...state,
        selectedFiles: [...state.selectedFiles, ...files],
      }));
      files.forEach(handleChangeStatus);
    }
  };
  const uploadArchivesHandler = async (archives: UploadGallery[]) => {
    setLoading(true);

    try {
      if (isDemo) {
        throw new Error(
          "No puedes subir archivos, estas usando una cuenta demo."
        );
      }

      const TO_CHECK = checkMapper(archives);
      const response = await checkArchives(TO_CHECK);

      if (response?.error) {
        throw new Error(response.error);
      }

      await uploadArchives(archives);
      toast({
        variant: "successful",
        title: "Los archivos se han cargado exitosamente",
        description: "Los datos de tu cuenta han sido actualizados.",
      });

      refresh();
      setFileData({ selectedFiles: [], base64strings: [] });
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message || "Algo salió mal. Intenta nuevamente.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    const mappedFiles = archivesMapper(
      fileData.selectedFiles,
      fileData.base64strings,
      patientId
    );
    uploadArchivesHandler(mappedFiles);
  };
  const handleRemoveFile = (fileToRemove: File) => {
    setFileData((state) => ({
      selectedFiles: state.selectedFiles.filter(
        (file) => file !== fileToRemove
      ),
      base64strings: state.base64strings.filter(
        (base64) => base64.name !== fileToRemove.name
      ),
    }));
  };

  return (
    <div className="w-full mt-2 flex flex-col items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
      {fileData?.selectedFiles?.length > 0 ? (
        <div className="text-center mt-2">
          <p className="text-gray-600">Archivos seleccionados:</p>
          {fileData.selectedFiles.map((file, index) => (
            <div
              key={index}
              className="font-medium text-gray-700 mt-4 w-full relative"
            >
              <div className="grid grid-cols-12 gap-4 items-center">
                <div className="col-span-10 flex justify-center items-center">
                  <p className="text-center">{file.name}</p>
                </div>
                <div className="col-span-1 flex justify-center">
                  <Button
                    id="delete-archive"
                    onClick={() => handleRemoveFile(file)}
                    variant="destructive"
                    className="top-0 left-0"
                  >
                    <LuTrash2 className="w-4 h-4" />
                  </Button>
                </div>
              </div>
              {file.type?.startsWith("image/") && (
                <div className="flex justify-center items-center mt-2 h-full w-full">
                  <Image
                    src={URL.createObjectURL(file)}
                    alt={`Selected preview ${index}`}
                    className="h-4/5 w-4/5 rounded-md shadow-lg"
                    width={100}
                    height={100}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="py-10 flex-col items-center text-center">
          <HiOutlineCloudArrowUp
            type="upload"
            className="mx-auto h-12 w-12 text-slate-500"
          />
          <div>Seleccioná los archivos</div>
        </div>
      )}
      <div className="mt-4 flex flex-row items-center space-x-2">
        <Input
          type="file"
          onChange={handleFileChange}
          accept={allowExtensions}
          className="select-archive cursor-pointer"
          multiple
        />
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button
            type="submit"
            disabled={fileData.selectedFiles.length === 0}
            onClick={handleSubmit}
            className="save-archive"
          >
            Guardar
          </Button>
        )}
      </div>
    </div>
  );
}
