"use client";

import React from "react";
import { acceptTermsAndConditions } from "@/actions/recipes";
import {
  Button,
  Checkbox,
  DialogClose,
  DialogContent,
} from "@/components/atoms";
import { toast } from "@/hooks";
import { terms_and_conditions } from "@/libs/prescriptions";
import { useBoundStore } from "@/hooks";

export const TermsAndConditions = () => {
  const [isAccepted, setIsAccepted] = React.useState<boolean>(false);
  const { updateUser } = useBoundStore();
  const onSubmit = async () => {
    if (isAccepted) {
      try {
        const prescriptions_tyc = await acceptTermsAndConditions();
        updateUser("prescriptions_tyc", prescriptions_tyc);

        return toast({
          variant: "successful",
          title: "Ya puedes realizar recetas",
          description:
            "Haz nuevamente click en nueva receta para realizar una.",
        });
      } catch (err: any) {
        return toast({
          variant: "warning",
          title: "Ups, parece que hubo un error",
          description: err.message,
        });
      }
    }
  };

  return (
    <DialogContent>
      <div className="h-64 overflow-y-auto my-5 bg-slate-100">
        {terms_and_conditions}
      </div>
      <div className="space-x-2 flex items-center">
        <Checkbox onClick={() => setIsAccepted(!isAccepted)} />
        <label className="text-base font-medium leading-none text-slate-600">
          He leído y estoy de acuerdo con los{" "}
          <strong>Términos y Condiciones</strong> de Bilog
        </label>
      </div>
      <div className="flex mt-5 space-x-4 justify-end">
        <DialogClose>
          <Button className="flex justify-center w-max px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500">
            Cancelar
          </Button>
        </DialogClose>
        <DialogClose>
          <Button
            className="flex justify-center w-max px-10 h-10 hover:bg-blue-600"
            onClick={onSubmit}
            disabled={!isAccepted}
          >
            Guardar
          </Button>
        </DialogClose>
      </div>
    </DialogContent>
  );
};
