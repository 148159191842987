"use client";

import { format } from "date-fns";
import { ImWhatsapp as Whatsapp } from "react-icons/im";
import { Button, SheetContent, ScrollArea } from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { Table as Appointments } from "@/components/organisms/tables/dailies/appointments";
import { Table as Reminders } from "@/components/organisms/tables/dailies/reminders";
import { sendBirthdayReminders } from "@/actions/reminders";
import { toast } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { DailiesDataPatient, BirthdayPatient } from "@/types/statistics";
import { transformDateToZoneTime } from "@/utils/date";

export const getTables = ({
  data,
  number,
}: {
  data: BirthdayPatient[] | DailiesDataPatient[];
  number: number;
}) => {
  const tables = [
    {
      title: "Turnos de pacientes que ya conoces",
      component: () => <Appointments data={data as DailiesDataPatient[]} />,
    },
    {
      title: "Turnos de pacientes nuevos",
      component: () => <Appointments data={data as DailiesDataPatient[]} />,
    },
    {
      title: "Enviar saludo de cumpleaños",
      component: () => <Reminders data={data as BirthdayPatient[]} />,
    },
    {
      title: "Pedidos que deberían ingresar hoy",
      component: () => <Appointments data={data as DailiesDataPatient[]} />,
    },
  ];

  return tables[number];
};

export const getSheets = ({
  data,
  number,
}: {
  data: BirthdayPatient[] | DailiesDataPatient[];
  number: number;
}) => {
  const sheets = [
    () => (
      <Content
        key={number}
        data={data as DailiesDataPatient[]}
        title="Turnos de pacientes que ya conoces"
      />
    ),
    () => (
      <Content
        key={number}
        data={data as DailiesDataPatient[]}
        title="Turnos de pacientes nuevos"
      />
    ),
    () => (
      <BirdthdayContent
        key={number}
        data={data as BirthdayPatient[]}
        title="Enviar saludo de cumpleaños"
      />
    ),
    () => (
      <Content
        key={number}
        data={data as DailiesDataPatient[]}
        title="Pedidos que deberían ingresar hoy"
      />
    ),
  ];

  if (data.length === 0) return;

  return sheets[number]();
};

const Content = ({
  data,
  title,
}: {
  data: DailiesDataPatient[];
  title: string;
}) => {
  return (
    <SheetContent side="right" className="w-full">
      <h3 className="text-slate-800 text-2xl font-bold">{title}</h3>
      <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10">
        {data.map((item: DailiesDataPatient, i: number) => (
          <div key={i} className="py-4 border-b last:border-none">
            <p className="text-sm text-slate-400">
              {item.hour} - {format(item.date, "dd/MM/yyyy")}
            </p>
            <p className="text-base font-bold text-slate-800">{item.patient}</p>
            <p className="text-sm  text-slate-800">{item.professional}</p>
          </div>
        ))}
      </ScrollArea>
    </SheetContent>
  );
};

const BirdthdayContent = ({
  data,
  title,
}: {
  data: BirthdayPatient[];
  title: string;
}) => {
  const { isDemo, startLoading, stopLoading, loading } = useBoundStore();
  const selected = data
    .map((d: BirthdayPatient) => {
      return {
        paciente: d.patient,
        nroTel: d.phone,
      };
    })
    .filter((s: { paciente: string; nroTel: string }) => s.nroTel);

  async function onSubmit(messages: { paciente: string; nroTel: string }[]) {
    startLoading();

    const data = {
      fecha: transformDateToZoneTime(),
      opcion: "recordarcumple",
      listawhatsapps: messages,
    };

    if (isDemo) {
      return toast({
        variant: "destructive",
        title: "Usuario de demostración",
        description:
          "Este es un usuario de demostración y no puede enviar mensajes.",
      });
    }

    const response = await sendBirthdayReminders(data);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "¡Mensajes enviados correctamente!",
        description:
          "Los mensajes de saludos de cumpleaños se enviaron correctamente",
      });
    }

    stopLoading();
  }

  return (
    <SheetContent side="right" className="w-full">
      <h3 className="text-slate-800 text-2xl font-bold">{title}</h3>
      <p className="text-base text-slate-600 leading-5 mt-2">
        Mandales un mensaje de feliz cumpleaños a tus pacientes que cumplen años
        hoy.
      </p>
      {loading ? (
        <ButtonLoading className="mt-4 bg-green-500 hover:bg-green-600" />
      ) : (
        <Button
          className="mt-4 bg-green-500 hover:bg-green-600 flex gap-x-2"
          onClick={() => onSubmit(selected)}
        >
          <Whatsapp className="text-white h-5 w-5" />
          <p className="m-0 p-0">Enviar mensajes</p>
        </Button>
      )}
    </SheetContent>
  );
};
