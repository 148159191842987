"use client";

import React from "react";
import { useBoundStore } from "@/hooks";
import { formSchema } from "@/components/organisms/forms/configuration/copy-medical-insurances/schema";
import { EmptyState } from "@/components/molecules";
import { Benefits } from "@/components/molecules/geist"; // TODO: Refactor this import
import { Form, Benefit } from "@/types/general";
import { Columns } from "./columns";
import { DataSource, DataDestination } from "./data";

export const Table: React.FC<{
  form: Form<typeof formSchema>;
  source: Benefit[];
  destination: Benefit[];
}> = ({ form, source, destination }) => {
  const { loading } = useBoundStore();

  return loading ? (
    <div className="grid grid-cols-4 gap-4 mx-auto mt-4">
      <div className="col-span-2">
        <Benefits />
      </div>
      <div className="col-span-2">
        <Benefits />
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-12 gap-x-4">
      <div className="col-span-6">
        {source.length > 0 ? (
          <DataSource
            form={form}
            columns={Columns({ showAction: true })}
            data={source}
          />
        ) : (
          <EmptyState
            title="Ups!"
            className="mx-auto h-96"
            copy="No se han encontrado registros."
          />
        )}
      </div>
      <div className="col-span-6 ">
        {destination.length > 0 ? (
          <DataDestination
            columns={Columns({ showAction: false })}
            data={destination}
          />
        ) : (
          <EmptyState
            title="Ups!"
            className="mx-auto h-96"
            copy="No se han encontrado registros."
          />
        )}
      </div>
    </div>
  );
};
