import { saveTurn } from "@/actions/schedule";
import {
  Button,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog, useScheduleUpdate } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { Appointment } from "@/types/general";
import { incrementOneMinute } from "@/utils/date";

export const DialogAca = ({
  appointment,
  attendance,
  id_user,
  refresh,
  setChangeDataTable,
}: {
  appointment: Appointment;
  attendance: string;
  id_user: number;
  refresh: () => void;
  setChangeDataTable: (value: string) => void;
}) => {
  const { startLoading, stopLoading } = useBoundStore();
  const { startRefresh } = useScheduleUpdate();
  const { closeDialog } = useDialog();
  const handleSubmit = async () => {
    startLoading();
    const AVAILABLE_TIME = incrementOneMinute(appointment.time);
    const response = await saveTurn({
      appointment: {
        ...appointment,
        time: AVAILABLE_TIME || appointment.time,
      },
      attendance,
      id_user,
      option: appointment.id_patient
        ? "agregarturnopacexist"
        : "agregarTurnoPac1Vez",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Turno movido con éxito",
        description: `El turno fue movido al ${AVAILABLE_TIME}`,
      });
    }
    closeDialog();

    await refresh();
    setChangeDataTable("");
    stopLoading();
  };

  return (
    <DialogContent>
      <DialogTitle>Aviso</DialogTitle>
      <DialogDescription>
        {`Al colocar "Ausente con Aviso", el turno será movido al siguiente turno disponible, 
y el turno de las ${appointment.time} quedará libre. ¿Desea mover el turno?`}
      </DialogDescription>
      <div className="flex justify-end gap-x-4 mt-4">
        <Button
          variant="destructive"
          onClick={() => {
            closeDialog();
            startRefresh();
          }}
        >
          Cancelar
        </Button>
        <Button onClick={handleSubmit}>Mover</Button>
      </div>
    </DialogContent>
  );
};
