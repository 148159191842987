import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Button,
  Input,
  Combobox,
} from "@/components/atoms";
import { Form, Offices, Professional } from "@/types/general";
import { Prescription } from "@/types/patients";
import {
  DatePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { toast, useBoundStore } from "@/hooks";
import { Financier } from "@/types/recipes";
import { getProfessionalSignature } from "@/actions/professionals";
import { formSchema } from "../schema";

export const First: React.FC<{
  data: {
    financiers: Financier[];
    offices: Offices[];
    professionals: Professional[];
    recipe: Prescription;
  };
  onCancel: () => void;
  resetProgressBar?: () => void;
  viewLoadMedicine?: {
    hiddenLoadMedicine: boolean;
    setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
  };
  handlerClickProgressBar?: (value: number) => void;
  onSubmit?: (data: FormData) => void;
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
}> = ({ data, form, setStep, onCancel }) => {
  const {
    general_params: { translations, user_params },
  } = useBoundStore();
  const { id_financier, id_office, affiliate_number, id_professional } =
    form.watch();
  const filter_professionals = data.professionals?.filter(
    (item) => !item.is_guard && item.enabled
  );
  const DISABLED_NEXT_BUTTON =
    id_financier !== -1 && !(filter_professionals.length && affiliate_number);
  const professional = React.useMemo(() => {
    return filter_professionals?.find(
      (prof: any) => prof.id_professional === id_professional
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_professional]);
  const actionButtonNext = async () => {
    if (professional) {
      const response = await getProfessionalSignature(
        professional?.id_professional
      );

      if ("error" in response) {
        return toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response.error,
        });
      } else {
        if (!response.professionalSignature)
          return toast({
            variant: "warning",
            title: "Complete el perfil en Configuración/Profesionales.",
            description: "El profesional no tiene firma definida.",
          });
        const signature = response.professionalSignature;
        const {
          abbreviation,
          profession,
          licence,
          licence_jurisdiction,
          licence_type,
          tax_number,
        } = professional;
        const VALIDATE_JURISDICTION =
          licence_type === "MP" ? licence_jurisdiction : true;

        if (tax_number?.length > 11 || !/^\d+$/.test(tax_number)) {
          return toast({
            variant: "warning",
            title: "Complete el perfil en Configuración/Profesionales.",
            description:
              "El CUIT del profesional es incorrecto. Tiene que ser numérico y de 11 dígitos.",
          });
        }

        if (
          user_params?.tax_number?.length > 11 ||
          !/^\d+$/.test(user_params?.tax_number)
        ) {
          return toast({
            variant: "warning",
            title: "Complete el perfil en Configuración/Profesionales.",
            description:
              "El CUIT de la clínica es incorrecto. Tiene que ser numérico y de 11 dígitos.",
          });
        }

        if (!licence) {
          return toast({
            variant: "warning",
            title: "Complete el perfil en Configuración/Profesionales.",
            description:
              "El número de matrícula del profesional es incorrecto o no esta definido.",
          });
        }

        return abbreviation &&
          profession &&
          licence &&
          licence_type &&
          VALIDATE_JURISDICTION &&
          tax_number?.length >= 11 &&
          signature
          ? setStep(1)
          : toast({
              variant: "warning",
              title: "Complete el perfil en Configuración/Profesionales.",
              description:
                "Los datos del profesional son insuficientes para realizar una receta.",
            });
      }
    }
  };
  React.useEffect(() => {
    form.setValue(
      "id_professional",
      filter_professionals?.[0]?.id_professional
    );
  }, [id_office]);

  return (
    <Card className="!rounded-t-none">
      <CardHeader>
        <CardTitle>Datos de la receta</CardTitle>
        <CardDescription>
           Añade los datos de tu obra social y profesional a la receta. Estos se
          guardarán para futuros usos.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <FormField
          control={form.control}
          name="full_name"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>Nombre y apellido</FormLabel>
              <FormControl>
                <Input type="text" {...field} disabled />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="document"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>Nº de Documento</FormLabel>
              <FormControl>
                <Input type="text" {...field} disabled />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Combobox
          form={form}
          data={data.financiers}
          dataKey="id_financier"
          value="name"
          placeholder={`Busca un/a ${translations.medical_insurance}`}
          title={translations.medical_insurance}
          className="col-span-3"
          classContent="!w-[300px]"
          label={translations.medical_insurance}
        />
        <FormField
          control={form.control}
          name="plan"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>Tipo de plan</FormLabel>
              <FormControl>
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="affiliate_number"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>Nro. de afiliado*</FormLabel>
              <FormControl>
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="col-span-3 self-end">
          <DatePicker
            form={form}
            name="date_of_recipe"
            label="Fecha de receta"
            dateformat="dd/MM/yyyy"
          />
        </div>
        {id_office && (
          <Combobox
            form={form}
            data={data.offices}
            dataKey="id_office"
            value="name"
            placeholder="Busca una sucursal"
            title="sucursal"
            classContent="!w-[300px]"
            label="Sucursal"
            className="col-span-3"
          />
        )}
        <Combobox
          form={form}
          data={filter_professionals}
          dataKey="id_professional"
          value="name"
          placeholder="Busca un profesional"
          title="profesional"
          classContent="!w-[300px]"
          className="col-span-3"
          label="Profesional"
        />
        <div className="col-span-full space-x-4 flex justify-end">
          <Button type="button" variant="destructive" onClick={onCancel}>
            Cancelar
          </Button>
          <Button
            type="button"
            onClick={actionButtonNext}
            disabled={DISABLED_NEXT_BUTTON}
          >
            Siguiente
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
