import { z } from "zod";
import { LuCircleCheck, LuPen, LuTrash2, LuEye } from "react-icons/lu";
import { HiDotsVertical } from "react-icons/hi";
import { FcCancel } from "react-icons/fc";
import classNames from "classnames";
import { format } from "date-fns";
import {
  Badge,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/atoms";
import { formSchema } from "@/components/organisms/forms/patients/laboratory/schema";
import { useRestrictedPatientAction } from "@/hooks/use-patient-restricted-action";

const Dropdown = ({
  laboratory,
  action,
  config: { permissions, is_supervisor },
}: {
  laboratory: any;
  action: (
    laboratory: z.infer<typeof formSchema>["order_from_list"],
    type: string
  ) => void;
  config: { permissions: Record<string, boolean>; is_supervisor: boolean };
}) => {
  const Action = ({
    children,
    type,
  }: {
    children: React.ReactNode;
    type: string;
  }) => {
    return (
      <DropdownMenuItem
        className="hover:cursor-pointer"
        onSelect={useRestrictedPatientAction({
          callback: () => action(laboratory, type),
          id: laboratory.id_professional,
        })}
      >
        {children}
      </DropdownMenuItem>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="relative p-2 hover:cursor-pointer flex-none">
          <HiDotsVertical className="text-gray-500 hover:text-gray-900 h-4 w-4" />
          <span className="sr-only">Abrir menu</span>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuGroup>
          <Action type="edit">
            <LuEye className="mr-2 size-4" />
            <span>Ver detalles</span>
          </Action>
          {(is_supervisor ||
            permissions.hasOwnProperty("Modificar Pedido")) && (
            <Action type="edit">
              <LuPen className="mr-2 size-4" />
              <span>Modificar</span>
            </Action>
          )}
          {(is_supervisor || permissions.hasOwnProperty("Eliminar Pedido")) && (
            <Action type="delete">
              <LuTrash2 className="mr-2 size-4" />
              <span>Eliminar</span>
            </Action>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const columns = ({
  action,
  config,
}: {
  action: (
    order: z.infer<typeof formSchema>["order_from_list"],
    type: string
  ) => void;
  config: {
    permissions: any;
    is_supervisor: boolean;
  };
}) => {
  return [
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <Dropdown action={action} laboratory={row.original} config={config} />
        );
      },
    },
    {
      accessorKey: "id_order",
      header: "N° y estado",
      cell: ({ row }: { row: any }) => {
        const { id_order, entry_date, paid } = row.original;
        const validateStatus = () => {
          const STATUS: { [key: string]: { [key: string]: string } } = {
            order: {
              text: "Emitido",
              style: "bg-slate-50 text-slate-600 border-slate-600/10",
            },
            paid: {
              text: "Pagado",
              style: "bg-green-50 text-green-600 border-green-600/10",
            },
            received: {
              text: "Recibido",
              style: "bg-yellow-100 !text-yellow-500 border-yellow-600/10",
            },
          };

          return entry_date && paid
            ? STATUS.paid
            : entry_date
            ? STATUS.received
            : STATUS.order;
        };
        const STATUS = validateStatus();

        return (
          <div className="flex gap-x-2">
            <Badge>{`N°: ${id_order}`}</Badge>
            <Badge className={classNames("w-auto", STATUS.style)}>
              {STATUS.text}
            </Badge>
          </div>
        );
      },
    },
    {
      accessorKey: "date_order",
      header: "Fecha pedido",
      cell: ({ row }: { row: any }) => {
        const { date_order } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {format(date_order, "dd/MM/yyyy")}
          </p>
        );
      },
    },
    {
      accessorKey: "entry_date",
      header: "Fecha recibido",
      cell: ({ row }: { row: any }) => {
        const { entry_date } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {entry_date ? format(new Date(entry_date), "dd/MM/yyyy") : "-"}
          </p>
        );
      },
    },
    {
      accessorKey: "is_usd",
      header: "En USD",
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return is_usd ? (
          <LuCircleCheck className="size-5 mx-auto text-green-500" />
        ) : (
          <FcCancel className="size-6 mx-auto" />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: "amount_laboratory",
      header: "Importe Laboratorio",
      cell: ({ row }: { row: any }) => {
        const { lab_items } = row.original;
        const amount_laboratory = lab_items?.reduce(
          (amount: number, item: any) => amount + item.amount * item.quantity,
          0
        );

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {amount_laboratory?.toLocaleString("es-AR", {
              style: "currency",
              currency: "ARS",
            })}
          </p>
        );
      },
    },
    {
      accessorKey: "total_amount",
      header: "Importe total",
      cell: ({ row }: { row: any }) => {
        const { total_amount } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {total_amount?.toLocaleString("es-AR", {
              style: "currency",
              currency: "ARS",
            })}
          </p>
        );
      },
    },
  ];
};
