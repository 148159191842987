"use client";

import React from "react";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { Button, CardTitle } from "@/components/atoms";
import { Form, Laboratory, Professional } from "@/types/general";
import { splitNameFormatter } from "@/utils/helpers";
import { useBoundStore } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { useStateAcordeon } from "@/hooks";
import { StateOrder } from "./state-order";
import { Header } from "./header";
import { Summary } from "./card-summary";
import { formSchema } from "../schema";

export const Third = ({
  data: { professionals, laboratories },
  onSubmit,
  resetSteps,
  viewLoadOrder,
  form,
  setStep,
}: {
  data: {
    professionals: Professional[];
    laboratories: Laboratory[];
  };
  onSubmit: () => void;
  resetSteps: () => void;
  viewLoadOrder: {
    hiddenLoadOrder: boolean;
    setHiddenLoadOrder: React.Dispatch<React.SetStateAction<boolean>>;
  };
  form: Form<typeof formSchema>;
  setStep: (value: number) => void;
}) => {
  const {
    ref_header,
    loading,
    permissions,
    user: { is_supervisor },
  } = useBoundStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const {
    id_laboratory,
    description,
    observation,
    order_list,
    id_professional,
    date_order,
    total_amount_order_list,
    date_received,
    date_estimated_received,
    paid,
    amount_laboratory,
  } = form.watch();
  const router = useRouter();
  const { id_order } = form.getValues();
  const PERMISSIONS = !(
    id_order &&
    !permissions.hasOwnProperty("Modificar Pedido") &&
    !is_supervisor
  );
  const LABORATORY_NAME =
    id_laboratory && laboratories
      ? laboratories?.find((l: Laboratory) => l.id_laboratory === id_laboratory)
          ?.name
      : "-";
  const PROFESSIONAL_NAME =
    id_professional && professionals.length > 0
      ? splitNameFormatter(
          professionals?.find(
            (p: Professional) => p.id_professional === id_professional
          )?.name || ""
        )
      : "-";

  const SUMMARY_DATA = {
    header: {
      professional_name: PROFESSIONAL_NAME,
      total_amount_order_list,
      paid,
      date_received,
      amount_laboratory,
      description,
    },
    order: {
      date_received,
      date_order,
      date_estimated_received,
      laboratory_name: LABORATORY_NAME,
      description,
      professional_name: PROFESSIONAL_NAME,
      view_load_order: viewLoadOrder,
      observation,
    },
    state_order: {
      date_received,
      date_order,
      laboratory_name: LABORATORY_NAME,
      description,
      professional_name: PROFESSIONAL_NAME,
      order_list,
      paid,
    },
  };

  React.useEffect(() => {
    if (order_list?.length <= 0) {
      resetSteps();
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_list]);

  return (
    <>
      <div
        className={classNames(
          "!rounded-t-none overflow-y-auto",
          STATE_ACCORDION !== "open"
            ? "!h-[calc(100vh-305px)]"
            : "h-[calc(100vh-365px)]"
        )}
      >
        <CardTitle className="text-lg mt-6 print:hidden mb-2">
          Resumen del pedido
        </CardTitle>
        <span className="print:hidden mb-4 text-slate-600">
          Vea los detalles de su pedido para laboratorio y edite de ser
          necesario.
        </span>
        {[Header, Summary, StateOrder].map((Component: any, index: number) => {
          return (
            <Component
              key={index}
              data={SUMMARY_DATA}
              setStep={setStep}
              form={form}
            />
          );
        })}
      </div>
      <div className="flex justify-end mt-4 gap-5 print:hidden">
        {id_order && (
          <Button
            variant="ghost"
            type="button"
            onClick={() =>
              router.push(`/api/download/laboratory?idOrder=${id_order}`)
            }
            disabled={!permissions.hasOwnProperty("Imprimir Pedido")}
          >
            Descargar PDF
          </Button>
        )}
        {loading ? (
          <ButtonLoading />
        ) : (
          PERMISSIONS && <Button onClick={onSubmit}>Guardar</Button>
        )}
      </div>
    </>
  );
};
