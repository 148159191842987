"use client";

import React from "react";
import { compareAsc, parse } from "date-fns";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import { LineCharts, ChartPie, Card, CardTitle } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useBoundStore } from "@/hooks";
import { ControlPanel } from "@/types/statistics";

export const ControlPanelGraphics = ({
  data,
  device,
}: {
  data: ControlPanel;
  device: string;
}) => {
  const {
    statistics: {
      control: { data: values },
    },
    setInitializedDataStatistics,
  } = useBoundStore();

  React.useEffect(() => {
    setInitializedDataStatistics({ data, section: "control" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PIES_VALUES = React.useMemo(
    () => [
      {
        title: "Pacientes Nuevos",
        data: values?.NEW_PATIENT_DATA?.data_groups,
      },
      {
        title: "Pacientes Atendidos",
        data: values?.ATTENDED_PATIENTS?.data_groups,
      },
    ],
    [values?.NEW_PATIENT_DATA?.data_groups]
  );

  const groupedByPeriod =
    (values?.BENEFITS &&
      values?.PAYMENTS &&
      [...values?.BENEFITS, ...values?.PAYMENTS]?.reduce((acc, obj) => {
        const period = obj.period;
        if (!acc[period]) {
          acc[period] = [];
        }
        acc[period].push(obj);
        return acc;
      }, {})) ||
    {};
  const parseDate = (dateString: string): Date => {
    return parse(
      dateString,
      "dd/MM/yyyy",
      new TZDate(new Date().toISOString(), TIMEZONES.AR)
    );
  };

  const result = Object.keys(groupedByPeriod).map((date: any) => {
    const principal = groupedByPeriod[date]?.[1]
      ? groupedByPeriod[date]?.[1]?.quantity
      : 0;
    const comparacion = groupedByPeriod[date]?.[0]
      ? groupedByPeriod[date]?.[0]?.quantity
      : 0;
    return {
      period: date,
      principal,
      comparacion,
    };
  });
  const ORDER_VALUE = result.sort((a, b) =>
    compareAsc(parseDate(a.period), parseDate(b.period))
  );

  return (
    <div className="mb-10 lg:grid lg:placeholder lg:grid-cols-12 gap-5 flex flex-col">
      {PIES_VALUES.map(
        (item: { title: string; data: any[] }, index: number) => (
          <Card className="col-span-6 flex flex-col p-8 px-6" key={index}>
            <CardTitle>{item.title}</CardTitle>
            {item.data ? (
              <ChartPie values={item.data} dataKey="quantity" device={device} />
            ) : (
              <EmptyState title="Ups!" copy="No hay datos para mostrar" />
            )}
          </Card>
        )
      )}
      <Card className="col-span-6">
        <CardTitle className="mb-10">Turnos Agendados</CardTitle>
        {values?.SCHEDULE_PATIENTS ? (
          <LineCharts
            values={values?.SCHEDULE_PATIENTS}
            height={300}
            Xkey="period"
            BarKey="quantity"
            legend="Turnos agendados"
          />
        ) : (
          <EmptyState title="Ups!" copy="No hay datos para mostrar" />
        )}
      </Card>
      <Card className="col-span-6">
        <CardTitle className="mb-10">
          Pagos ingresados y prestaciones realizadas
        </CardTitle>
        {ORDER_VALUE.length ? (
          <LineCharts
            values={ORDER_VALUE}
            height={300}
            Xkey="period"
            BarKey="principal"
            CompareKey="comparacion"
            compare
            legend="Pagos ingresados"
            legendCompare="Prestaciones realizadas"
          />
        ) : (
          <EmptyState title="Ups!" copy="No hay datos para mostrar" />
        )}
      </Card>
    </div>
  );
};
