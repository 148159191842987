import React from "react";
import { z } from "zod";
import { formSchema } from "@/components/organisms/forms/patients/laboratory/schema";
import { useBoundStore } from "@/hooks";
import { Form } from "@/types/general";
import { Columns } from "./columns";
import { Data } from "./data";

export const Table = <TData,>({
  data,
  action,
  form,
}: {
  data: TData[];
  action: (
    row: z.infer<typeof formSchema>["order_from_list"],
    key: string
  ) => void;
  form: Form<typeof formSchema>;
}) => {
  const {
    permissions,
    user: { is_supervisor },
  } = useBoundStore();

  return (
    <div className="mx-auto w-full">
      <Data
        columns={Columns({
          action,
          config: {
            permissions,
            is_supervisor,
            form,
          },
        })}
        data={data}
        form={form}
      />
    </div>
  );
};
