"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  Button,
  Input,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
  Combobox,
  Skeleton,
} from "@/components/atoms";
import { toast } from "@/hooks";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  DatePicker,
} from "@/components/molecules";
import {
  getBankMovement,
  setBankMovement,
  getMovements,
} from "@/actions/administration";
import { useDialog, useKeypress } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { MOVEMENT_TYPES } from "@/libs/administration";
import { resolver, formSchema } from "./schema";

export function NewMovementForm({
  idMovement,
  idBank,
}: {
  idMovement?: number;
  idBank?: number;
}) {
  const { closeDialog } = useDialog();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      id_movement: 0,
      id_bank: idBank,
      id_user: null,
      date: new TZDate(new Date().toISOString(), TIMEZONES.AR),
      concept: "",
      amount: 0,
      type: "",
    },
  });
  const {
    startLoading,
    stopLoading,
    loading,
    setDataAdministration,
    administration: { movements },
  } = useBoundStore();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setBankMovement(values);

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const { banks: mvnts }: any = await getMovements(movements.params);

      setDataAdministration({
        data: mvnts,
        params: values,
        section: "movements",
      });
      closeDialog();
    }

    stopLoading();
  };

  React.useEffect(() => {
    async function getData() {
      startLoading();
      const response = await getBankMovement(idMovement as number);

      if ("error" in response) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response?.error,
        });
      } else {
        form.reset(response as z.infer<typeof formSchema>);
      }

      stopLoading();
    }

    if (!idMovement) return;

    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMovement]);

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <DialogContent className="w-1/2">
      <DialogHeader>
        <DialogTitle>Nuevo movimiento bancario</DialogTitle>
      </DialogHeader>
      {loading ? (
        <Skltn />
      ) : (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="grid grid-cols-12 gap-x-4 gap-y-6"
          >
            <div className="col-span-6">
              <DatePicker label="Fecha" name="date" form={form} />
            </div>
            <FormField
              control={form.control}
              name="concept"
              render={({ field }) => (
                <FormItem className="col-span-12">
                  <FormLabel>Concepto</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem className="col-span-6">
              <FormLabel>Tipo</FormLabel>
              <Combobox
                form={form}
                data={MOVEMENT_TYPES}
                dataKey="type"
                value="text"
                placeholder="Tipo de movimiento"
                title="movimiento"
              />
            </FormItem>
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Importe</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      )}
      <DialogFooter className="grid grid-cols-12">
        <DialogClose className="col-span-2 col-start-9">
          <Button type="button" onClick={form.reset} variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        <Button className="col-span-2" onClick={form.handleSubmit(onSubmit)}>
          Guardar
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}

const Skltn = () => (
  <>
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-5 w-1/4" />
      <Skeleton className="h-10 w-1/2" />
    </div>
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-5 w-1/4" />
      <Skeleton className="h-10 w-full" />
    </div>
    <div className="grid grid-cols-2 gap-x-10">
      {Array.from({ length: 2 }).map((_, i) => (
        <div key={i} className="flex flex-col space-y-3">
          <Skeleton className="h-5 w-1/4" />
          <Skeleton className="h-10 w-full" />
        </div>
      ))}
    </div>
  </>
);
