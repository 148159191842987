import React from "react";
import classNames from "classnames";
import { LuCheck } from "react-icons/lu";
import { TabsList, TabsTrigger } from "@/components/atoms";
import { useBoundStore } from "@/hooks";
import { Form, Plan } from "@/types/general";

export const ProgressBar: React.FC<{
  length: number;
  onClick: (value: number) => void;
  stepsBar: {
    id: number;
    name: string;
    status: "complete" | "current" | "upcoming";
    position: string;
  }[];
  form: Form<any>;
  disabled?: boolean;
}> = ({ length, stepsBar, onClick, form, disabled }) => {
  const {
    general_params: { professionals, medical_insurances, laboratories },
  } = useBoundStore();
  const itemForDescription = React.useMemo(() => {
    return (key: string) => {
      const [filter_value] = form.watch([key]);
      const values: { [key: string]: any } = {
        id_professional: professionals,
        id_med_ins: medical_insurances,
        id_laboratory: laboratories,
      };
      const item = values[key]?.find(
        (value: any) => value[key] === filter_value
      );
      const MED_INS_NAME = item?.medical_insurance || "";
      const PLAN =
        (MED_INS_NAME &&
          item.plans?.find(
            (value: Plan) => value.id_plan === form.getValues("id_plan")
          )?.name) ||
        "";
      const item_name: { [key: string]: string } = {
        id_professional: item?.name,
        id_med_ins: `${MED_INS_NAME}-${PLAN}`,
        id_laboratory: item?.name,
      };

      return item_name[key];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch("id_plan")]);

  return (
    <TabsList
      className={`grid w-full h-full items-center px-4 print:hidden !p-0 !bg-white !rounded-b-none grid-cols-${length}`}
    >
      {stepsBar.map((step: any, i: number) => (
        <TabsTrigger
          value={step.position}
          className={classNames(
            "cursor-pointer flex items-center !justify-between data-[state=active]:!shadow-none h-full",
            { "!pointer-events-none": step.status! === "upcoming" }
          )}
          key={i}
          onClick={() => onClick(i)}
          disabled={disabled}
        >
          <div className="flex items-center space-x-2">
            <div
              className={classNames(
                "text-center border-2 rounded-full col-span-2 size-7 flex items-center justify-center",
                step.status === "current" && "border-blue-500 text-blue-500",
                step.status === "upcoming" && "text-slate-300 border-slate-300",
                step.status === "complete" &&
                  "bg-blue-500 text-white border-blue-500"
              )}
            >
              {step.status === "complete" ? (
                <LuCheck className="size-4" />
              ) : (
                <div className="font-bold">0{step.id}</div>
              )}
            </div>
            <div
              className={classNames(
                "font-bold flex flex-col items-start text-sm col-span-8 lg:scale-100 md:scale-75 text-slate-500",
                step.status === "current" && "text-blue-500",
                step.status === "upcoming" && "text-slate-300"
              )}
            >
              <p className="truncate">{step.name}</p>
              {(step.description || step.key) && (
                <p className="text-xs !font-normal truncate max-w-32">{`${
                  step.description
                }${itemForDescription(step.key)}`}</p>
              )}
            </div>
          </div>
          {i !== stepsBar.length - 1 && (
            <div className="rotate-45 col-span-4 size-9 rounded-tr-lg border-slate-300 border-r border-t" />
          )}
        </TabsTrigger>
      ))}
    </TabsList>
  );
};
