import React from "react";
import { numberToCurrency } from "@/utils/currency";

export const AmountsBar = ({ form }: { form: any }) => {
  const { tax, amount, discount, interest } = form.getValues();
  const IMPORTS = [
    {
      label: "Importe unitario",
      value: numberToCurrency(amount),
    },
    {
      label: "IVA",
      value: `${tax} %`,
    },
    {
      label: "Descuento",
      value: numberToCurrency(discount),
    },
    {
      label: "Interés",
      value: `${interest} %`,
    },
  ];

  return (
    <div>
      <div className="pb-5">
        <span className="text-slate-600 font-bold">
          Importe unitario final:{" "}
          <span className="text-lg text-green-600">
            {numberToCurrency(amount)}
          </span>
        </span>
      </div>
      <div className="col-span-12 grid grid-cols-12 border border-slate-200 mb-10 bg-white border-r-0">
        {IMPORTS?.map((item, index) => {
          return (
            <span
              key={index}
              className="col-span-3 p-3 text-sm text-slate-500 border-r border-r-slate-200"
            >
              {item.label}:
              <strong className="font-semibold pl-2">{item.value}</strong>
            </span>
          );
        })}
      </div>
    </div>
  );
};
