import React from "react";
import Image from "next/image";
import { LuChevronRight, LuTrash2 } from "react-icons/lu";
import { useBoundStore } from "@/hooks";
import { Input } from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { toast } from "@/hooks";
import { Form } from "@/types/general";
import { formSchema } from "./schema";

export const UploadLogo = ({ form }: { form: Form<typeof formSchema> }) => {
  const {
    general_params: {
      user_params: { what_region },
    },
  } = useBoundStore();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const { recipe_logo } = form.watch();
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (!file) return;

    const MAX_SIZE_MB = 1;
    if (file.size > MAX_SIZE_MB * 1024 * 1024) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: " La imagen no debe pesar más de 1MB.",
      });
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        const IMAGE_SOURCE = reader.result as string;

        const IMG = new window.Image();
        IMG.src = IMAGE_SOURCE;
        IMG.onload = () => {
          if (IMG.width > 640 || IMG.height > 480) {
            toast({
              variant: "destructive",
              title: "Ups! Parece que hubo un error",
              description: "Las dimensiones máximas son 640x480 píxeles.",
            });

            return;
          } else {
            const DATA_IMAGE = IMAGE_SOURCE.split(",")[1];
            form.setValue("recipe_logo", DATA_IMAGE);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleCleanInputFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    form.setValue("recipe_logo", null);
  };

  if (what_region?.toLowerCase() !== "arg") return null;

  return (
    <>
      <FormField
        control={form.control}
        name="recipe_logo"
        render={() => (
          <FormItem>
            <div className="flex flex-col">
              <FormLabel className="!text-md !font-bold">
                Adjuntar logo
              </FormLabel>
              <ul className="text-sm">
                Sube la imagen de tu logo para personalizar tu perfil. Asegúrate
                de que cumpla con estos requisitos:
                <li />✔ Formato: PNG, JPEG, JPG
                <li />✔ Dimensiones: 640 × 480 px
                <li />✔ Tamaño máximo: 1 MB
              </ul>
            </div>
            <FormControl>
              <div className="flex w-full justify-between">
                <label className="relative w-full flex justify-between items-center text-blue-500 cursor-pointer">
                  <span>
                    {recipe_logo ? "Cambiar logo" : "Seleccionar logo"}
                  </span>
                  <Input
                    ref={fileInputRef}
                    type="file"
                    accept="image/jpeg,image/jpg,image/png"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  <span className="absolute right-3">
                    <LuChevronRight />
                  </span>
                </label>
                <div
                  className="w-full flex items-center justify-between text-blue-500 cursor-pointer"
                  onClick={handleCleanInputFile}
                >
                  <span>Limpiar logo</span>
                  <LuTrash2 />
                </div>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {recipe_logo && (
        <Image
          alt="logo recetas"
          src={`data:image/png;base64,${recipe_logo}`}
          width={140}
          height={140}
          className="h-32 mb-5 w-auto"
        />
      )}
    </>
  );
};
