"use client";

import * as React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { LuSettings } from "react-icons/lu";
import classNames from "classnames";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/atoms";
import { sideBarConfig } from "@/libs/sidebars";
import { useBoundStore } from "@/hooks";
import { usePermissionsView } from "@/hooks";

export function ConfigurationSidebar({
  ...props
}: React.ComponentProps<typeof Sidebar>) {
  const pathname = usePathname();
  const isActive = (url: string) => pathname.includes(url);
  const isSubMenuActive = (
    items?:
      | {
          title: string;
          url: string;
          isActive: boolean;
        }[]
      | null
      | string
  ) => {
    return typeof items !== "string"
      ? items?.some((current) => pathname.includes(current.url))
      : isActive(items);
  };
  const {
    permissions,
    user: { is_supervisor },
    general_params: { translations },
  } = useBoundStore();
  const { SECTION } = usePermissionsView("configuration");

  return (
    <Sidebar {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <div>
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-slate-700 text-white">
                  <LuSettings className="size-4" />
                </div>
                <h3 className="font-semibold">Configuración</h3>
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu className="gap-2">
            {sideBarConfig({
              permissions,
              is_supervisor,
              translations,
              SECTION,
            })?.map((nav, index) => (
              <SidebarMenu key={index}>
                <SidebarMenuButton asChild size="sm">
                  <Link
                    shallow
                    href={nav?.url || ""}
                    className={classNames("font-bold", {
                      "bg-slate-100": isSubMenuActive(nav?.items || nav?.url),
                    })}
                  >
                    {nav?.title}
                  </Link>
                </SidebarMenuButton>
                {nav?.items && nav?.items.length ? (
                  <SidebarMenuSub className="ml-0 border-l-0 px-1.5">
                    {nav?.items?.map((subNav) => (
                      <SidebarMenuSubItem key={subNav?.title}>
                        <SidebarMenuSubButton
                          asChild
                          isActive={isActive(subNav?.url || "")}
                        >
                          <Link shallow href={subNav?.url || ""}>
                            {subNav?.title}
                          </Link>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                ) : null}
              </SidebarMenu>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
