"use client";

import React from "react";
import { z } from "zod";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  Input,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
  RadioGroup,
  RadioGroupItem,
  Separator,
} from "@/components/atoms";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
  InstallmentsInputs,
} from "@/components/molecules";
import { setCard } from "@/actions/administration";
import { getTableParams } from "@/actions/configs";
import { useDialog, useKeypress, toast } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { Card, CardInstallment } from "@/types/general";
import { CARDS_CHECKBOX_LIST } from "@/libs/administration";
import { resolver, formSchema } from "./schema";

export function NewCardForm({ card }: { card?: Card }) {
  const { closeDialog } = useDialog();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      id_card: card?.id_card ?? 0,
      card: card?.card ?? "",
      enabled: card?.enabled ?? true,
      is_credit: card?.is_credit ? "credit" : "debit",
      card_installments: card?.card_installments ?? [],
      rowversion: card?.rowversion ?? null,
      tax_id_number: card?.tax_id_number ?? "",
    },
  });
  const { startLoading, stopLoading, setDataAdministration } = useBoundStore();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setCard(values);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const {
        cards,
      }: {
        cards: Card[];
      } = await getTableParams(["TARJETAS"]);

      setDataAdministration({
        data: cards,
        section: "cards",
      });

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "La tarjeta se guardó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  const {
    fields: installments,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "card_installments",
  });

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <DialogContent className="w-1/2">
      <DialogHeader>
        <DialogTitle>Tarjeta</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-cols-12 gap-x-4 gap-y-6"
        >
          <FormField
            control={form.control}
            name="card"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>Tarjeta</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tax_id_number"
            render={({ field }) => (
              <FormItem className="col-span-6">
                <FormLabel>N° de comercio</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_credit"
            render={({ field }) => (
              <FormItem className="col-span-12">
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="grid-cols-12"
                  >
                    {CARDS_CHECKBOX_LIST.map(
                      (item: {
                        key: string;
                        label: string;
                        description: string;
                      }) => (
                        <FormItem
                          key={item.key}
                          className="col-span-6 flex items-center space-x-3 space-y-0"
                        >
                          <FormControl>
                            <RadioGroupItem value={item.key} />
                          </FormControl>
                          <div>
                            <FormLabel>{item.label}</FormLabel>
                            <FormDescription>
                              {item.description}
                            </FormDescription>
                          </div>
                        </FormItem>
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
          <Separator className="col-span-12" />
          <DialogTitle className="col-span-12">Cuotas</DialogTitle>
          <InstallmentsInputs
            installments={installments as CardInstallment[]}
            form={form}
            remove={remove}
            append={append}
          />
        </form>
      </Form>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose className="col-span-2 col-start-9">
          <Button
            type="button"
            onClick={() => form.reset()}
            variant="destructive"
          >
            Cancelar
          </Button>
        </DialogClose>
        <Button className="col-span-2" onClick={form.handleSubmit(onSubmit)}>
          Guardar
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
