"use client";

import React from "react";
import { create } from "zustand";
import useInterval from "@use-it/interval";
import { useBoundStore } from "@/hooks";
import { getScheduleUpdate } from "@/actions/schedule";
import { ScheduleList } from "@/types/schedule";
import { INITIAL_PARAMS_SHEDULE } from "@/libs/schedule";

const useUpdateSchedule = ({
  params,
  doRefresh,
}: {
  params: typeof INITIAL_PARAMS_SHEDULE;
  doRefresh: boolean;
}) => {
  const { setDataSchedule } = useBoundStore();
  const [lastUpdate, setLastUpdate] = React.useState<number>(Date.now());

  useInterval(async () => {
    if (!doRefresh || params.option !== "buscar") return;

    const response: ScheduleList[] | { error: string } | null =
      await getScheduleUpdate({ params, lastUpdate });

    if (response) {
      setDataSchedule({
        data: response,
      });
    }
    setLastUpdate(Date.now());
  }, 10000);
};

interface RefreshState {
  refresh: boolean;
}

interface RefreshActions {
  useUpdateSchedule: (params: typeof INITIAL_PARAMS_SHEDULE) => void;
  startRefresh: () => void;
  stopRefresh: () => void;
}

export const useScheduleUpdate = create<RefreshState & RefreshActions>(
  (set, get) => ({
    refresh: true,
    useUpdateSchedule: (params: typeof INITIAL_PARAMS_SHEDULE) =>
      useUpdateSchedule({ params, doRefresh: get().refresh }),
    startRefresh: () =>
      set(() => ({
        refresh: true,
      })),
    stopRefresh: () => {
      set(() => ({
        refresh: false,
      }));
    },
  })
);
