"use client";

import React from "react";
import { useBoundStore } from "@/hooks";
import { PaymentsTable, PaymentsForm } from "@/components/organisms";
import { EmptyState } from "@/components/molecules";
import {
  Skeleton,
  Button,
  Dialog,
  Tabs,
  TabsContent,
} from "@/components/atoms";
import { updateSection } from "@/actions/patients";
import { Banks, Cards } from "@/types/payments";
import { toast } from "@/hooks";
import { Account } from "@/types/patients";
import { Delete } from "./delete";
import { Cancel } from "./cancel";

export const PaymentsView = ({
  cardList,
  bankList,
}: {
  cardList: Cards[];
  bankList: Banks[];
}) => {
  const {
    patient,
    general_params: { user_params, offices, professionals },
    patient_data_loading,
    searchBarRef,
    user,
    setPaymentSelected,
    patient_payments: { data, payment_selected },
    updatePatient,
    setPatientPayments,
  } = useBoundStore();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<string>("tables");
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

  const REFRESH = async () => {
    try {
      setLoading(true);
      const { account, patient_payments, budgets } = await updateSection(
        ["prest", "ctacte", "odontograma", "presup"],
        patient.id_patient
      );

      updatePatient("budgets", budgets);
      setPatientPayments({ data: account });
      updatePatient("account", account);
      updatePatient("patient_payments", patient_payments);
    } catch (err: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    patient.id_patient && REFRESH();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.id_patient]);

  const ACTIONS_TABLE = (payment: Account, action: string) => {
    const ACTIONS: { [key: string]: () => void } = {
      delete: () => setOpenDeleteModal(true),
      edit: () => setActiveTab("form"),
      cancel: () => setOpenCancelModal(true),
    };

    setPaymentSelected(payment);

    if (payment_selected?.id_all) {
      return toast({
        variant: "warning",
        title: "Ups! No puede realizar esta acción",
        description:
          "Por favor, utilizá el módulo de prestaciones para realizar esta acción.",
      });
    }

    const DATE = new Date(payment_selected?.date);
    const CLOSURE_DATE = new Date(user_params.cash_date);

    if (
      CLOSURE_DATE.getDate() !== null &&
      DATE <= CLOSURE_DATE &&
      !user.is_supervisor
    ) {
      toast({
        variant: "warning",
        title: "Ups! No puede realizar esta acción",
        description:
          "Esta acción no se puede realizar ya que se realizó el cierre de caja de este período.",
      });
    } else {
      const CURRENT = patient?.patient_payments?.find(
        (el: Account) => el.id_payment === payment.id_payment
      );
      if (!CURRENT)
        return toast({
          variant: "warning",
          title: "Ups! No puede realizar esta acción",
          description:
            "Este pago no se puede modificar. Es modificable solamente desde el software de escritorio.",
        });

      if (!["cancel"].includes(action) && CURRENT.canceled)
        return toast({
          variant: "warning",
          title: "Ups! No puede realizar esta acción",
          description:
            "Este pago no se puede modificar, el mismo se encuentra anulado.",
        });
      if (user_params.is_usd && !user.is_usd && CURRENT?.is_usd)
        return toast({
          variant: "warning",
          title: "Ups! No puede realizar esta acción",
          description:
            "El pago seleccionado se encuentra en USD y el usuario no puede operar doble moneda.",
        });

      setPaymentSelected(CURRENT);
      ACTIONS[action]();
    }
  };

  return (
    <Tabs
      defaultValue={activeTab}
      value={activeTab}
      className="!w-full hidden lg:flex lg:flex-col"
    >
      <TabsContent value="tables" className="!w-full !mt-0">
        {patient_data_loading ? (
          <div className="col-span-full grid grid-cols-12 gap-4 mx-auto">
            <Skeleton className="h-10 w-40 rounded-xl" />
            <Skeleton className="col-start-12 h-10 rounded-xl" />
            <Skeleton className="col-span-full h-80 rounded-xl" />
          </div>
        ) : patient.id_patient ? (
          <div className="grid grid-cols-12">
            {data?.length ? (
              <PaymentsTable action={ACTIONS_TABLE} loading={loading} />
            ) : (
              <div className="m-auto col-span-5 col-start-4 grid grid-cols-12 gap-x-10">
                <EmptyState
                  title="No hay pagos para mostrar"
                  copy="Puedes cargar un pago"
                  className="col-span-full !justify-start"
                />
                <Button
                  onClick={() => {
                    setPaymentSelected(null);
                    setActiveTab("form");
                  }}
                  className="col-span-4 col-start-6"
                >
                  Nueva pago
                </Button>
              </div>
            )}
            <Dialog open={openDeleteModal} onOpenChange={setOpenDeleteModal}>
              <Delete
                onChange={REFRESH}
                loading={loading}
                setLoading={setLoading}
              />
            </Dialog>
            <Dialog open={openCancelModal} onOpenChange={setOpenCancelModal}>
              <Cancel
                onChange={REFRESH}
                loading={loading}
                setLoading={setLoading}
              />
            </Dialog>
            {/* Actualizar con dialog de juan */}
            {data?.length > 0 && (
              <Button
                onClick={() => {
                  setPaymentSelected(null);
                  setActiveTab("form");
                }}
                className="col-span-4 col-start-11"
              >
                Nuevo pago
              </Button>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-x-10">
            <EmptyState
              title="No existe paciente seleccionado"
              copy="Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información."
              className="col-span-6 !justify-start flex col-start-4"
            />
            <Button
              className="col-span-3 col-start-6"
              onClick={() => searchBarRef?.current?.focus()}
            >
              Buscar Paciente
            </Button>
          </div>
        )}
      </TabsContent>
      <TabsContent value="form" className="!mt-0">
        <PaymentsForm
          onCancel={setActiveTab}
          professionals={professionals}
          offices={offices}
          onChange={REFRESH}
          bankList={bankList}
          cardList={cardList}
        />
      </TabsContent>
    </Tabs>
  );
};
