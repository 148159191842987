import React from "react";
import { z } from "zod";
import { useBoundStore } from "@/hooks";
import { formSchema } from "@/components/organisms/forms/patients/laboratory/schema";
import { Laboratory } from "@/types/general";
import { Loading } from "@/components/organisms/reports/loading";
import { columns } from "./columns";
import { Data } from "./data";

export const Table = ({
  data,
  action,
  loading,
}: {
  data: {
    laboratories: Laboratory[];
  };
  action: (
    row: z.infer<typeof formSchema>["order_from_list"],
    key: string
  ) => void;
  loading: boolean;
}) => {
  const {
    general_params: { user_params },
    user,
    permissions,
  } = useBoundStore();
  const USE_USD = {
    params: user_params?.use_usd,
    user: user?.use_usd,
  };

  return (
    <div className="w-full col-span-full">
      {loading ? (
        <Loading heigth="300px" />
      ) : (
        <Data
          columns={columns({
            action,
            config: { permissions, is_supervisor: user.is_supervisor },
          })}
          data={data.laboratories?.reverse()}
          config={USE_USD}
        />
      )}
    </div>
  );
};
