import React from "react";
import { saveTurn } from "@/actions/schedule";
import { toast, useDialog } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { FloattingSearchBar } from "@/components/molecules/floating-searchbar";
import { Patient } from "@/types/patients";
import { DialogContent } from "@/components/atoms";
import { MedicalInsurance } from "@/types/general";
import { scheduleMapper } from "@/utils/mappers";
import { EditObservation } from "./edit-observation";

export const NewAppointmentPatientExist: React.FC<{
  refresh: () => void;
  medicalInsurances: MedicalInsurance[];
  setChangeDataTable: (value: string) => void;
}> = ({ refresh, setChangeDataTable, medicalInsurances }) => {
  const {
    schedule: { current_appointment },
    user: { id_user, is_supervisor },
    startLoading,
    stopLoading,
    resetPatiensList,
    setCurrentAppointment,
    permissions,
  } = useBoundStore();
  const { openDialog } = useDialog();
  const SHOW_OBSERVATION =
    is_supervisor || permissions.hasOwnProperty("Obs. del Turno");
  const onSumbitHandler = async (patient_data: Patient) => {
    startLoading();
    setChangeDataTable(current_appointment?.time);

    const response = await saveTurn({
      appointment: {
        ...current_appointment,
        id_user,
        afiliate_number: null,
        observation: null,
        id_patient: patient_data?.id_patient,
        is_overturn: current_appointment?.is_overturn || false,
        medical_insurance:
          patient_data?.plan_navigation?.medical_insurance_navigation
            ?.medical_insurance || null,
        given_date: current_appointment?.given_date || new Date().toISOString(),
        attendance: null,
        patient_name: null,
        medical_insurance_plan: patient_data?.plan_navigation?.name || null,
        phone: null,
        rowversion: current_appointment?.rowversion,
      },
      attendance: current_appointment?.attendance,
      id_user,
      option: "agregarturnopacexist",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      await refresh();
      const APPOINTMENT = scheduleMapper(response);

      setCurrentAppointment({ current_appointment: APPOINTMENT[0] });
      resetPatiensList();
      toast({
        variant: "successful",
        title: "Turno guardado correctamente",
        description: "Ahora puedes visualizar el turno en la agenda.",
      });
      SHOW_OBSERVATION &&
        openDialog({
          content: (
            <DialogContent>
              <EditObservation
                setChangeDataTable={setChangeDataTable}
                refresh={refresh}
                medicalInsurances={medicalInsurances}
              />
            </DialogContent>
          ),
        });
      setChangeDataTable("");
      stopLoading();
    }
  };

  return <FloattingSearchBar onSubmit={onSumbitHandler} />;
};
