"use client";

import * as React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import classNames from "classnames";
import { HiOutlineBanknotes as Banknotes } from "react-icons/hi2";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/atoms";
import { SIDEBAR_ADMINISTRATION } from "@/libs/sidebars";
import { usePermissionsView } from "@/hooks";
import { useBoundStore } from "@/hooks";

export function AdministrationSidebar({
  ...props
}: React.ComponentProps<typeof Sidebar>) {
  const pathname = usePathname();
  const {
    permissions,
    user: { is_supervisor },
  } = useBoundStore();
  const { validateAccess } = usePermissionsView();
  const isActive = (url: string) => pathname.includes(url);
  const isSubMenuActive = (
    items:
      | {
          title: string;
          url: string;
          isActive: boolean;
        }[]
      | null
      | string
  ) => {
    return typeof items !== "string"
      ? items?.some((current) => pathname.includes(current.url))
      : isActive(items);
  };

  const handleNavigation = (
    e: React.MouseEvent<HTMLAnchorElement>,
    permission?: string[]
  ) => {
    const ACCESS = validateAccess({ permission });

    if (!ACCESS) {
      e.preventDefault();
    }
  };
  const handlePrefetch = ({ permission }: { permission?: string[] }) => {
    const HAS_PERMISSION =
      !permission ||
      permission.some((p: string) => permissions.hasOwnProperty(p));

    return HAS_PERMISSION || is_supervisor;
  };

  return (
    <Sidebar {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <div>
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-slate-700 text-white">
                  <Banknotes className="size-4" />
                </div>
                <h3 className="font-semibold">Administración</h3>
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu className="gap-2">
            {SIDEBAR_ADMINISTRATION.navMain.map((item) => (
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton asChild size="sm">
                  <Link
                    href={item.url}
                    prefetch={handlePrefetch({ permission: item?.permission })}
                    className={classNames(
                      "block w-full text-left !font-bold !text-black !justify-start p-2 rounded",
                      {
                        "bg-slate-100": isSubMenuActive(item.items || item.url),
                      }
                    )}
                    onClick={(e) => handleNavigation(e, item?.permission)}
                  >
                    {item.title}
                  </Link>
                </SidebarMenuButton>
                {item.items?.length ? (
                  <SidebarMenuSub className="ml-0 border-l-0 px-1.5">
                    {item.items.map((item) => (
                      <SidebarMenuSubItem key={item.title}>
                        <SidebarMenuSubButton
                          asChild
                          isActive={isActive(item.url)}
                        >
                          <Link
                            href={item.url}
                            className="hover:cursor-pointer"
                            prefetch={handlePrefetch({
                              permission: item?.permission,
                            })}
                            onClick={(e) =>
                              handleNavigation(e, item?.permission)
                            }
                          >
                            {item.title}
                          </Link>
                        </SidebarMenuSubButton>
                      </SidebarMenuSubItem>
                    ))}
                  </SidebarMenuSub>
                ) : null}
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
