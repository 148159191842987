import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog } from "@/hooks";
import { ButtonLoading, FreemiumForm } from "@/components/molecules";
import { Professional } from "@/types/general";
import { createOrUpdateProfessional } from "@/actions/professionals";
import { useBoundStore } from "@/hooks";

export function DeleteProfessional({
  professional,
}: {
  professional: Professional | undefined;
}) {
  const { loading, startLoading, stopLoading } = useBoundStore();
  const { openDialog, closeDialog } = useDialog();

  const onSubmit = async () => {
    startLoading();
    const response = await createOrUpdateProfessional({
      ...professional,
      option: "eliminar",
    });

    if (response && "error" in response) {
      response?.error.includes("40000")
        ? openDialog({
            content: <FreemiumForm />,
          })
        : toast({
            variant: "destructive",
            title: "Ups! Parece que hubo un error",
            description: response?.error,
          });
    } else {
      toast({
        variant: "successful",
        title: "Profesional eliminado con éxito",
        description: "El profesional se eliminó correctamente.",
      });
      closeDialog();
    }
    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar profesional</DialogTitle>
      </DialogHeader>
      <span>
        Estás por eliminar un/a profesional y esta acción es irreversible, el/la
        profesional se eliminará definitivamente.
      </span>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-patient">
            Eliminar
          </Button>
        )}
      </div>
    </DialogContent>
  );
}
