"use client";

import React from "react";
import { Holyday, Offices, Professional, Specialty } from "@/types/general";
import { useBoundStore } from "@/hooks";
import { Skeleton } from "@/components/atoms";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data?: Holyday[];
  config: {
    offices: Offices[];
    professional?: Professional;
    specialties: Specialty[];
  };
}> = ({ data, config }) => {
  const { loading } = useBoundStore();

  return loading ? (
    <div className="grid grid-cols-4 gap-4 mx-auto my-4">
      <Skeleton className="col-span-5 h-[calc(100vh-153px)] rounded-xl" />
    </div>
  ) : (
    <Data columns={columns({ config })} data={data} />
  );
};
