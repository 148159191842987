import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = {
  description: z.string(),
  date: z.date(),
  id_budget: z.number(),
  id_professional: z.number(),
  id_payment_method: z.number(),
  is_usd: z.boolean(),
  id_benefit: z.number().nullable(),
  is_manual_benefit: z.boolean(),
  id_med_ins: z.number().nullable(),
  total_quantity_benefit_list: z.number().optional(),
  total_amount_benefit_list: z.number().optional(),
  total_amount_with_discount: z.number().optional(),
  id_plan: z.number(),
  balance: z.number().optional(),
  sessions: z.string().optional(),
  estimated_time: z.string().optional(),
  observations: z.string().optional(),
  id_tax: z.number(),
  id_office: z.number().optional().nullable(),
  affiliate_number: z.string(),
  interest_rate: z.coerce.number().optional(),
  installment_value: z.number().optional(),
  id_installment: z.number().optional(),
  discount: z.object({
    amount: z.coerce.number().optional(),
    type_discount: z.string(),
    percentage: z.coerce.number().optional(),
  }),
  initial_payment: z.object({
    amount: z.coerce.number().optional(),
    percentage: z.coerce.number().optional(),
  }),
  list_benefits: z.array(
    z.object({
      age: z.string().optional().nullable(),
      amount_med_ins: z.number().optional(),
      amount_patient: z.coerce.number(),
      benefit_code: z.string().optional().nullable(),
      code_benefit_med_ins_liq: z.string().optional().nullable(),
      description: z.string().optional().nullable(),
      disabled: z.boolean().optional(),
      drawing: z.string().optional().nullable(),
      id_benefit: z.number().optional().nullable(),
      id_benefit_to_be_performed: z.number().optional().nullable(),
      id_budget: z.number().optional().nullable(),
      id_budget_benefit: z.number().optional(),
      id_group: z.number().optional().nullable(),
      id_plan: z.number().optional(),
      id_specialty: z.number().optional().nullable(),
      id_specialty_navigation: z.number().optional().nullable(),
      internal: z.boolean().optional(),
      is_manual: z.boolean().optional(),
      is_odontogram: z.boolean().optional(),
      is_usd: z.boolean().optional().nullable(),
      needs_thoot: z.boolean().optional(),
      per_patient: z.number().optional(),
      per_piece_sector: z.number().optional(),
      quantity: z.coerce.number(),
      requires_face: z.boolean().optional(),
      rowversion: z.string().optional().nullable(),
      thoot_face: z.string().optional().nullable(),
      tooth: z.string().optional().nullable(),
      tooth_section: z.string().optional().nullable(),
      total_amount: z.number().optional(),
      unit: z.string().optional().nullable(),
      services_performed: z.number().optional().nullable(),
    })
  ),
  selected_benefit: z.object({
    age: z.string().optional().nullable(),
    amount_med_ins: z.number().optional(),
    amount_patient: z.coerce.number(),
    benefit_code: z.string().optional().nullable(),
    code_benefit_med_ins_liq: z.string().optional().nullable(),
    description: z.string().optional().nullable(),
    disabled: z.boolean().optional(),
    drawing: z.string().optional().nullable(),
    id_benefit: z.number().optional().nullable(),
    id_benefit_to_be_performed: z.number().optional().nullable(),
    id_budget: z.number().optional().nullable(),
    id_budget_benefit: z.number().optional(),
    id_group: z.number().optional().nullable(),
    id_plan: z.number().optional(),
    id_specialty: z.number().optional().nullable(),
    id_specialty_navigation: z.number().optional().nullable(),
    internal: z.boolean().optional(),
    is_manual: z.boolean().optional(),
    is_odontogram: z.boolean().optional(),
    is_usd: z.boolean().optional().nullable(),
    needs_thoot: z.boolean().optional(),
    per_patient: z.number().optional(),
    per_piece_sector: z.number().optional(),
    quantity: z.coerce.number(),
    requires_face: z.boolean().optional(),
    rowversion: z.string().optional().nullable(),
    thoot_face: z.string().optional().nullable(),
    tooth: z.string().optional().nullable(),
    tooth_section: z.string().optional().nullable(),
    total_amount: z.number().optional(),
    unit: z.string().optional().nullable(),
    services_performed: z.number().optional().nullable(),
  }),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
