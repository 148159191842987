"use client";
import React from "react";
import { z } from "zod";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TimeDatePicker,
} from "@/components/molecules";
import {
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Input,
  Combobox,
  Checkbox,
  Separator,
  Button,
} from "@/components/atoms";
import { Offices, Professional, Specialty } from "@/types/general";
import { updateTimetable } from "@/actions/professionals";
import { toast, useDialog } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { Time } from "@/types/timetable";
import { DAYS } from "@/libs/config";
import { formSchema, resolver } from "./schema";

export const TimesTableForm = ({
  time,
  professional,
  specialties,
  offices,
}: {
  time?: Time;
  professional?: Professional;
  specialties: Specialty[];
  offices: Offices[];
}) => {
  const { closeDialog } = useDialog();
  const { startLoading, stopLoading, loading } = useBoundStore();

  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver,
    defaultValues: {
      id_day: time?.day || 1,
      id_office: time?.id_office || -1,
      time_from: time?.from || "",
      time_to: time?.to || "",
      interval: time?.interval || 30,
      date_from: time?.date_from ? new Date(time.date_from) : null,
      date_to: time?.date_to ? new Date(time.date_to) : null,
      id_specialty: time?.id_specialty || null,
      all_specialties: time?.id_specialty ? false : true,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const date_from =
      values.date_from && format(values.date_from, "yyyy-MM-dd");
    const date_to = values.date_to && format(values.date_to, "yyyy-MM-dd");
    const response = await updateTimetable({
      ...values,
      id_professional: professional?.id_professional,
      name: professional?.name,
      date_from,
      date_to,
      has_schedule: false,
      id_office: values.id_office === -1 ? null : values.id_office,
      rowversion: time?.rowversion,
      id_time: time?.id_time,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: time
          ? "Horario actualizado correctamente"
          : "Horario creado correctamente",
        description: "Los datos se verán reflejados en las tablas.",
      });
      closeDialog();
    }
    stopLoading();
  };

  return (
    <SheetContent className="w-full !max-w-[500px] !text-start flex flex-col justify-between">
      <SheetHeader className="!text-start">
        <SheetTitle className="p-2 !font-semibold !text-2xl">
          {time ? "Modificar horario" : "Nuevo horario"}
        </SheetTitle>
        <SheetDescription className="flex flex-col p-2">
          <span className="text-sm text-zinc-400">
            Profesional seleccionado
          </span>
          <span className="text-lg text-blue-700 uppercase">
            {professional?.name}
          </span>
        </SheetDescription>
      </SheetHeader>
      <div className="overflow-y-scroll h-[calc(100vh-73px)]">
        <div>
          <h4 className="font-semibold text-lg p-2">Día y parámetros</h4>
          <Form {...form}>
            <form className="w-full grid grid-cols-12 gap-4 overflow-hidden bg-white p-2">
              <Combobox
                data={DAYS}
                dataKey="id_day"
                form={form}
                value="value"
                placeholder="Selecciona un item..."
                title="Selecciona un item..."
                showInputSearch={false}
                className="col-span-12 !max-w-full"
                classContent="!w-96"
                label="Elegir día"
              />
              {offices?.length > 0 && (
                <Combobox
                  data={offices}
                  dataKey="id_office"
                  form={form}
                  value="description"
                  placeholder="Selecciona un item..."
                  title="Selecciona un item..."
                  showInputSearch={false}
                  className="col-span-12 !max-w-full"
                  classContent="!w-80"
                  label="Sucursales"
                />
              )}
              <Combobox
                data={specialties}
                dataKey="id_specialty"
                form={form}
                value="description"
                placeholder="Selecciona un item..."
                title="Selecciona un item..."
                showInputSearch={false}
                className="col-span-12 !max-w-full"
                classContent="!w-80"
                label="Especialidades"
                disabled={form.watch("all_specialties")}
              />
              <FormField
                control={form.control}
                name="all_specialties"
                render={({ field }) => (
                  <FormItem className="col-span-12 flex flex-row-reverse gap-2 items-center justify-end">
                    <FormLabel>Todas las especialidades</FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        ref={field.ref}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Separator className="col-span-12" />
              <div className="col-span-12">
                <h4 className="text-xl font-semibold">Seleccionar período</h4>
                <p className="text-sm text-zinc-400">
                  Puedes establecer un límite de tiempo para que el horario sea
                  válido.
                </p>
              </div>
              <FormField
                control={form.control}
                name="time_from"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormLabel>Hora de inicio*</FormLabel>
                    <FormControl>
                      <Input {...field} type="time" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="time_to"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormLabel>Hora Final*</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="time"
                        min={form.watch("time_from")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="interval"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormLabel>Intervalos*</FormLabel>
                    <FormControl>
                      <Input {...field} type="number" placeholder="30" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="date_from"
                render={() => (
                  <FormItem className="col-span-12">
                    <FormControl>
                      <TimeDatePicker
                        form={form}
                        label="Desde"
                        dateFieldName="date_from"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="date_to"
                render={() => (
                  <FormItem className="col-span-12">
                    <FormControl>
                      <TimeDatePicker
                        form={form}
                        label="Hasta"
                        dateFieldName="date_to"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
      </div>
      <div className="flex justify-end gap-5">
        <SheetClose>
          <Button variant="destructive"> Cancelar</Button>
        </SheetClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button className="bg-blue-600" onClick={form.handleSubmit(onSubmit)}>
            Guardar
          </Button>
        )}
      </div>
    </SheetContent>
  );
};
