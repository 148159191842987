"use client";

import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { LuChevronLeft } from "react-icons/lu";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { toast, useKeypress } from "@/hooks";
import {
  Card,
  CardTitle,
  Separator,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  ScrollArea,
  Checkbox,
  Button,
  buttonVariants,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormItem,
  FormLabel,
  FormField,
  FormMessage,
} from "@/components/molecules";
import { CopyMedicalInsurancesBenefits as Table } from "@/components/organisms/tables";
import { useBoundStore } from "@/hooks";
import { MedicalInsurance, Plan, Benefit } from "@/types/general";
import { copyBenefits, getMedicalInsuranceBenefits } from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export function CopyMedicalInsurancesDataForm({
  medicalInsurances,
}: {
  medicalInsurances: MedicalInsurance[];
}) {
  const {
    loading,
    startLoading,
    stopLoading,
    general_params: { translations },
  } = useBoundStore();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_med_ins_source: medicalInsurances[0]?.id_med_ins.toString(),
      id_med_ins_destination: medicalInsurances[0]?.id_med_ins.toString(),
      id_plan_source: medicalInsurances[0]?.plans[0]?.id_plan.toString(),
      id_plan_destination: medicalInsurances[0]?.plans[0]?.id_plan.toString(),
      benefits_to_copy: [],
      replace_all: false,
    },
  });
  const medicalInsuranceName = translations?.medical_insurance;
  const { id_med_ins_source, id_med_ins_destination } = form.watch();
  const plansSource = React.useMemo(
    () =>
      medicalInsurances.find(
        (m) => m.id_med_ins === parseInt(form.getValues("id_med_ins_source"))
      )?.plans || [],
    [id_med_ins_source]
  );
  const plansDestination = React.useMemo(
    () =>
      medicalInsurances.find(
        (m) =>
          m.id_med_ins === parseInt(form.getValues("id_med_ins_destination"))
      )?.plans || [],
    [id_med_ins_destination]
  );
  const [benefits, setBenefits] = React.useState<{
    source: Benefit[];
    destination: Benefit[];
  }>({
    source: [],
    destination: [],
  });

  React.useEffect(() => {
    const newPlanIdSource = medicalInsurances
      .find(
        (m) => m.id_med_ins === parseInt(form.getValues("id_med_ins_source"))
      )
      ?.plans[0].id_plan.toString() as string;
    const newPlanIdDestination = medicalInsurances
      .find(
        (m) =>
          m.id_med_ins === parseInt(form.getValues("id_med_ins_destination"))
      )
      ?.plans[0].id_plan.toString() as string;

    form.setValue("id_plan_source", newPlanIdSource);
    form.setValue("id_plan_destination", newPlanIdDestination);
  }, [id_med_ins_source, id_med_ins_destination]);

  React.useEffect(() => {
    const fetchMedicalBenefits = async () => {
      startLoading();
      const [idSource, idDestination] = form.getValues([
        "id_plan_source",
        "id_plan_destination",
      ]);
      const [resSource, resDestination] = await Promise.all([
        getMedicalInsuranceBenefits("OSPRE", parseInt(idSource)),
        getMedicalInsuranceBenefits("OSPRE", parseInt(idDestination)),
      ]);

      if (resSource?.error || resDestination?.error) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: resSource?.error || resDestination?.error,
        });
      } else {
        setBenefits({
          source: (resSource.benefits as Benefit[]) || [],
          destination: (resDestination.benefits as Benefit[]) || [],
        });
      }

      stopLoading();
    };

    void fetchMedicalBenefits();
  }, [form.watch("id_plan_source"), form.watch("id_plan_destination")]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const params = {
      id_plan_source: values.id_plan_source,
      id_plan_destination: values.id_plan_destination,
      replace_all: values.replace_all,
      benefits_to_copy: values.benefits_to_copy,
    };
    const response = await copyBenefits(params);
    const [resSource, resDestination] = await Promise.all([
      getMedicalInsuranceBenefits("OSPRE", parseInt(values.id_plan_source)),
      getMedicalInsuranceBenefits(
        "OSPRE",
        parseInt(values.id_plan_destination)
      ),
    ]);

    if (response?.error || resSource?.error || resDestination?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description:
          response?.error || resSource?.error || resDestination?.error,
      });
    } else {
      setBenefits({
        source: (resSource.benefits as Benefit[]) || [],
        destination: (resDestination.benefits as Benefit[]) || [],
      });

      toast({
        variant: "successful",
        title: "Éxito!",
        description: `La copia de prestaciones se realizó correctamente.`,
      });
    }
    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <div className="m-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card className="grid grid-cols-12 gap-3 mx-auto max-h-[calc(100vh-100px)]">
            <div className="col-span-full flex flex-row w-full gap-2 items-center">
              <Link
                href="/dashboard/configuration/tables/medical-insurances"
                className={classNames(
                  buttonVariants({ variant: "outline", size: "icon" })
                )}
              >
                <LuChevronLeft className="size-4" />
                <span className="sr-only">Back</span>
              </Link>
              <CardTitle className="!mt-0">Copia de prestaciones</CardTitle>
            </div>
            <div className="col-span-full flex flex-row justify-between gap-5">
              <div className="flex flex-col w-full gap-2">
                <div className="grid grid-rows-2">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="text-center border-2 border-slate-600 rounded-full col-span-2 size-7 flex items-center justify-center text-slate-600">
                      <span className="font-extrabold">1</span>
                    </div>
                    <p className="font-bold">Datos de obra social de origen</p>
                  </div>
                  <span className="font-normal text-slate-500">
                    Selecciona la obra social de origen.
                  </span>
                </div>
                <div className="flex flex-col gap-x-4">
                  <div className="flex gap-x-4">
                    <FormField
                      control={form.control}
                      name="id_med_ins_source"
                      render={({ field }) => (
                        <FormItem className="w-1/2">
                          {!!medicalInsuranceName && (
                            <FormLabel>
                              Seleccionar {medicalInsuranceName.toLowerCase()}{" "}
                              de destino
                            </FormLabel>
                          )}
                          <FormControl>
                            <Select
                              key="id_med_ins_source"
                              value={field.value}
                              onValueChange={(value: string) => {
                                form.setValue("id_med_ins_source", value);
                              }}
                            >
                              <SelectTrigger className="w-full !h-10 !text-sm">
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent>
                                <ScrollArea className="h-[300px]">
                                  {medicalInsurances.map(
                                    (medIns: MedicalInsurance) => (
                                      <SelectItem
                                        key={medIns.id_med_ins}
                                        value={"" + medIns.id_med_ins}
                                      >
                                        {medIns.medical_insurance}
                                      </SelectItem>
                                    )
                                  )}
                                </ScrollArea>
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="id_plan_source"
                      render={({ field }) => (
                        <FormItem className="w-1/2">
                          <FormLabel>Plan de origen</FormLabel>
                          <FormControl>
                            <Select
                              key="id_plan_source"
                              value={field.value}
                              onValueChange={(value: string) => {
                                form.setValue("id_plan_source", value);
                              }}
                            >
                              <SelectTrigger className="w-full !h-10 !text-sm">
                                <SelectValue />
                              </SelectTrigger>
                              <SelectContent>
                                {plansSource.map((plan: Plan) => (
                                  <SelectItem
                                    key={plan.id_plan}
                                    value={"" + plan.id_plan}
                                  >
                                    {plan.name}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name="replace_all"
                    render={({ field }) => (
                      <FormItem className="mt-4 flex flex-row items-center space-x-3 justify-start">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="leading-none flex flex-col">
                          <FormLabel className="!mb-0">
                            Reemplazar datos en destino
                          </FormLabel>
                          <p className="text-sm font-regular text-gray-400">
                            Reemplazar datos en el caso que el código de la
                            prestación ya exista en destino.
                          </p>
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <Separator orientation="vertical" />
              <div className="flex flex-col w-full gap-2">
                <div className="grid grid-rows-2">
                  <div className="flex flex-row gap-3 items-center">
                    <div className="text-center border-2 border-slate-600 rounded-full col-span-2 size-7 flex items-center justify-center text-slate-600">
                      <span className="font-extrabold">2</span>
                    </div>
                    <p className="font-bold">Datos de obra social de destino</p>
                  </div>
                  <span className="font-normal text-slate-500">
                    Selecciona la obra social a donde se copiran las
                    prestaciones.
                  </span>
                </div>
                <div className="flex gap-x-4">
                  <FormField
                    control={form.control}
                    name="id_med_ins_destination"
                    render={({ field }) => (
                      <FormItem className="w-1/2">
                        <FormLabel>
                          Seleccionar {medicalInsuranceName?.toLowerCase()} de
                          destino
                        </FormLabel>
                        <FormControl>
                          <Select
                            key="id_med_ins_destination"
                            value={field.value}
                            onValueChange={(value: string) => {
                              form.setValue("id_med_ins_destination", value);
                            }}
                          >
                            <SelectTrigger className="w-full !h-10 !text-sm">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <ScrollArea className="h-[300px]">
                                {medicalInsurances.map(
                                  (medIns: MedicalInsurance) => (
                                    <SelectItem
                                      key={medIns.id_med_ins}
                                      value={"" + medIns.id_med_ins}
                                    >
                                      {medIns.medical_insurance}
                                    </SelectItem>
                                  )
                                )}
                              </ScrollArea>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="id_plan_destination"
                    render={({ field }) => (
                      <FormItem className="w-1/2">
                        <FormLabel>Plan de destino</FormLabel>
                        <FormControl>
                          <Select
                            key="id_plan_destination"
                            value={field.value}
                            onValueChange={(value: string) => {
                              form.setValue("id_plan_destination", value);
                            }}
                          >
                            <SelectTrigger className="w-full !h-10 !text-sm">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              {plansDestination.map((plan: Plan) => (
                                <SelectItem
                                  key={plan.id_plan}
                                  value={"" + plan.id_plan}
                                >
                                  {plan.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-full">
              <Table
                form={form}
                source={benefits.source}
                destination={benefits.destination}
              />
            </div>
            <div className="col-span-full flex justify-end">
              {loading ? (
                <ButtonLoading />
              ) : (
                <Button type="submit">Copiar</Button>
              )}
            </div>
          </Card>
        </form>
      </Form>
    </div>
  );
}
