import React from "react";
import classNames from "classnames";
import {
  Badge,
  CardContent,
  CardTitle,
  Input,
  RadioGroup,
  RadioGroupItem,
} from "@/components/atoms";
import {
  CheckboxForm,
  DatePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { useBoundStore } from "@/hooks";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export const StateOrder = ({
  data,
  form,
}: {
  data: { [key: string]: { date_received: Date; paid: string } };
  form: Form<typeof formSchema>;
}) => {
  const {
    permissions,
    user: { is_supervisor },
  } = useBoundStore();
  const { modify_percentage_prof } = form.watch();
  const { date_received, paid } = data["state_order"];
  const { id_order } = form.getValues();
  const PERMISSION = !!(
    id_order &&
    !permissions.hasOwnProperty("Modificar Pedido") &&
    !is_supervisor
  );

  const MOVEMENTS_TYPE =
    date_received && paid === "si"
      ? "Pagado"
      : date_received
      ? "Recibido"
      : "Emitido";
  const STYLES_MOVEMENTS = {
    Emitido: "bg-slate-100 !text-slate-500",
    Pagado: "bg-green-100 !text-green-500",
    Recibido: "bg-yellow-100 !text-yellow-500",
  };
  const VALIDATE_IMPUTS = !date_received && paid !== "si";
  React.useEffect(() => {
    if (!date_received) {
      form.setValue("date_payment", null);
      form.setValue("amount_laboratory", 0);
      form.setValue("date_delivered", null);
      form.setValue("invoice_number", "");
      form.setValue("paid", "no");
      form.setValue("modify_percentage_prof", false);
    }
  }, [date_received]);

  return (
    <div className="print:hidden grid grid-cols-12 rounded-md bg-white mt-5">
      <div className="col-span-12 border-b border-b-slate-300 p-5 bg-white truncate rounded-t-md flex justify-between">
        <div className="col-span-10 flex flex-col">
          <CardTitle className="text-xl mb-2">Estado del pedido</CardTitle>
          <span className="text-sm text-slate-400">
            Modifique el estado de sus pedidos. Algunos campos se le habilitaran
            al completar las fechas.
          </span>
        </div>
        <Badge
          className={classNames(
            "items-center justify-center h-8 w-20 mr-14 mt-4",
            STYLES_MOVEMENTS[MOVEMENTS_TYPE]
          )}
        >
          {MOVEMENTS_TYPE}
        </Badge>
      </div>
      <CardContent className="col-span-12 grid grid-cols-12 gap-5 mt-5">
        <div className="col-span-3 self-end">
          <DatePicker
            form={form}
            name="date_received"
            label="Recibido"
            dateformat="dd/MM/yyyy"
            disabled={PERMISSION}
          />
        </div>
        <FormField
          control={form.control}
          name="invoice_number"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>Rto. / Fact. Nro</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  {...field}
                  disabled={VALIDATE_IMPUTS || PERMISSION}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="amount_laboratory"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>Importe fact. laboratorio</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  disabled={VALIDATE_IMPUTS || PERMISSION}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="col-span-3 self-end">
          <DatePicker
            form={form}
            name="date_delivered"
            label="Fecha entrega al paciente"
            dateformat="dd/MM/yyyy"
            disabled={VALIDATE_IMPUTS || PERMISSION}
          />
        </div>
        <div className="col-span-3 mt-2">
          <label htmlFor="input" className="block text-sm font-medium">
            Pagado
          </label>
          <div className="flex gap-24">
            <FormField
              control={form.control}
              name="paid"
              render={({ field }) => (
                <FormItem className="space-y-3 w-full">
                  <FormControl className="w-full">
                    <RadioGroup
                      onValueChange={(value) => {
                        field.onChange(value);
                        if (value === "no") {
                          form.setValue("date_payment", null);
                        }
                      }}
                      defaultValue={field.value}
                      className="grid grid-cols-2 mt-2"
                    >
                      <div className="flex space-x-2">
                        <RadioGroupItem
                          value="si"
                          disabled={VALIDATE_IMPUTS || PERMISSION}
                        />
                        <FormLabel>Si</FormLabel>
                      </div>
                      <div className="flex space-x-2">
                        <RadioGroupItem
                          value="no"
                          disabled={VALIDATE_IMPUTS || PERMISSION}
                        />
                        <FormLabel>No</FormLabel>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="col-span-3 self-end">
          <DatePicker
            form={form}
            label="Fecha pago laboratorio"
            name="date_payment"
            disabled={VALIDATE_IMPUTS || paid !== "si"}
            dateformat="dd/MM/yyyy"
          />
        </div>
        <CheckboxForm
          classname="col-span-3 mt-7"
          label="Modificar el % a descontar al prof."
          onCheckedChange={(check: boolean) => {
            form.setValue("modify_percentage_prof", check);
            if (!check) {
              form.setValue("percentage_discount", 0);
            }
          }}
          checked={modify_percentage_prof}
          disabled={PERMISSION}
        />
        <FormField
          control={form.control}
          name="percentage_discount"
          render={({ field }) => (
            <FormItem className="col-span-3">
              <FormLabel>% a descontar al profesional</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  disabled={!modify_percentage_prof}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </CardContent>
    </div>
  );
};
