import React from "react";
import classNames from "classnames";
import { FacetedFilter, Visibility, SearchFilter } from "./filters";

export function TableToolbar<TData>({
  table,
  options,
  config,
}: any & { config: any }) {
  return (
    <div
      className={classNames(
        "flex items-center mb-2",
        config.params ? "justify-between" : "justify-end"
      )}
    >
      <div className="flex gap-2 w-full">
        <SearchFilter table={table} />
        {config.params && (
          <FacetedFilter
            title="Filtrar por moneda"
            name="is_usd"
            table={table}
            options={options}
          />
        )}
      </div>

      <Visibility table={table} />
    </div>
  );
}
