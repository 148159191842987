"use client";

import React from "react";
import { useBoundStore } from "@/hooks";
import { Default as GeistTable } from "@/components/molecules/geist";
import { EmptyState } from "@/components/molecules";
import { Offices } from "@/types/general";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data: any;
  section: "patient_payments" | "incomes" | "expenses";
  offices: Offices[];
}> = ({ data, section, offices }) => {
  const {
    loading,
    administration: { dailycashflow },
    setInitAdministration,
  } = useBoundStore();
  // This init data is the same data came on the first render
  // but is inside a useMemo to avoid re-renders.
  const INIT_DATA = React.useMemo(() => data, [data]);

  // INIT_DATA is used here to avoid the eslint-disable-next-line rule.
  React.useEffect(
    () =>
      setInitAdministration({
        data: {
          expenses: INIT_DATA.expenses,
          incomes: INIT_DATA.incomes,
          patient_payments: INIT_DATA.patient_payments,
          balances: INIT_DATA.balances,
          totals: INIT_DATA.totals,
        },
        section: "dailycashflow",
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [INIT_DATA]
  );

  const DATA_STATE = React.useMemo(
    () => dailycashflow.data[section],
    [dailycashflow.data, section]
  );
  const TOTALS = React.useMemo(() => DATA_STATE?.totals, [dailycashflow.data]);

  return loading ? (
    <GeistTable />
  ) : DATA_STATE?.data?.length > 0 ? (
    <Data
      section={section}
      columns={columns({
        column: section,
        config: {
          offices,
        },
      })}
      data={{
        data: DATA_STATE?.data,
        totals: {
          total_amount: TOTALS.total_amount,
          owed: TOTALS.owed,
          paid: TOTALS.paid,
        },
      }}
    />
  ) : (
    <EmptyState
      title="Ups!"
      className="mx-auto h-96"
      copy="No se han encontrado registros."
    />
  );
};
