"use client";

import React from "react";
import { getPermissionsView } from "@/utils/permissions";
import { useBoundStore } from "@/hooks";
import { toast } from "./use-toast";

export const usePermissionsView = (
  section?:
    | "schedule"
    | "reminders"
    | "administration"
    | "patients"
    | "statistics"
    | "configuration"
    | "reports"
    | "my-account"
) => {
  const {
    user: { is_owner, plan_version, is_supervisor },
    permissions,
  } = useBoundStore();

  const VIEWS = React.useMemo(() => {
    const PERMISSIONS_VIEWS = getPermissionsView(permissions, {
      is_owner: is_owner,
      plan_version: plan_version,
    });
    return PERMISSIONS_VIEWS;
  }, [permissions, section]);

  const validateAccess = ({ permission }: { permission?: string[] }) => {
    const HAS_PERMISSION =
      !permission ||
      permission.some((p: string) => permissions.hasOwnProperty(p));

    if (!HAS_PERMISSION && !is_supervisor) {
      toast({
        variant: "destructive",
        title: "¡Ups! Acceso denegado",
        description: "El usuario no tiene permiso para acceder a esta vista.",
      });
      return false;
    }
    return true;
  };

  if (section) {
    return { SECTION: VIEWS[section], validateAccess };
  }
  return { VIEWS, validateAccess };
};
