"use client";

import React from "react";
import {
  Button,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from "@/components/atoms";
import { ButtonLoading } from "@/components/molecules";
import { deleteExpense, getTransactions } from "@/actions/administration";
import { useBoundStore } from "@/hooks";
import { useDialog, toast } from "@/hooks";
import { DailyCashflow } from "@/types/administration";

export function DeleteExpenseForm({ idExpense }: { idExpense: number }) {
  const { closeDialog } = useDialog();
  const {
    startLoading,
    stopLoading,
    setDataAdministration,
    administration: { transactions },
    loading,
  } = useBoundStore();

  const onSubmit = async () => {
    startLoading();
    const response = await deleteExpense(idExpense);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const data = await getTransactions(transactions.params);

      setDataAdministration({
        data,
        params: transactions.params,
        section: "transactions",
      });
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El egreso se eliminó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar egreso</DialogTitle>
        <p className="text-slate-500 text-base">
          Está por eliminar un egreso. Esta acción es irreversible, ¿desea
          continuar?
        </p>
      </DialogHeader>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose asChild className="col-span-2 col-start-9">
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading className="col-span-2" />
        ) : (
          <Button className="col-span-2" onClick={onSubmit}>
            Continuar
          </Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
