"use client";

import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Form,
  FormMessage,
} from "@/components/molecules";
import { Button, DialogClose, Input } from "@/components/atoms";
import { toast, useScheduleUpdate } from "@/hooks";
import { saveTurn } from "@/actions/schedule";
import { useBoundStore } from "@/hooks";
import { ControllerMedicalInsurance } from "./controller-med-ins";
import { MedicalInsurance, Plan } from "@/types/general";
import { formSchema, resolver } from "./schema";

export function FirstTimeForm({
  refresh,
  medicalInsurances,
  setChangeDataTable,
}: {
  refresh: () => void;
  medicalInsurances: MedicalInsurance[];
  setChangeDataTable: (value: string) => void;
}) {
  const {
    schedule: { current_appointment },
    startLoading,
    stopLoading,
    user: { id_user: id_user, is_supervisor },
    general_params: { translations },
    permissions,
  } = useBoundStore();
  const { startRefresh } = useScheduleUpdate();
  const MEDICAL_INSURANCE = medicalInsurances?.find(
    (m: MedicalInsurance) =>
      m.medical_insurance === current_appointment?.medical_insurance?.name
  );
  const PLAN = MEDICAL_INSURANCE?.plans?.find(
    (m: Plan) => m.name === current_appointment?.medical_insurance?.plan
  );
  const IS_MANUAL =
    current_appointment?.appointment_type?.status === "free" ||
    medicalInsurances?.find(
      (m: MedicalInsurance) =>
        m.medical_insurance === current_appointment?.medical_insurance?.name
    );
  const SHOW_OBSERVATION =
    is_supervisor || permissions.hasOwnProperty("Obs. del Turno");
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      name: current_appointment?.patient_name || "",
      attendance: current_appointment?.attendance || null,
      affiliate_number:
        current_appointment?.medical_insurance?.affiliate_number || "",
      phone: current_appointment?.patient_phone || "",
      observation: current_appointment?.observation || "",
      medical_insurance: current_appointment?.medical_insurance?.name || "",
      medical_insurance_plan:
        current_appointment?.medical_insurance?.plan || "",
      id_professional: current_appointment?.id_professional,
      id_med_ins:
        MEDICAL_INSURANCE?.id_med_ins ||
        (IS_MANUAL ? medicalInsurances?.[0]?.id_med_ins : null),
      is_manual: !IS_MANUAL,
      id_plan:
        PLAN?.id_plan ||
        (IS_MANUAL ? medicalInsurances?.[0]?.plans?.[0]?.id_plan : null),
    },
  });

  async function onSubmit({ values }: { values: z.infer<typeof formSchema> }) {
    startLoading();
    setChangeDataTable(current_appointment?.time);
    const MEDICAL_INSURANCE = medicalInsurances?.find(
      (insurance: MedicalInsurance) =>
        insurance.id_med_ins === values.id_med_ins
    );
    const PLAN = MEDICAL_INSURANCE?.plans?.find(
      (m: any) => m.id_plan === values.id_plan
    );
    const response = await saveTurn({
      appointment: {
        ...current_appointment,
        medical_insurance: {
          ...current_appointment.medical_insurance,
          name:
            MEDICAL_INSURANCE?.medical_insurance ||
            values.medical_insurance ||
            current_appointment.medical_insurance.name,
          plan:
            PLAN?.name ||
            values.medical_insurance_plan ||
            current_appointment.medical_insurance.plan,
          affiliate_number: values.affiliate_number,
        },
        patient_name: values.name,
        patient_phone: values.phone,
        observation: values.observation,
      },
      attendance: current_appointment.attendance,
      id_user,
      option: "agregarTurnoPac1Vez",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      await refresh();
      toast({
        variant: "successful",
        title: "Turno guardado correctamente",
        description: "Ahora puedes visualizar el turno en la agenda.",
      });
    }

    setChangeDataTable("");
    stopLoading();
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit((values: any) => onSubmit({ values }))}>
        <div className="grid grid-cols-2 gap-5 mt-4 w-[600px]">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="col-span-2 ">
                <FormLabel>Apellido y nombre</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <ControllerMedicalInsurance
            medicalInsurances={medicalInsurances}
            form={form}
            translations={translations}
          />
          <FormField
            control={form.control}
            name="affiliate_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Nº de afiliado</FormLabel>
                <FormControl>
                  <Input type="number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Teléfono</FormLabel>
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {SHOW_OBSERVATION && (
            <FormField
              control={form.control}
              name="observation"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Observaciones</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className="grid grid-cols-4 mt-5 gap-x-5">
          <DialogClose>
            <Button
              type="button"
              className="w-full"
              onClick={() => {
                form.reset();
                startRefresh();
              }}
              variant="destructive"
            >
              Cancelar
            </Button>
          </DialogClose>
          <Button type="submit" className="w-full">
            Guardar
          </Button>
        </div>
      </form>
    </Form>
  );
}
