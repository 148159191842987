"use client";

import React from "react";
import { useBoundStore } from "@/hooks";
import { BenefitsPatientForm } from "@/components/organisms";
import { PatientBenefitsTable } from "@/components/organisms/tables";
import { EmptyState } from "@/components/molecules";
import { Button, Tabs, TabsContent, Dialog } from "@/components/atoms";
import { Benefit } from "@/types/general";
import { updateSection } from "@/actions/patients";
import { toast } from "@/hooks";
import { Delete } from "./delete";

export const BenefitsView = ({ benefits }: { benefits: Benefit[] }) => {
  const {
    patient,
    searchBarRef,
    setBenefitSelected,
    updatePatient,
    general_params: { offices },
  } = useBoundStore();
  const [activeTab, setActiveTab] = React.useState<string>("tables");
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const updateBenefits = async () => {
    const response = await updateSection(["prest"], patient.id_patient);

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      updatePatient("alls", response.benefits);
    }
  };
  const ACTIONS_TABLE = {
    delete: (benefit: Benefit) => {
      setOpenDeleteModal(true);
      setBenefitSelected(benefit);
    },
    edit: (benefit: Benefit) => {
      setBenefitSelected(benefit);
      setActiveTab("form");
    },
  };

  return (
    <Tabs
      defaultValue={activeTab}
      value={activeTab}
      className="!w-full hidden lg:flex lg:flex-col"
    >
      <TabsContent value="tables" className="!w-full !mt-0">
        <>
          {patient.id_patient ? (
            <div className="grid grid-cols-12">
              {patient.alls?.length ? (
                <PatientBenefitsTable action={ACTIONS_TABLE} />
              ) : (
                <div className="m-auto col-span-5 col-start-4 grid grid-cols-12 gap-x-10">
                  <EmptyState
                    title="No hay prestaciones para mostrar"
                    copy="Puedes cargar una prestacion"
                    className="col-span-full !justify-start"
                  />
                  <Button
                    onClick={() => {
                      setBenefitSelected(null);
                      setActiveTab("form");
                    }}
                    className="col-span-4 col-start-6"
                  >
                    Nueva prestación
                  </Button>
                </div>
              )}
              <Dialog open={openDeleteModal} onOpenChange={setOpenDeleteModal}>
                <Delete onChangeAction={updateBenefits} />
              </Dialog>
              {patient.alls?.length > 0 && (
                <Button
                  onClick={() => {
                    setBenefitSelected(null);
                    setActiveTab("form");
                  }}
                  className="col-span-4 col-start-11"
                >
                  Nueva prestación
                </Button>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-12 gap-x-10">
              <EmptyState
                title="No existe paciente seleccionado"
                copy="Todavía no has seleccionado un paciente para mostrar, busca un paciente para ver toda su información."
                className="col-span-6 !justify-start flex col-start-4"
              />
              <Button
                className="col-span-3 col-start-6"
                onClick={() => searchBarRef?.current?.focus()}
              >
                Buscar Paciente
              </Button>
            </div>
          )}
        </>
      </TabsContent>
      <TabsContent value="form" className="!mt-0">
        <BenefitsPatientForm
          onCancel={setActiveTab}
          offices={offices}
          benefits={benefits}
          onChange={updateBenefits}
        />
      </TabsContent>
    </Tabs>
  );
};
