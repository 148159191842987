"use client";

import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { useBoundStore } from "@/hooks";
import { toast } from "@/hooks";
import { deletePayment } from "@/actions/payments";

export const Delete = ({
  onChange,
  setLoading,
}: {
  onChange: () => void;
  setLoading: (value: boolean) => void;
  loading: boolean;
}) => {
  const {
    patient_payments: { payment_selected },
    user,
    updatePatient,
  } = useBoundStore();

  const handleSubmit = async () => {
    setLoading(true);
    const response = await deletePayment(payment_selected);

    if (response?.error) {
      return toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Pago eliminado con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
      if (response?.account) {
        payment_selected.is_usd
          ? updatePatient("balance_usd", response?.account?.balance_usd)
          : updatePatient("balance", response?.account?.balance);
      }
      onChange();
    }

    setLoading(false);
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Aviso</DialogTitle>
        <DialogDescription>
          {payment_selected?.cash_register?.length > 0 || user.is_supervisor ? (
            <>
              Esta intentando eliminar un pago del sistema, pero este se
              encuentra en un periodo de administración ya cerrado.
              <br />
              Debido a que Ud. es Supervisor, el sistema puede autorizarlo.
            </>
          ) : (
            "Esta acción borrará de forma permanente el pago seleccionado."
          )}
        </DialogDescription>
      </DialogHeader>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button variant="destructive">Cancelar</Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={handleSubmit}>Eliminar pago</Button>
        </DialogClose>
      </div>
    </DialogContent>
  );
};
