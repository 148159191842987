"use client";
import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import {
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  Button,
  SheetFooter,
  Input,
} from "@/components/atoms";
import { Offices, Professional, Specialty } from "@/types/general";
import { toast, useDialog } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { updateSpecialtiesConfig } from "@/actions/configs";
import { formSchema, resolver } from "./schema";

export const SpecialtyForm = ({
  specialty,
}: {
  specialty?: Specialty;
  professional?: Professional;
  offices?: Offices[];
}) => {
  const { closeDialog } = useDialog();
  const { startLoading, stopLoading, loading } = useBoundStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      specialty_code: specialty?.specialty_code,
      description: specialty?.description,
    },
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const option = specialty ? "modificar" : "nuevo";
    const response = await updateSpecialtiesConfig({
      ...values,
      rowversion: specialty?.rowversion,
      id_specialty: specialty?.id_specialty,
      option,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: specialty
          ? "Especialidad actualizada correctamente"
          : "Especialidad creada correctamente",
        description: "Los datos se verán reflejados en las tablas.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <SheetContent className="w-96 !text-start flex flex-col justify-between overflow-y-auto">
      <SheetHeader className="!text-start">
        <SheetTitle className="p-2 font-semibold text-xl">
          {specialty ? "Modificar especialidad" : "Nuevo especialidad"}
        </SheetTitle>
        <SheetDescription className="flex flex-col p-2 font-semibold text-lg"></SheetDescription>
        <Form {...form}>
          <form className="w-full grid grid-cols-12 gap-4 overflow-hidden bg-white p-2">
            <FormField
              control={form.control}
              name="specialty_code"
              render={({ field }) => (
                <FormItem className="col-span-12">
                  <FormLabel>Código</FormLabel>
                  <FormControl>
                    <Input {...field} maxLength={10} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="col-span-12">
                  <FormLabel>Especialidad</FormLabel>
                  <FormControl>
                    <Input {...field} maxLength={20} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </SheetHeader>
      <SheetFooter>
        <SheetClose>
          <Button variant="destructive" type="button">
            Cancelar
          </Button>
        </SheetClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button className="bg-blue-600" onClick={form.handleSubmit(onSubmit)}>
            Guardar
          </Button>
        )}
      </SheetFooter>
    </SheetContent>
  );
};
