"use client";

import React from "react";
import {
  Button,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from "@/components/atoms";
import { toast } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { useDialog } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { deleteSupplier } from "@/actions/configs";
import { Supplier } from "@/types/general";

export function DeleteSupplierForm({ selected }: { selected: Supplier }) {
  const { closeDialog } = useDialog();
  const { loading, startLoading, stopLoading } = useBoundStore();

  const onSubmit = async () => {
    startLoading();
    const response = await deleteSupplier(selected);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El proveedor se eliminó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar proveedor</DialogTitle>
        <p className="text-slate-500 text-base">
          Estas por eliminar un proveedor. Esta acción es irreversible, ¿deseas
          continuar?
        </p>
      </DialogHeader>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose asChild className="col-span-2 col-start-9">
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading className="col-span-2" />
        ) : (
          <Button className="col-span-2" onClick={onSubmit}>
            Continuar
          </Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
