import { saveTurn } from "@/actions/schedule";
import { Button, DialogClose } from "@/components/atoms";
import { toast, useScheduleUpdate } from "@/hooks";
import { useBoundStore } from "@/hooks";

export const DeleteAppointment: React.FC<{
  refresh: () => void;
  setChangeDataTable: (value: string) => void;
}> = ({ setChangeDataTable, refresh }) => {
  const {
    schedule: { current_appointment },
    user: { id_user },
    startLoading,
    stopLoading,
  } = useBoundStore();
  const { startRefresh } = useScheduleUpdate();

  const onSumbitHandler = async () => {
    startLoading();
    setChangeDataTable(current_appointment.time);
    const response = await saveTurn({
      appointment: {
        ...current_appointment,
        dont_give:
          (current_appointment.id_patient && current_appointment.is_overturn) ||
          current_appointment.is_overturn
            ? true
            : false,
        observation: null,
        patient_name: null,
        patient_phone: null,
        attendance: null,
      },
      attendance: current_appointment.attendance,
      id_user,
      option: "anularTurno",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      await refresh();
      toast({
        variant: "successful",
        title: current_appointment.dont_give
          ? "Turno habilitado con éxito"
          : "Turno eliminado con éxito",
        description: "Puedes visualizar los cambios en la agenda.",
      });
    }

    setChangeDataTable("");
    stopLoading();
  };

  return (
    <div>
      <section>
        {current_appointment.dont_give ? (
          <span>¿Querés volver a habilitar este turno?</span>
        ) : (
          <span>
            Estás por liberar el turno y esta acción es irreversible, el turno
            dado se liberará definitivamente.
          </span>
        )}
        <div className="grid grid-cols-4 mt-5 gap-x-5">
          <DialogClose>
            <Button
              onClick={() => {
                startRefresh();
              }}
              type="button"
              className="w-full"
              variant="destructive"
            >
              Cancelar
            </Button>
          </DialogClose>
          <Button onClick={onSumbitHandler} className="w-full">
            {current_appointment.dont_give ? "Aceptar" : "Eliminar"}
          </Button>
        </div>
      </section>
    </div>
  );
};
