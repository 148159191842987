"use client";

import React from "react";
import {
  Button,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from "@/components/atoms";
import { toast } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { useDialog } from "@/hooks";
import { Benefit, MedicalInsurance, Plan } from "@/types/general";
import {
  deleteBenefitConfig,
  getMedicalInsuranceBenefits,
} from "@/actions/configs";
import { ButtonLoading } from "@/components/molecules";

export function DeleteBenefitForm({
  selected,
  data: { medicalInsurance, plan },
}: {
  selected: Benefit;
  data: {
    medicalInsurance: MedicalInsurance;
    plan: Plan;
  };
}) {
  const { closeDialog } = useDialog();
  const { loading, startLoading, stopLoading, setBenefits } = useBoundStore();

  const onSubmit = async () => {
    startLoading();
    const [deleteResponse, getResponse] = await Promise.all([
      deleteBenefitConfig({
        medicalInsurance,
        benefit: selected as Benefit,
        plan,
      }),
      getMedicalInsuranceBenefits("OSPRE", plan.id_plan),
    ]);

    if (deleteResponse?.error || getResponse?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: deleteResponse?.error || getResponse?.error,
      });
    } else {
      setBenefits({ data: getResponse.benefits });
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "La prestación se eliminó correctamente.",
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar prestación</DialogTitle>
        <p className="text-slate-500 text-base">
          Estas por eliminar esta prestación. Esta acción es irreversible,
          ¿desea continuar?
        </p>
      </DialogHeader>
      <DialogFooter className="grid grid-cols-12">
        <DialogClose asChild className="col-span-2 col-start-9">
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading className="col-span-2" />
        ) : (
          <Button className="col-span-2" onClick={onSubmit}>
            Continuar
          </Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
}
