"use client";

import React from "react";
import { useBoundStore } from "@/hooks";
import { BenefitsPatientForm } from "@/components/organisms";
import { BenefitsFilter } from "@/components/organisms/tables";
import { EmptyState } from "@/components/molecules";
import {
  Skeleton,
  Dialog,
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  DialogContent,
  DialogHeader,
} from "@/components/atoms";
import { updateSection } from "@/actions/patients";
import { toast } from "@/hooks";
import { ODONTOGRAM_OPTIONS } from "@/libs/benefits";
import { filterAllByTooth } from "@/utils/benefits";
import { Layout } from "./layout";
import { Benefit } from "@/types/general";

export const OdontogramView = ({ benefits }: { benefits: Benefit[] }) => {
  const {
    patient,
    setBenefitSelected,
    patient_benefits,
    updatePatient,
    general_params: { offices },
  } = useBoundStore();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [benefitsFilter, setBenefitsFilter] = React.useState<any | null>(null);
  const [odontogramType, setOdontogramType] = React.useState("odontogramaper");
  const [typeModal, setTypeModal] = React.useState<string>("");
  const [tooth, setTooth] = React.useState<string>("");

  const getOdontogram = async () => {
    setLoading(true);
    const response = await updateSection(
      [odontogramType, "prest"],
      patient.id_patient
    );

    if (response?.error) {
      return toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const { odontogram } = await updateSection(
        [odontogramType],
        patient.id_patient
      );
      updatePatient("odontogram", odontogram);
      updatePatient("alls", response.benefits);
      setOpenModal(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!patient.id_patient) return;

    void getOdontogram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.id_patient, odontogramType]);

  const openModalWithType = (type: string) => {
    setTypeModal(type);
    setOpenModal(true);
  };
  const ACTIONS = {
    view_details: (tooth: string) => {
      openModalWithType("view_details");
      setBenefitsFilter(filterAllByTooth(patient.alls, tooth));
    },
    new_benefit: (tooth: string) => {
      openModalWithType("new_benefit");
      setBenefitSelected(null);
      setTooth(tooth);
    },
  };

  const MODALS_TYPES: { [key: string]: React.ReactNode } = {
    new_benefit: (
      <BenefitsPatientForm
        onCancel={() => null}
        offices={offices}
        benefits={benefits}
        onChange={getOdontogram}
        compact
        tooth={tooth}
      />
    ),
    view_details: (
      <>
        <DialogHeader>Lista de prestaciones</DialogHeader>
        <BenefitsFilter data={benefitsFilter} loading={loading} />
      </>
    ),
  };

  return (
    <div className="!w-full hidden lg:flex lg:flex-col">
      <div className="grid grid-cols-12">
        {loading ? (
          <div className="col-span-12 grid grid-cols-2 p-4 gap-5">
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-32 w-full" />
          </div>
        ) : patient.odontogram?.length > 0 && !loading ? (
          <>
            <div className="col-span-5">
              <Label>Tipo de odontograma</Label>
              <Select
                key="id_option"
                value={odontogramType}
                onValueChange={setOdontogramType}
              >
                <SelectTrigger className="!w-44 !mt-2 !h-10 !text-sm select-trigger-condition bg-white">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {ODONTOGRAM_OPTIONS.map(
                    (condition: {
                      id_option: number;
                      value: string;
                      name: string;
                    }) => (
                      <SelectItem
                        key={condition.id_option}
                        value={condition.value}
                        className="select-item-condition"
                      >
                        {condition.name}
                      </SelectItem>
                    )
                  )}
                </SelectContent>
              </Select>
            </div>
            <Layout odontogram={patient.odontogram} actions={ACTIONS} />
          </>
        ) : (
          <div className="m-auto col-span-5 col-start-4 grid grid-cols-12 gap-x-10">
            <EmptyState
              title="No hay prestaciones para mostrar"
              copy="Puedes cargar una prestacion"
              className="col-span-full !justify-start"
            />
          </div>
        )}
        <Dialog open={openModal} onOpenChange={setOpenModal}>
          <DialogContent>{MODALS_TYPES[typeModal]}</DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
