"use client";

import React from "react";
import { z } from "zod";
import { toast, useKeypress, useDialog } from "@/hooks";
import { Button, Separator, Input, SheetClose } from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  CompareRangePicker,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/molecules";
import {
  MedicalInsurances,
  Professionals,
  Offices,
  GroupDate,
  GroupMedIns,
} from "@/components/molecules/params";
import {
  getFinances,
  getControlPanel,
  getBenefitsMade,
  getBudgetsIssued,
} from "@/actions/statistics";
import { useBoundStore } from "@/hooks";
import {
  Form as FormT,
  MedicalInsurance,
  Offices as OfficesT,
  Professional,
} from "@/types/general";
import { formSchema } from "./schema";

export function StatisticsForm({
  form,
  values,
  section,
  showCompare,
  device,
}: {
  form: FormT<typeof formSchema>;
  values: {
    medical_insurances: MedicalInsurance[];
    offices: OfficesT[];
    professionals: Professional[];
  };
  section: string;
  showCompare?: boolean;
  device: string;
}) {
  const {
    setDataStatistics,
    startLoading,
    stopLoading,
    loading,
    general_params: { translations },
  } = useBoundStore();
  const { medical_insurances, offices, professionals } = values;
  const ACTIONS: { [key: string]: (values: any) => void } = {
    finances: getFinances,
    control: getControlPanel,
    "benefits-made": getBenefitsMade,
    budgets: getBudgetsIssued,
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response: any = await ACTIONS[section]({
      ...values,
      group_by_med_ins: values.group_by_med_ins === "prof" ? false : true,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      setDataStatistics({
        data: response,
        params: values,
        section,
      });
      toast({
        variant: "successful",
        title: "Éxito!",
        description: "Las estadísticas se han actualizado correctamente.",
      });
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className="rounded-md space-y-4 justify-start flex flex-col h-[100vh]"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
        >
          <div>
            <h4 className="text-sm">Selección de fecha</h4>
            <CompareRangePicker
              align={device === "mobile" ? "center" : "end"}
              name="date_comparison"
              form={form}
              showCompare={showCompare}
              device={device}
            />
          </div>
          {!["finances"].includes(section) && <GroupDate form={form} />}
          <MedicalInsurances
            form={form}
            medicalInsurances={medical_insurances}
            translations={translations}
          />
          <Separator className="bg-slate-200 h-px" />
          <Offices form={form} offices={offices} />
          <Professionals form={form} professionals={professionals} />
          {!["finances"].includes(section) && (
            <GroupMedIns form={form} translations={translations} />
          )}
          {["benefits-made"].includes(section) && (
            <>
              <Separator className="bg-slate-200 h-px" />
              <p className="text-slate-800 text-lg font-bold">Prestación</p>
              <FormField
                control={form.control}
                name="benefit_code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Código de prestación</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </>
          )}
        </div>
        <div className="w-full lg:w-auto flex flex-row justify-center items-center lg:justify-end gap-x-5 mt-8">
          <SheetClose asChild className="w-full">
            <Button
              type="button"
              onClick={() => {
                form.reset();
              }}
              className="w-full"
              variant="destructive"
            >
              Cancelar
            </Button>
          </SheetClose>
          {loading ? (
            <ButtonLoading className="w-full" />
          ) : (
            <Button type="submit" className="w-full">
              Guardar
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
