"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { ButtonLoading, Form } from "@/components/molecules";
import { Button, DialogClose } from "@/components/atoms";
import { toast, useScheduleUpdate } from "@/hooks";
import { saveTurn } from "@/actions/schedule";
import { useBoundStore } from "@/hooks";

export function DisabledAppointment({
  refresh,
  setChangeDataTable,
}: {
  refresh: () => void;
  setChangeDataTable: (value: string) => void;
}) {
  const {
    schedule: { current_appointment },
    loading,
    startLoading,
    stopLoading,
    user: { id_user: id_user },
  } = useBoundStore();
  const { startRefresh } = useScheduleUpdate();
  const form = useForm();

  async function onSubmit() {
    startLoading();
    setChangeDataTable(current_appointment.time);
    const response = await saveTurn({
      appointment: {
        ...current_appointment,
        dont_give: true,
        observation: null,
        patient_name: null,
        patient_phone: null,
        attendance: null,
        medical_insurance: {
          name: null,
          plan: null,
          affiliate_number: null,
        },
      },
      attendance: current_appointment.attendance,
      id_user,
      option: "anularTurno",
    });

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      await refresh();
      toast({
        variant: "successful",
        title: current_appointment.is_overturn
          ? "Turno eliminado con éxito"
          : "Turno deshabilitado con éxito",
        description: "Puedes visualizar los cambios en la agenda.",
      });
    }

    setChangeDataTable("");
    stopLoading();
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div>
          {current_appointment?.is_overturn
            ? "Este sobreturno se eliminará de su lista."
            : 'Este turno se marcará como "NO CITAR".'}
        </div>
        <div className="grid grid-cols-4 mt-5 gap-x-5">
          <DialogClose>
            <Button
              type="button"
              className="w-full"
              onClick={() => {
                form.reset();
                startRefresh();
              }}
              variant="destructive"
            >
              Cancelar
            </Button>
          </DialogClose>
          {loading ? (
            <ButtonLoading />
          ) : (
            <Button type="submit" className="w-full">
              Guardar
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
}
