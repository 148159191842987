"use client";

import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { LuChevronLeft } from "react-icons/lu";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  Button,
  buttonVariants,
  Card,
  CardContent,
  CardTitle,
  CardHeader,
  CardFooter,
  CardDescription,
  Checkbox,
  Combobox,
  Input,
} from "@/components/atoms";
import { toast, useKeypress } from "@/hooks";
import {
  ButtonLoading,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/molecules";
import { Offices } from "@/components/molecules/params";
import { useBoundStore } from "@/hooks";
import { DailyCashflow, Expense } from "@/types/administration";
import { Offices as OfficesProps } from "@/types/general";
import {
  setExpense,
  getExpense,
  getTransactions,
  getDailycashflow,
} from "@/actions/administration";
import {
  formSchema,
  resolver,
} from "@/components/organisms/forms/administration/new-cash-expense";
import {
  VOUCHER_DROPDOWN_OTHER,
  VOUCHER_TYPE_DROPDOWN,
  PAYMENT_METHOD_DROPDOWN,
} from "@/libs/administration";

export function NewCashExpenseForm({
  offices,
  expenseId,
}: {
  offices: OfficesProps[];
  expenseId?: string;
}) {
  const {
    loading,
    startLoading,
    stopLoading,
    setDataAdministration,
    administration: {
      transactions,
      dailycashflow: { data: dailycashflowData },
    },
    user: { id_user },
  } = useBoundStore();
  const ENABLED_OFFICES = offices.filter((office) => office.enabled);
  const router = useRouter();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_expense: 0,
      id_office: ENABLED_OFFICES?.[0]?.id_office ?? null,
      date: new TZDate(new Date().toISOString(), TIMEZONES.AR),
      voucher: "Factura",
      voucher_type: "A",
      payment_method: "Efectivo",
      show_in_cashflow: true,
      total: 0,
      id_user: id_user,
      voucher_number: "",
      concept: "",
      details: "",
      is_usd: false,
      is_supplier_payment: false,
      disabled: false,
      type: "other",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setExpense(values as z.infer<typeof formSchema>);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const data = await getTransactions(transactions.params);

      setDataAdministration({
        data,
        params: transactions.params,
        section: "transactions",
      });

      const response = await getDailycashflow();

      if ("error" in response) {
        toast({
          variant: "destructive",
          title: "Ups! Parece que hubo un error",
          description: response.error,
        });
      } else {
        setDataAdministration({
          data: {
            ...dailycashflowData,
            expenses: response.expenses,
            totals: response.totals,
          },
          section: "dailycashflow",
        });
      }

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El egreso se guardó correctamente.",
      });
      await router.push("/dashboard/administration/transactions/expenses");
    }

    stopLoading();
  };

  React.useEffect(() => {
    async function getData() {
      try {
        startLoading();
        const expense: Expense = await getExpense(expenseId as string);

        form.reset(expense as z.infer<typeof formSchema>);
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Ups! Hubo un error al obtener los datos",
          description: error.message,
        });
      } finally {
        stopLoading();
      }
    }

    if (!expenseId) return;

    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseId]);

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-x-2">
          <Link
            href="/dashboard/administration/transactions/expenses"
            className={classNames(
              buttonVariants({ variant: "outline", size: "icon" })
            )}
          >
            <LuChevronLeft className="size-4" />
            <span className="sr-only">Back</span>
          </Link>
          Egreso de caja
        </CardTitle>
        <CardDescription>
          Esto implica un movimiento en efectivo.
        </CardDescription>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="gap-4 grid grid-cols-12">
            <FormField
              control={form.control}
              name="show_in_cashflow"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-4 self-start">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => field.onChange(checked)}
                    />
                  </FormControl>
                  <div className="leading-none mt-0 flex flex-col">
                    <FormLabel>Representar en caja diaria</FormLabel>
                    <FormDescription className="text-slate-500">
                      Se incluirá el egreso en la caja diaria.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
            <div className="col-span-4 self-end">
              <DatePicker label="Seleccionar fecha" name="date" form={form} />
            </div>
            <div className="col-span-4 self-end">
              <Offices
                form={form}
                offices={offices}
                show={{
                  disabled: false,
                  allOffices: false,
                }}
                classname="text-sm font-medium mb-2"
              />
            </div>
            <FormItem className="col-span-3 col-start-1">
              <FormLabel>Comprobante</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={VOUCHER_DROPDOWN_OTHER}
                  dataKey="voucher"
                  value="text"
                  placeholder="Elige un comprobante"
                  title="Comprobante"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormItem className="col-span-2">
              <FormLabel>Tipo</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={VOUCHER_TYPE_DROPDOWN}
                  dataKey="voucher_type"
                  value="text"
                  placeholder="Elige un tipo de comprobante"
                  title="Tipo"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="voucher_number"
              render={({ field }) => (
                <FormItem className="col-span-4">
                  <FormLabel>Número</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormItem className="col-span-3">
              <FormLabel>Forma de pago</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={PAYMENT_METHOD_DROPDOWN.slice(0, 1)}
                  dataKey="payment_method"
                  value="text"
                  placeholder="Elige forma de pago"
                  title="Forma de pago"
                  disabled={form.getValues("id_expense") !== 0}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="concept"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Concepto</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="details"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Detalle</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_usd"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 justify-start col-span-4 self-start">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={(checked) => field.onChange(checked)}
                    />
                  </FormControl>
                  <div className="leading-none mt-0 flex flex-col">
                    <FormLabel>Es USD</FormLabel>
                    <FormDescription className="text-slate-500">
                      Los importes se cargaran en dolares.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="total"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Importe *</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="flex flex-row justify-center items-center lg:justify-end gap-x-2">
            <Button
              type="button"
              onClick={() => {
                form.reset();
                router.push("/dashboard/administration/transactions/expenses");
              }}
              variant="destructive"
            >
              Cancelar
            </Button>
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button type="submit">Guardar</Button>
            )}
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
