"use client";

import React from "react";
import { columns } from "./columns";
import { Data } from "./data";
import { remindersGridMapper } from "@/utils/mappers";
import { useBoundStore } from "@/hooks";
import { EmptyState } from "@/components/molecules";
import { Skeleton } from "@/components/atoms";
import { ScheduleList } from "@/types/schedule";
import { Professional } from "@/types/general";

export const Table: React.FC<{
  data: { schedule_list: ScheduleList[]; professionals: Professional[] };
}> = ({ data }) => {
  const {
    reminders: { data: list_reminders, list_send_reminders },
    setReminders,
    setListSendReminders,
    loading,
  } = useBoundStore();

  React.useEffect(() => {
    setReminders({ data: data.schedule_list });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <>
      <Skeleton className="w-full h-12 mb-1" />
      <Skeleton className="w-full h-[300px]" />
    </>
  ) : list_reminders.length <= 0 ? (
    <EmptyState title="Ups!" copy="No hay recordatorios para mostrar." />
  ) : (
    <Data
      columns={columns(
        list_send_reminders,
        setListSendReminders,
        remindersGridMapper(list_reminders, -1)
      )}
      data={remindersGridMapper(list_reminders, -1)}
    />
  );
};
