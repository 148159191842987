"use client";

import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { useBoundStore } from "@/hooks";
import { toast } from "@/hooks";
import { deleteOrder } from "@/actions/orders";

export const Delete = ({
  onChange,
  setLoading,
}: {
  onChange: () => void;
  setLoading: (value: boolean) => void;
  loading: boolean;
}) => {
  const { order_selected } = useBoundStore();
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await deleteOrder(order_selected);
      toast({
        variant: "successful",
        title: "Orden eliminada con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
      onChange();
    } catch (err: any) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Aviso</DialogTitle>
        <DialogDescription>
          Esta acción borrará de forma permanente la orden seleccionada.
        </DialogDescription>
      </DialogHeader>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button variant="destructive">Cancelar</Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={handleSubmit}>Eliminar orden</Button>
        </DialogClose>
      </div>
    </DialogContent>
  );
};
