"use client";

import React from "react";
import { DialogContent } from "@/components/atoms";
import { DeleteImage } from "./delete";
import { EditImage } from "./edit";
import { useBoundStore } from "@/hooks";

export const GalleryActions = ({
  formState,
  closeModal,
  refresh,
}: {
  formState: string;
  closeModal: (value: boolean) => void;
  refresh: () => void;
}) => {
  const {
    patient_gallery: { archives_selected },
  } = useBoundStore();
  const FORM_LIST: {
    [key: string]: { content: React.ReactNode };
  } = {
    edit: {
      content: <EditImage closeModal={closeModal} refresh={refresh} />,
    },
    delete: {
      content: (
        <DeleteImage
          closeModal={closeModal}
          refresh={refresh}
          archive={archives_selected}
        />
      ),
    },
  };

  const FORM_MODAL = FORM_LIST[formState];

  return FORM_MODAL && <DialogContent>{FORM_MODAL.content}</DialogContent>;
};
