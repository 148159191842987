import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { toast, useDialog } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { deleteTimetable } from "@/actions/professionals";
import { useBoundStore } from "@/hooks";
import { Time } from "@/types/timetable";

export const DeleteTime = ({ time }: { time: Time | undefined }) => {
  const { loading, startLoading, stopLoading } = useBoundStore();
  const { closeDialog } = useDialog();

  const onSubmit = async () => {
    startLoading();
    const response = await deleteTimetable({
      ...time,
      time_from: time?.from,
      time_to: time?.to,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Horario eliminado con éxito",
        description: "El horario se eliminó correctamente.",
      });
      closeDialog();
    }
    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar horario</DialogTitle>
      </DialogHeader>
      <span>Estás por eliminar un horario y esta acción es irreversible.</span>
      <div className="flex justify-end gap-x-5">
        <DialogClose>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit} className="delete-patient">
            Eliminar
          </Button>
        )}
      </div>
    </DialogContent>
  );
};
