import * as React from "react";
import classNames from "classnames";
import { LuChevronsUpDown, LuCheck, LuTableProperties } from "react-icons/lu";
import { useBoundStore } from "@/hooks";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/atoms";
import { FormControl, FormField, FormItem } from "@/components/molecules";
import { Form } from "@/types/general";
import { formSchema } from "@/components/organisms/forms/schedule/search/schema";
import { toast } from "@/hooks";

export function ScheduleLayoutsSelect({
  form,
  layouts,
  className,
}: {
  form: Form<typeof formSchema>;
  layouts: { id_layout: number; description: string; value: string }[];
  className?: string;
}) {
  const {
    schedule: { config },
    setScheduleConfig,
    user: { plan_version, is_supervisor },
    permissions,
  } = useBoundStore();
  const DENSITY = React.useMemo(() => config.type_table, [config.type_table]);
  const [open, setOpen] = React.useState(false);
  const { id_layout, id_professional } = form.getValues();
  const filteredLayout = React.useMemo(() => {
    if (["freemium", "standard"].includes(plan_version)) {
      return layouts.filter((layout) => layout.id_layout !== 2);
    }
    if (["small"].includes(plan_version)) {
      return layouts.filter((layout) => ![2, 3].includes(layout.id_layout));
    }
    if (!is_supervisor && !permissions.hasOwnProperty("Ver Semana")) {
      return layouts.filter((layout) => layout.id_layout !== 3);
    }
    return layouts;
  }, [layouts, plan_version]);

  return (
    <div className={className}>
      <FormField
        control={form.control}
        name="id_layout"
        render={({ field }) => (
          <FormItem className="w-full">
            <Popover open={open} onOpenChange={() => setOpen(!open)}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    className={classNames(
                      "justify-between h-9 w-full",
                      !field.value && "text-slate-400"
                    )}
                  >
                    {field.value
                      ? filteredLayout.find(
                          (layout) => layout.id_layout === field.value
                        )?.description
                      : "Selección de agenda"}
                    <LuChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandList>
                    <CommandGroup>
                      {filteredLayout.map((layout) => (
                        <CommandItem
                          value={layout.description}
                          key={layout.id_layout}
                          onSelect={() => {
                            if (
                              [
                                "buscarpordiatodosprof",
                                "buscarsemana",
                              ].includes(layout.value) &&
                              !id_professional
                            ) {
                              return toast({
                                variant: "warning",
                                title: "¡Campo requerido!",
                                description: "Debe seleccionar un profesional",
                              });
                            }
                            field.onChange(layout.id_layout);
                            setOpen(false);
                          }}
                        >
                          <LuCheck
                            className={classNames(
                              "mr-2 size-4",
                              layout.id_layout === field.value
                                ? "opacity-100 text-blue-500"
                                : "opacity-0"
                            )}
                          />
                          {layout.description}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </FormItem>
        )}
      />
      <DropdownMenu>
        <Tooltip>
          <TooltipProvider>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="h-9 w-9"
                  disabled={id_layout !== 1}
                >
                  <LuTableProperties className="size-4" />
                  <span className="sr-only">Densidad de información</span>
                </Button>
              </DropdownMenuTrigger>
            </TooltipTrigger>
            <TooltipContent>Densidad de información</TooltipContent>
          </TooltipProvider>
        </Tooltip>
        <DropdownMenuContent>
          {[
            {
              text: "Expandida",
              value: "expanded",
            },
            {
              text: "Compacta",
              value: "compact",
            },
          ].map((layout, index) => (
            <DropdownMenuCheckboxItem
              key={index}
              className="capitalize"
              checked={DENSITY === layout.value}
              onCheckedChange={() => {
                setScheduleConfig({
                  config: {
                    ...config,
                    type_table: layout.value,
                  },
                });
              }}
            >
              {layout.text}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
