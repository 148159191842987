import { TZDate } from "@date-fns/tz";
import {
  isBefore,
  parseISO,
  subDays,
  startOfDay,
  format,
  parse,
  differenceInMinutes,
  addMinutes,
} from "date-fns";
import { TIMEZONES } from "@/libs/schedule";
import { Appointment } from "@/types/general";

export const year = new Date().getFullYear();

export const getDailyStatisticsDefaultPeriod = () => {
  const firstday = format(startOfDay(subDays(new Date(), 6)), "yyyy-MM-dd");
  const lastday = format(startOfDay(subDays(new Date(), 0)), "yyyy-MM-dd");

  return {
    firstday: new Date(`${firstday} 00:00:00`),
    lastday: new Date(`${lastday} 00:00:00`),
  };
};

export const validateAppointmentDateTime = (
  appointment: Appointment
): boolean => {
  const { date, time } = appointment;
  const DATE_TIME = parseISO(
    `${typeof date === "string" && date.split("T")[0]}T${time}:00`
  );
  const CURRENT_DATE_TIME = new Date();

  return isBefore(DATE_TIME, CURRENT_DATE_TIME);
};

export const beforeDays = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export const findFirstGreaterTime = (
  baseTime: string,
  appointments: Appointment[]
): string | null => {
  const FORMAT = "HH:mm";
  const BASE_DATE = parse(baseTime, FORMAT, new Date());
  const FILTER_APPOINTMENTS = appointments.filter(
    (appointment) => appointment.id_schedule === -1
  );

  return (
    FILTER_APPOINTMENTS.find((appointment) => {
      const parsedTime = parse(appointment.time, FORMAT, new Date());
      return differenceInMinutes(parsedTime, BASE_DATE) > 0;
    })?.time || null
  );
};

export const incrementOneMinute = (baseTime: string): string => {
  const FORMAT = "HH:mm";
  const BASE_DATE = parse(baseTime, FORMAT, new Date());
  const INCREMENTED_DATE = addMinutes(BASE_DATE, 1);
  return format(INCREMENTED_DATE, FORMAT);
};

export const transformDateToZoneTime = (date?: string | Date): string => {
  const DATE = date
    ? new TZDate(new Date(date).toISOString(), TIMEZONES.AR)
    : new TZDate(new Date().toISOString(), TIMEZONES.AR);

  return format(DATE, "yyyy-MM-dd");
};

export const formatRecipeDate = (date: Date) => {
  // if (isEqual(date.getDate(), new Date().getDate())) {
  //   return addHours(new Date(), 3);
  // }
  // const utc_date = new UTCDate(date);
  const now = new Date(); // Obtener la hora actual
  const fullDate = new Date(date);
  // return addHours(new Date(utc_date.toDateString()), 3);
  fullDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
  return fullDate;
};
