"use client";

import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { LuChevronLeft } from "react-icons/lu";
import { TZDate } from "@date-fns/tz";
import { TIMEZONES } from "@/libs/schedule";
import {
  Button,
  Card,
  buttonVariants,
  CardContent,
  CardTitle,
  CardHeader,
  CardFooter,
  Input,
  Combobox,
} from "@/components/atoms";
import { toast, useKeypress } from "@/hooks";
import {
  ButtonLoading,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/molecules";
import { Suppliers, Offices } from "@/components/molecules/params";
import {
  setExpense,
  getExpense,
  getTransactions,
} from "@/actions/administration";
import { useBoundStore } from "@/hooks";
import { Expense, DailyCashflow } from "@/types/administration";
import { Supplier, Offices as OfficesProps } from "@/types/general";
import {
  VOUCHER_DROPDOWN_INVOICE,
  VOUCHER_TYPE_DROPDOWN,
} from "@/libs/administration";
import {
  formSchema,
  resolver,
} from "@/components/organisms/forms/administration/new-invoice-expense";

export function NewInvoiceExpenseForm({
  suppliers,
  offices,
  expenseId,
}: {
  suppliers: Supplier[];
  offices: OfficesProps[];
  expenseId?: string;
}) {
  const {
    loading,
    startLoading,
    stopLoading,
    setDataAdministration,
    administration: { transactions },
    user: { id_user },
  } = useBoundStore();
  const ENABLED_SUPPLIERS = suppliers?.filter((item) => item.enabled);
  const ENABLED_OFFICES = offices.filter((office) => office.enabled);
  const router = useRouter();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver,
    defaultValues: {
      id_expense: 0,
      id_office: ENABLED_OFFICES?.[0]?.id_office ?? null,
      id_supplier: ENABLED_SUPPLIERS?.[0]?.id_supplier ?? null,
      id_professional_paying: null,
      id_laboratory_paying: null,
      id_supplier_navigation: null,
      date: new TZDate(new Date().toISOString(), TIMEZONES.AR),
      voucher: "Otro",
      voucher_type: "A",
      payment_method: "",
      show_in_cashflow: false,
      total: 0,
      id_user: id_user,
      voucher_number: "",
      concept: "",
      details: "",
      is_supplier_payment: false,
      disabled: false,
      type: "invoice",
      net_taxed: 0,
      net_other: 0,
      iva_21: 0,
      iva_other: 0,
      percep: 0,
      exempt: 0,
      others: 0,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await setExpense(values as z.infer<typeof formSchema>);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const data = await getTransactions(transactions.params);

      setDataAdministration({
        data,
        params: transactions.params,
        section: "transactions",
      });

      toast({
        variant: "successful",
        title: "Éxito!",
        description: "El egreso se guardó correctamente.",
      });
      await router.push("/dashboard/administration/transactions/expenses");
    }

    stopLoading();
  };

  React.useEffect(() => {
    async function getData() {
      try {
        startLoading();
        const expense: Expense = await getExpense(expenseId as string);

        form.reset(expense as z.infer<typeof formSchema>);
      } catch (error: any) {
        toast({
          variant: "destructive",
          title: "Ups! Hubo un error al obtener los datos",
          description: error.message,
        });
      } finally {
        stopLoading();
      }
    }

    if (!expenseId) return;

    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseId]);

  React.useEffect(() => {
    const subscription = form.watch((values) => {
      const {
        net_taxed,
        net_other,
        exempt,
        percep,
        others,
        iva_21,
        iva_other,
      } = values;
      const TOTAL = Math.round(
        ((Number(net_taxed) +
          Number(net_other) +
          Number(exempt) +
          Number(percep) +
          Number(others) +
          Number(iva_21) +
          Number(iva_other) +
          Number.EPSILON) *
          100) /
          100
      );

      const CURRENT_TOTAL = form.getValues("total");
      if (CURRENT_TOTAL !== TOTAL) {
        form.setValue("total", TOTAL, {
          shouldValidate: false,
          shouldDirty: false,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-x-2">
          <Link
            href="/dashboard/administration/transactions/expenses"
            className={classNames(
              buttonVariants({ variant: "outline", size: "icon" })
            )}
          >
            <LuChevronLeft className="size-4" />
            <span className="sr-only">Back</span>
          </Link>
          Factura a proveedor
        </CardTitle>
      </CardHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="gap-4 grid grid-cols-12">
            <div className="col-span-4 self-end">
              <DatePicker label="Seleccionar fecha" name="date" form={form} />
            </div>
            <div className="col-span-4 self-end">
              <Suppliers suppliers={ENABLED_SUPPLIERS} form={form} />
            </div>
            {!!offices.length && (
              <div className="col-span-4 self-end">
                <Offices
                  form={form}
                  offices={offices}
                  show={{
                    disabled: false,
                    allOffices: false,
                  }}
                  classname="text-sm font-medium mb-2"
                />
              </div>
            )}
            <FormItem className="col-span-3 col-start-1">
              <FormLabel>Comprobante</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={VOUCHER_DROPDOWN_INVOICE}
                  dataKey="voucher"
                  value="text"
                  placeholder="Elige un comprobante"
                  title="Comprobante"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormItem className="col-span-2">
              <FormLabel>Tipo</FormLabel>
              <FormControl>
                <Combobox
                  form={form}
                  data={VOUCHER_TYPE_DROPDOWN}
                  dataKey="voucher_type"
                  value="text"
                  placeholder="Elige un tipo de comprobante"
                  title="Tipo"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
            <FormField
              control={form.control}
              name="voucher_number"
              render={({ field }) => (
                <FormItem className="col-span-4">
                  <FormLabel>Número</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="concept"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Concepto</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="details"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Detalle</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="net_taxed"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Neto gravado</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iva_21"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>IVA 1</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iva_other"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>IVA 2</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="net_other"
              render={({ field }) => (
                <FormItem className="col-start-1 col-span-3">
                  <FormLabel>Neto otro</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="exempt"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Exentas</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="percep"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Percepción</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="others"
              render={({ field }) => (
                <FormItem className="col-span-3">
                  <FormLabel>Otros</FormLabel>
                  <FormControl>
                    <Input type="number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="total"
              render={({ field }) => (
                <FormItem className="col-span-6">
                  <FormLabel>Total</FormLabel>
                  <FormControl>
                    <Input type="number" disabled {...field} />
                  </FormControl>
                  <FormDescription className="text-slate-500">
                    El total de la factura se calculará automáticamente.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="flex flex-row justify-center items-center lg:justify-end gap-x-2">
            <Button
              type="button"
              onClick={() => {
                form.reset();
                router.push("/dashboard/administration/transactions/expenses");
              }}
              variant="destructive"
            >
              Cancelar
            </Button>
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button type="submit">Guardar</Button>
            )}
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
}
