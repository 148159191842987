import React from "react";
import { LuCircleAlert as Alert } from "react-icons/lu";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Checkbox,
  Dialog,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  InputCarnetVerification,
  MedInsSelect,
  PlansSelect,
} from "@/components/molecules";
import { CONDITION } from "@/libs/patients";
import { toast } from "@/hooks";
import { Form, MedicalInsurance } from "@/types/general";
import { MembershipCheckList } from "@/types/patients";
import { getMembershipHistory } from "@/actions/patients";
import { useBoundStore } from "@/hooks";
import { MemberVerificacion } from "../membership";
import { formSchema } from "../schema";

export const Affiliation = ({
  form,
  medicalInsurances,
  lenderCode,
}: {
  form: Form<typeof formSchema>;
  medicalInsurances: MedicalInsurance[];
  lenderCode: string;
}) => {
  const {
    patient: { id_patient, plan_navigation },
    general_params: { translations },
  } = useBoundStore();
  const {
    is_holder,
    condition,
    affiliate_number,
    document_number,
    id_med_ins,
  } = form.watch();
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [history, setHistory] = React.useState<MembershipCheckList[]>([]);
  const PLANS = React.useMemo(() => {
    const MEDICAL_INSURANCE = medicalInsurances?.find(
      (m: MedicalInsurance) => m.id_med_ins === id_med_ins
    );
    return MEDICAL_INSURANCE?.plans || [];
  }, [id_med_ins, medicalInsurances]);
  const MEDICAL_INSURANCE_ENABLED = React.useMemo(() => {
    return medicalInsurances?.filter((m: MedicalInsurance) => !m.disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const MEDICAL_INSURANCE = medicalInsurances?.find(
    (m: MedicalInsurance) => m.id_med_ins === id_med_ins
  );
  const ACTION_DISABLED =
    !lenderCode ||
    (!affiliate_number && !document_number) ||
    !MEDICAL_INSURANCE?.client_ftp;
  const ERRORS = !lenderCode
    ? "Falta el codigo de prestador"
    : !document_number
    ? "Falta DNI del paciente"
    : `Falta cliente FTP en el/la ${translations.medical_insurance} del paciente`;
  const onClickHandler = () => {
    if (!id_patient) {
      return toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: "No puede validar el carnet de un paciente inexistente",
      });
    }
    if (!ACTION_DISABLED) {
      return !form.formState.isDirty
        ? setOpenDialog(true)
        : toast({
            variant: "destructive",
            title: "Ups! Parece que hubo un error",
            description: "Debe guardar el paciente primero",
          });
    }
    return toast({
      variant: "destructive",
      title: "Ups! Parece que hubo un error",
      description: ERRORS,
    });
  };
  const handleChange = (value: boolean) => {
    if (id_patient) {
      setOpenDialog(value);
    }
  };
  const getHistory = async () => {
    try {
      const data = await getMembershipHistory(id_patient);
      setHistory(data);
    } catch (error: any) {
      return toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: error.message,
      });
    }
  };

  React.useEffect(() => {
    plan_navigation?.id_med_ins !== id_med_ins;
    PLANS?.length &&
      form.setValue("id_plan", PLANS[0].id_plan, { shouldDirty: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PLANS]);

  return (
    <Card className="col-span-6 p-5">
      <CardHeader>
        <CardTitle>Datos afiliatorios</CardTitle>
        <CardDescription>
          Acá vas a encontrar los datos sobre la obra social de tu paciente.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <MedInsSelect
          form={form}
          label={translations?.medical_insurance}
          medicalInsurance={MEDICAL_INSURANCE_ENABLED}
          classname="med-ins-select col-span-6"
          translations={translations}
        />
        <PlansSelect
          form={form}
          plans={PLANS}
          classname="plans-select col-span-6"
        />
        <Dialog open={openDialog} onOpenChange={handleChange}>
          <div className="col-span-6">
            <Label>Número de afiliado</Label>
            <MemberVerificacion
              history={history}
              lenderCode={lenderCode}
              getHistory={getHistory}
            />
            <InputCarnetVerification form={form} onClick={onClickHandler} />
          </div>
        </Dialog>
        <div className="col-span-6">
          <Label>Condición</Label>
          <Select
            key="condition"
            value={condition}
            onValueChange={(value: string) =>
              form.setValue("condition", value, { shouldDirty: true })
            }
          >
            <SelectTrigger className="w-full !h-10 !text-sm select-trigger-condition">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {CONDITION.map((condition: any) => (
                <SelectItem
                  key={condition.id}
                  value={condition.id}
                  className="select-item-condition"
                >
                  {condition.text}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="self-end h-14 col-span-5 col-start-1 flex items-center">
          <Checkbox
            className="check-is-holder"
            checked={is_holder}
            onCheckedChange={(value: boolean) => {
              form.setValue("is_holder", value, { shouldDirty: true });
            }}
          />
          <div className="ml-2">
            <Label>Es titular</Label>
            <p className="text-sm font-regular text-gray-400">
              Es titular de su obra social.
            </p>
          </div>
        </div>
        {!is_holder && (
          <>
            <FormField
              control={form.control}
              name="holder_name"
              render={({ field }) => (
                <FormItem className="small col-span-3">
                  <FormLabel className="text-sm">Nombre del titular</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} maxLength={40} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="holder_company"
              render={({ field }) => (
                <FormItem className="small col-span-3">
                  <FormLabel>Titular empresa</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} maxLength={40} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}
        <FormField
          control={form.control}
          name="alert"
          render={({ field }) => (
            <FormItem className="col-span-12 col-start-1">
              <FormLabel className="flex flex-row items-center">
                <Alert className="mr-1 w-4 h-4 text-red-500" />
                Alertas
              </FormLabel>
              <FormControl>
                <Input type="text" {...field} maxLength={250} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </CardContent>
    </Card>
  );
};
