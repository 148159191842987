"use client";

import React from "react";
import { z } from "zod";
import { format } from "date-fns";
import { LuPen } from "react-icons/lu";
import { Button, CardTitle } from "@/components/atoms";
import { ConceptsTable } from "@/components/organisms/tables";
import { useBoundStore } from "@/hooks";
import { Form } from "@/types/general";
import { formSchema } from "../schema";

export const Summary = ({
  data,
  setStep,
  form,
}: {
  data: {
    order: {
      view_load_order: { setHiddenLoadOrder: (value: boolean) => void };
      date_order: string;
      date_estimated_received: string;
      laboratory_name: string;
      professional_name: { first_name: string; last_name: string };
      description: string;
      observation: string;
    };
    order_list: z.infer<typeof formSchema>["order_list"];
    order_from_list: z.infer<typeof formSchema>["order_from_list"];
  };
  setStep: (value: number) => void;
  form: Form<typeof formSchema>;
}) => {
  const {
    permissions,
    user: { is_supervisor },
  } = useBoundStore();
  const {
    view_load_order: { setHiddenLoadOrder },
    date_order,
    date_estimated_received,
    laboratory_name,
    professional_name,
    description,
    observation,
  } = data["order"];
  const { order_list, order_from_list } = form.watch();
  const { id_order } = form.getValues();
  const PERMISSIONS = !(
    id_order &&
    !permissions.hasOwnProperty("Modificar Pedido") &&
    !is_supervisor
  );
  const actions: {
    [key: string]: (
      value: z.infer<typeof formSchema>["order_from_list"]
    ) => void;
  } = {
    edit: (selectedRow: z.infer<typeof formSchema>["order_from_list"]) => {
      const ORDER_SELECTED = order_list?.find(
        (order: z.infer<typeof formSchema>["order_from_list"]) =>
          order.id_order_concept === selectedRow.id_order_concept
      );
      form.setValue("order_from_list", {
        ...order_from_list,
        ...ORDER_SELECTED,
      });
      form.setValue("is_manual_concept", !selectedRow.code);
      setStep(1);
      setHiddenLoadOrder(true);
    },
    delete: (selectedRow: z.infer<typeof formSchema>["order_from_list"]) => {
      const FILTER_ORDERS = order_list?.filter(
        (order: z.infer<typeof formSchema>["order_from_list"]) =>
          order.id_order_concept !== selectedRow.id_order_concept
      );
      form.setValue("order_list", FILTER_ORDERS);
    },
  };

  const action = (
    order: z.infer<typeof formSchema>["order_from_list"],
    action: string
  ) => {
    actions[action](order);
  };
  const CARDS_DATA: { [key: string]: any }[] = [
    {
      title: "Datos del pedido",
      className:
        "border-b border-b-slate-300 p-5 text-xl rounded-t-md bg-white truncate flex",
      content: [
        {
          label: "Descripción:",
          value: description,
        },
        {
          label: "Fecha pedido:",
          value: format(date_order, "dd-MM-yyyy"),
        },
        {
          label: "Fecha est. entrega:",
          value:
            (date_estimated_received &&
              format(date_estimated_received, "dd-MM-yyyy")) ||
            "-",
        },
        {
          label: "Laboratorio:",
          value: laboratory_name,
        },
        {
          label: "Profesional:",
          value: `${professional_name.first_name}, ${professional_name.last_name}`,
        },
        {
          label: "Observaciones:",
          value: observation,
        },
      ],
      tabIndex: 0,
      stylesContainer: "bg-white flex flex-col justify-between rounded-md mb-5",
    },
    {
      title: "Lista de trabajos",
      isTable: true,
      className: "text-xl truncate mb-5 print:mt-5",
      Content: () => (
        <ConceptsTable
          data={order_list.reverse()}
          action={action}
          form={form}
        />
      ),
      tabIndex: 1,
      stylesContainer:
        "flex flex-col justify-between mb-5 print:shadow-md print:rounded-md",
      stylesHeader: "flex justify-between print:ml-4",
    },
  ];

  return (
    <div className="mt-4 print:flex print:items-start print:gap-5">
      {CARDS_DATA.map((Item: { [key: string]: any }, index: number) => (
        <div key={index} className={Item.stylesContainer}>
          <div className={Item.stylesHeader || ""}>
            <CardTitle className={Item.className}>{Item.title}</CardTitle>
            {Item.isTable && PERMISSIONS && (
              <Button
                className="print:hidden"
                variant="ghost"
                onClick={() => setStep(Item.tabIndex)}
              >
                Cargar Trabajo
              </Button>
            )}
          </div>

          {!Item.isTable ? (
            <div className="grid grid-cols-12">
              {Item.content.map((content: any, i: number) => (
                <div key={i} className="flex flex-row p-3 px-5 col-span-3">
                  <span>{content.label}</span>
                  <span className="ml-2 !text-slate-900">{content.value}</span>
                </div>
              ))}
            </div>
          ) : (
            <Item.Content />
          )}
          {!Item.isTable &&
            (is_supervisor ||
              permissions.hasOwnProperty("Modificar Pedido")) && (
              <p
                className="group flex text-base print:hidden text-blue-500 justify-between p-4 px-8 border-t border-t-slate-300 hover:cursor-pointer"
                onClick={() => setStep(Item.tabIndex)}
              >
                Modificar
                <LuPen
                  type="arrow_right"
                  className="h-5 text-sm text-blue-500 transition-transform duration-500 group-hover:translate-x-3"
                />
              </p>
            )}
        </div>
      ))}
    </div>
  );
};
