"use client";

import React from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FiEye, FiEyeOff } from "react-icons/fi";
import {
  Button,
  Checkbox,
  Input,
  Card,
  CardHeader,
  CardDescription,
  CardContent,
  Separator,
} from "@/components/atoms";
import {
  ButtonLoading,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/molecules";
import { toast, useBoundStore, useKeypress } from "@/hooks";
import { userLogin } from "@/actions/login";
import {
  Offices,
  UserParams,
  Regions,
  Provinces,
  User,
  Permissions,
  Translations,
} from "@/types/general";
import { formSchema, resolver } from "./schema";

export function LoginForm({ flushStore }: { flushStore: boolean }) {
  const router = useRouter();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { setUser, startLoading, stopLoading, loading } = useBoundStore();
  const form = useForm<z.infer<typeof formSchema>>(resolver);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();

    const response = await userLogin(
      {
        webuser: values.webuser,
        user: values.user,
        password: values.password,
      },
      values.keep_logged
    );

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      const {
        user,
        permissions,
        region,
        countries,
        offices,
        user_params,
        provinces,
        translations,
      } = response as {
        user: User;
        permissions: Permissions[];
        region: string;
        countries: Regions[];
        offices: Offices[];
        user_params: UserParams;
        provinces: Provinces[];
        translations: Translations;
      };
      const params = {
        offices,
        user_params,
        translations,
        provinces,
      };
      const data = {
        user,
        permissions,
        region,
        countries,
      };

      setUser(data, params, values.webuser);
      router.push("/dashboard");
    }

    stopLoading();
  }

  React.useEffect(() => {
    if (flushStore) {
      const DEFAULT_STATE = useBoundStore.getInitialState();
      useBoundStore.setState(DEFAULT_STATE, true);
      router.replace("/");
    }
  }, []);

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="z-50 space-y-8">
        <Card className="z-50 mx-auto flex w-full flex-col justify-center">
          <CardHeader className="flex items-center flex-col text-center space-y-6">
            <Image
              className="mt-6"
              width={145}
              height={80}
              src="/logo-bilog.svg"
              alt="Bilog logo image"
              priority
            />
            <CardDescription className="text-neutral-500 text-lg lg:max-w-64 lg:text-base">
              ¡Hola! Ingresá tu usuario y contraseña para iniciar sesión.
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col space-y-3">
            <FormField
              control={form.control}
              name="webuser"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input type="text" placeholder="Usuario Bilog" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="user"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input type="text" placeholder="Usuario" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative flex justify-between">
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="******"
                        {...field}
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-3 cursor-pointer text-slate-600"
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </span>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="keep_logged"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 !space-y-1">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="leading-none">
                    <FormLabel>Recordar contraseña</FormLabel>
                  </div>
                </FormItem>
              )}
            />
            {loading ? (
              <ButtonLoading className="w-full !mt-6" />
            ) : (
              <Button type="submit" className="w-full !mt-6">
                Continuar
              </Button>
            )}
          </CardContent>
        </Card>
        <div className="flex h-5 items-center space-x-4 text-sm text-white">
          <Link
            className="block font-bold hover:underline"
            href="https://signup.bilog.com.ar"
            target="_blank"
            rel="noreferrer"
          >
            Registrarme
          </Link>
          <Separator orientation="vertical" />
          <Link
            className="block font-bold hover:underline"
            href="https://signup.bilog.com.ar/recovery/password"
            target="_blank"
            rel="noreferrer"
          >
            Olvidé mi contraseña
          </Link>
          <Separator orientation="vertical" />
          <Link
            className="block font-bold hover:underline"
            href="https://www.bilog.com.ar/first-steps"
            target="_blank"
            rel="noreferrer"
          >
            Ayuda
          </Link>
        </div>
      </form>
    </Form>
  );
}
