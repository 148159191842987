import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const schema = {
  name: z.string().min(3, "El nombre debe tener al menos 3 caracteres"),
  address: z.string(),
  phone: z.string(),
  email: z
    .string()
    .email()
    .max(30, "El email no debe tener mas de 30 caracteres")
    .or(z.literal("")) 
    .optional(),
  cuit: z.string(),
  id_tax_condition: z.number(),
  id_country: z.number(),
  use_usd: z.boolean(),
  tax_number: z.string(),
  recipe_logo: z.string().nullable(),
};

export const formSchema = z.object(schema);

export const resolver = zodResolver(formSchema);
