"use client";

import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { LuChevronLeft } from "react-icons/lu";
import classNames from "classnames";
import {
  buttonVariants,
  CardTitle,
  Tabs,
  TabsContent,
} from "@/components/atoms";
import { Form, FreemiumForm, ProgressBar } from "@/components/molecules";
import { toast, useDialog, useStepBar } from "@/hooks";
import { useBoundStore } from "@/hooks";
import { Offices, Professional, User } from "@/types/general";
import { enabledOffices } from "@/utils/mappers";
import { Financier } from "@/types/recipes";
import { splitNameFormatter } from "@/utils/helpers";
import { newRecipe } from "@/actions/recipes";
import { Patient } from "@/types/patients";
import { INITIAL_STEPS } from "@/libs/prescriptions";
import { updateSection } from "@/actions/patients";
import { professionalsByOffice } from "@/utils/filters";
import { getProfessionalSignature } from "@/actions/professionals";
import { formSchema, resolver } from "./schema";
import { First, Fourth, Second, Third } from "./steps";

export const PrescriptionsForm = ({
  onCancel,
  offices,
  professionals,
  financiers,
  users,
}: {
  onCancel: () => void;
  professionals: Professional[];
  offices: Offices[];
  financiers: Financier[];
  users: User[];
}) => {
  const {
    patient,
    general_params: { user_params },
    updatePatient,
    startLoading,
    stopLoading,
    prescription_selected,
    setPrescriptionsSelected,
  } = useBoundStore();
  const { openDialog } = useDialog();
  const [hiddenLoadMedicine, setHiddenLoadMedicine] = React.useState(false);
  const { steps, currentPosition, setStep, resetSteps } = useStepBar({
    initialSteps: INITIAL_STEPS,
    completeSteps: !!prescription_selected,
  });
  const ENABLED_OFFICES = offices && enabledOffices(offices);
  const ENABLED_PROFESSIONALS = professionalsByOffice({
    id_office: ENABLED_OFFICES?.[0]?.id_office || -1,
    professionals,
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: resolver,
    defaultValues: {
      add_general_diagnosis: false,
      add_diagnosis: false,
      is_manual_diagnosis: false,
      id_office: ENABLED_OFFICES?.[0]?.id_office || null,
      id_financier:
        patient?.plan_navigation?.medical_insurance_navigation?.id_financier ||
        financiers?.[0]?.id_financier,
      plan: patient?.plan_navigation?.name || "",
      full_name: patient?.full_name,
      id_professional: ENABLED_PROFESSIONALS?.[0]?.id_professional,
      document: patient?.document_number,
      affiliate_number: patient?.affiliate_number,
      date_of_recipe: new Date(),
      general_diagnosis: {
        description: "",
        code: "",
        id_diagnosis: -1,
        is_manual: false,
      },
      selected_medication: {
        code: null,
        product_name: "",
        presentation: "",
        id_medicine_recipe: Math.random(),
        quantity: 1,
        only_generic: false,
        not_replace: false,
        diagnosis: { description: "", code: "", id_diagnosis: -1 },
        observations: "",
        prolonged_treatment: false,
        dosage: "",
        has_coverage: false,
        requires_approval: false,
        discount: 0,
        is_psychopharmaceutical: false,
        is_narcotic: false,
        is_controlled_sale: false,
        is_for_hiv: false,
        requires_duplicate: false,
        drug_name: "",
        register_number: "",
      },
      list_medications: prescription_selected?.medications || [],
    },
  });
  const STEPS = [
    { component: First, position: "first" },
    { component: Second, position: "second" },
    { component: Third, position: "third" },
    { component: Fourth, position: "fourth" },
  ];
  const VALUE = STEPS.find(
    (_step, index) => index === currentPosition
  )?.position;
  async function onSubmit(values: z.infer<typeof formSchema>) {
    startLoading();
    const professional =
      professionals?.find(
        (p: Professional) => p.id_professional === values.id_professional
      ) || professionals?.[0];
    const response_signature = await getProfessionalSignature(
      professional?.id_professional
    );
    if (response_signature.error) {
      return toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description:
          "No se pudo recuperar la firma del profesional, por favor intentalo nuevamente.",
      });
    }

    const medicines = values.list_medications?.map(
      (m: { [key: string]: any }) => {
        let medicine = {
          productName: m.product_name,
          drugName: m.drug_name,
          presentation: m.presentation,
          registerNumber: m.register_number,
          quantity: m.quantity,
          allowSubstitution: m.not_replace ? "S" : "N",
          treatment: m.prolonged_treatment ? 1 : 0,
          diagnosis: m.diagnosis?.description,
          diagnosisCode: values.is_manual_diagnosis ? null : m.diagnosis?.code,
          dosage: m.dosage,
          observations: m.observations,
        };

        return medicine;
      }
    );
    const financier_filter =
      values.id_financier !== -1
        ? financiers?.find(
            (f: Financier) => f.id_financier === values.id_financier
          )
        : null;
    const { first_name, last_name } = splitNameFormatter(professional?.name);
    const { first_name: patient_name, last_name: patient_last_name } =
      splitNameFormatter(patient?.full_name);
    const {
      document_type,
      document_number,
      gender,
      date_of_birth,
      email,
      mobile_phone,
      city,
      affiliate_number,
      state,
      id_patient,
      plan_navigation,
    } = patient as Patient;
    const { name, tax_number, address } = user_params;
    const {
      abbreviation,
      tax_number: professional_tax_number,
      profession,
      licence_type,
      licence,
      licence_jurisdiction,
      id_professional,
    } = professional;

    const response = await newRecipe({
      ...values,
      medicines,
      professional: {
        first_name,
        last_name,
        abbreviation,
        tax_number: professional_tax_number,
        profession,
        signature: response_signature.professionalSignature,
        licence_type,
        licence,
        licence_jurisdiction,
        id_professional,
      },
      financier_filter,
      patient: {
        first_name: patient_name,
        last_name: patient_last_name,
        document_type: document_type || "DNI",
        document_number,
        gender,
        date_of_birth,
        email,
        mobile_phone,
        city,
        affiliate_number,
        state,
        id_patient,
        id_medical_insurance: plan_navigation?.id_med_ins,
      },
      prescriber: {
        name,
        tax_number,
        address,
      },
    });

    if (response?.error) {
      response.error.includes("40000")
        ? openDialog({
            content: <FreemiumForm />,
          })
        : toast({
            variant: "destructive",
            title: "Ups! Parece que hubo un error",
            description: response.error,
          });
    } else {
      toast({
        variant: "successful",
        title: "Receta creada con exito",
        description: "Los datos se veran reflejados en la tabla.",
      });
      const { prescriptions } = await updateSection(
        ["recetas"],
        patient.id_patient
      );
      updatePatient("prescriptions", prescriptions);
      onCancel();
    }

    stopLoading();
  }

  return (
    <Form {...form}>
      <form className="w-full grid grid-cols-12 mt-0 overflow-hidden">
        <CardTitle className="flex items-center gap-x-2 col-span-4 mb-2">
          <span
            onClick={onCancel}
            className={classNames(
              "cursor-pointer",
              buttonVariants({ variant: "outline", size: "icon" })
            )}
          >
            <LuChevronLeft className="size-4" />
            <span className="sr-only">Back</span>
          </span>
          {prescription_selected ? "Detalle de receta" : "Nueva receta"}
        </CardTitle>
        <Tabs
          defaultValue="first"
          value={VALUE}
          className="!w-full hidden lg:flex lg:flex-col col-span-full"
        >
          {!prescription_selected && (
            <ProgressBar
              length={4}
              stepsBar={steps}
              onClick={setStep}
              form={form}
            />
          )}
          {STEPS.map((Step: any, index: number) => (
            <TabsContent
              key={index}
              value={Step.position}
              className="!w-full !mt-0"
            >
              <Step.component
                form={form}
                data={{
                  professionals,
                  financiers,
                  offices: ENABLED_OFFICES,
                  users,
                }}
                setStep={setStep}
                resetSteps={resetSteps}
                onSubmit={
                  prescription_selected
                    ? () => {
                        onCancel();
                        setPrescriptionsSelected(null);
                      }
                    : form.handleSubmit(onSubmit)
                }
                viewLoadMedicine={{
                  hiddenLoadMedicine,
                  setHiddenLoadMedicine,
                }}
                onCancel={onCancel}
              />
            </TabsContent>
          ))}
        </Tabs>
      </form>
    </Form>
  );
};
