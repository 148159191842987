import {
  LuHouse as Home,
  LuCalendar as Calendar,
  LuCog as Cog,
  LuUsers as Users,
  LuChartBarBig as BarChartBig,
  LuFileText as FileText,
} from "react-icons/lu";
import {
  HiOutlineBanknotes as Banknotes,
  HiOutlineSparkles as Sparkies,
} from "react-icons/hi2";
import { Translations } from "@/types/general";
import { PermissionsSection } from "@/types/permissions";

export const SIDEBAR_STATISTICS = {
  navMain: [
    {
      title: "Finanzas",
      url: "finances",
    },
    {
      title: "Panel de control",
      url: "control",
    },
    {
      title: "Prestaciones realizadas",
      url: "benefits-made",
    },
    {
      title: "Presupuestos emitidos",
      url: "budgets",
    },
  ],
};

export const SIDEBAR_REPORTS = {
  navMain: [
    {
      title: "Auditor Bilog",
      url: "/dashboard/reports/auditor",
    },
  ],
};

export const SIDEBAR_MY_ACCOUNT = {
  navMain: [
    {
      title: "Datos generales",
      url: "/dashboard/my-account/general",
    },
    {
      title: "Suscripción",
      url: "/dashboard/my-account/subscription",
    },
    {
      title: "Productos",
      url: "/dashboard/my-account/products",
    },
  ],
};

export const SIDEBAR_ADMINISTRATION = {
  navMain: [
    {
      title: "Caja diaria",
      url: "/dashboard/administration/dailycashflow/patient_payments",
      items: [
        {
          title: "Pagos pacientes",
          url: "/dashboard/administration/dailycashflow/patient_payments",
          isActive: true,
        },
        {
          title: "Otros ingresos",
          url: "/dashboard/administration/dailycashflow/incomes",
          isActive: false,
          permission: ["Adm. Ingresos"],
        },
        {
          title: "Egresos varios",
          url: "/dashboard/administration/dailycashflow/expenses",
          isActive: false,
          permission: ["Adm. Egresos"],
        },
      ],
    },
    {
      title: "Movimientos",
      url: "/dashboard/administration/transactions/patient_payments",
      permission: ["Adm. Ingresos", "Adm. Egresos"],
      items: [
        {
          title: "Pagos de pacientes",
          url: "/dashboard/administration/transactions/patient_payments",
          isActive: false,
          permission: ["Adm. Ingresos", "Adm. Egresos"],
        },
        {
          title: "Otros ingresos",
          url: "/dashboard/administration/transactions/incomes",
          isActive: false,
          permission: ["Adm. Ingresos"],
        },
        {
          title: "Egresos varios",
          url: "/dashboard/administration/transactions/expenses",
          isActive: false,
          permission: ["Adm. Egresos"],
        },
      ],
    },
    {
      title: "Bancos",
      url: "/dashboard/administration/banks/movements",
      permission: ["Adm. Bancos"],
      items: [
        {
          title: "Movimientos",
          url: "/dashboard/administration/banks/movements",
          isActive: false,
          permission: ["Adm. Bancos"],
        },
        {
          title: "Bancos y cuentas",
          url: "/dashboard/administration/banks/accounts",
          isActive: false,
          permission: ["Adm. Bancos"],
        },
      ],
    },
    {
      title: "Proveedores",
      url: "/dashboard/administration/suppliers",
      items: null,
      permission: ["Adm. Proveedores"],
    },
    {
      title: "Tarjetas",
      url: "/dashboard/administration/cards",
      items: null,
      permission: ["Adm. Tarjetas"],
    },
  ],
};

export const getSidebarPatients = (
  region: string,
  is_supervisor: boolean,
  SECTION?: {
    enabled: boolean;
    sections: PermissionsSection | null;
  }
) => {
  const navMain = [
    {
      title: "Datos personales",
      url: "personal-data",
    },
    {
      title: "Prestaciones",
      url: "benefits",
    },
    {
      title: "Odontograma",
      url: "odontogram",
    },
    {
      title: "Historia clínica",
      url: "medical-history",
    },
    {
      title: "Pagos",
      url: "payments",
    },
    {
      title: "Presupuestos",
      url: "budget",
    },
    {
      title: "Laboratorios",
      url: "laboratory",
    },
    {
      title: "Turnos",
      url: "appointments",
    },
    {
      title: "Galería",
      url: "gallery",
    },
  ];

  if (region?.toLowerCase() === "arg") {
    navMain.splice(4, 0, {
      title: "Recetas",
      url: "prescriptions",
    });
  }

  const FILTERED_NAV = navMain.filter((item) => {
    if (SECTION) {
      return (
        SECTION.enabled &&
        SECTION?.sections &&
        SECTION?.sections[item.url]?.enabled
      );
    }
  });

  return !is_supervisor ? FILTERED_NAV : navMain;
};

export const getFirstAvailableUrl = (
  permissions: Record<string, boolean>,
  type: "tables" | "sistem"
) => {
  const TABLES_URLS = [
    { permission: "Tabla de Profesionales", url: "professionals" },
    { permission: "Tabla de Obras Sociales", url: "medical-insurances" },
    { permission: "Tabla de Laboratorios", url: "laboratories" },
    { permission: "Tabla de Especialidades", url: "specialties" },
    { permission: "Ver Dias no Laborables", url: "holidays" },
    { permission: "Tabla de Proveedores", url: "supplier" },
    { permission: "Tabla de Sucursales", url: "offices" },
  ];

  const SISTEMS_URL = [
    { permission: "Parametros del Sistema", url: "general-data" },
    { permission: "Usuarios del Sistema", url: "users" },
    { permission: "Parametros del Sistema", url: "whatsapp-data" },
    { permission: "Parametros del Sistema", url: "sms-data" },
    { permission: "Parametros del Sistema", url: "various-data" },
  ];

  const URLS = ["tables"].includes(type) ? TABLES_URLS : SISTEMS_URL;
  const FOUND_URL = URLS.find(({ permission }) => permissions[permission]);

  return FOUND_URL
    ? `/dashboard/configuration/${type}/${FOUND_URL?.url}`
    : null;
};

export const sideBarConfig = ({
  translations,
  permissions,
  is_supervisor,
  SECTION,
}: {
  permissions: { [key: string]: boolean };
  is_supervisor: boolean;
  translations: Translations;
  SECTION?: {
    enabled: boolean;
    sections: PermissionsSection | null;
  };
}) => {
  const NAV_MAIN = [
    {
      title: "Tablas",
      url: getFirstAvailableUrl(permissions, "tables"),
      key: "tables",
      items: [
        {
          title: "Profesionales",
          url: "/dashboard/configuration/tables/professionals",
          isActive: true,
        },
        {
          title: translations?.medical_insurances,
          url: "/dashboard/configuration/tables/medical-insurances",
          isActive: false,
        },
        {
          title: "Laboratorios",
          url: "/dashboard/configuration/tables/laboratories",
          isActive: false,
        },
        {
          title: "Especialidades",
          url: "/dashboard/configuration/tables/specialties",
          isActive: false,
        },
        {
          title: "Feriados",
          url: "/dashboard/configuration/tables/holidays",
          isActive: false,
        },
        {
          title: "Proveedores",
          url: "/dashboard/configuration/tables/supplier",
          isActive: false,
        },
        {
          title: "Sucursales",
          url: "/dashboard/configuration/tables/offices",
          isActive: false,
        },
      ],
    },
    {
      title: "Sistemas",
      key: "sistem",
      url: getFirstAvailableUrl(permissions, "sistem"),
      items: [
        {
          title: "Datos generales",
          url: "/dashboard/configuration/sistem/general-data",
          isActive: false,
        },
        {
          title: "Usuarios",
          url: "/dashboard/configuration/sistem/users",
          isActive: false,
        },
        {
          title: "Datos de whatsapp",
          url: "/dashboard/configuration/sistem/whatsapp-data",
          isActive: false,
        },
        {
          title: "Datos de sms",
          url: "/dashboard/configuration/sistem/sms-data",
          isActive: false,
        },
        {
          title: "Datos varios",
          url: "/dashboard/configuration/sistem/various-data",
          isActive: false,
        },
      ],
    },
  ];
  const filteredNav = NAV_MAIN.map((section) => {
    const SECTION_PERMISSIONS =
      SECTION?.enabled && SECTION?.sections && SECTION?.sections[section.key];

    if (!SECTION || !SECTION_PERMISSIONS || !SECTION_PERMISSIONS.enabled) {
      return null;
    }

    const FILTERED_SECTION =
      section.items &&
      section.items.length > 0 &&
      section.items.filter((item) => {
        const [sectionKey] = item.url.split("/").slice(-1);
        return SECTION_PERMISSIONS.sections?.[sectionKey]?.enabled ?? false;
      });

    return {
      ...section,
      items: FILTERED_SECTION,
    };
  }).filter(Boolean);

  return is_supervisor ? NAV_MAIN : filteredNav;
};
export const SIDEBAR_PERMISSIONS = {
  navMain: [
    {
      title: "Inicio",
      icon: <Home className="!size-5 text-slate-500" />,
      value: "inicio",
    },
    {
      title: "Administración",
      icon: <Banknotes className="!size-5 text-slate-500" />,
      value: "administracion",
    },
    {
      title: "Agenda",
      icon: <Calendar className="!size-5 text-slate-500" />,
      value: "agenda turnos",
    },
    {
      title: "Informes",
      icon: <FileText className="!size-5 text-slate-500" />,
      value: "informes",
    },
    {
      title: "Pacientes",
      icon: <Users className="!size-5 text-slate-500" />,
      value: "pacientes",
    },
    {
      title: "Sistema",
      icon: <Cog className="!size-5 text-slate-500" />,
      value: "sistema",
    },
    {
      title: "Socios",
      icon: <Sparkies className="!size-5 text-slate-500" />,
      value: "socios",
    },
    {
      title: "Tablas",
      icon: <BarChartBig className="!size-5 text-slate-500" />,
      value: "tablas",
    },
  ],
};
