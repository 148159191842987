"use client";

import React from "react";
import { ChartPie, Card } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { numberToCurrency } from "@/utils/currency";
import { useBoundStore } from "@/hooks";
import { BenefitMade } from "@/types/statistics";

export const BenefitsMadeGraphics = ({
  data,
  device,
}: {
  data: BenefitMade;
  device: string;
}) => {
  const {
    statistics: {
      "benefits-made": { data: values },
    },
    general_params: { translations },
    setInitializedDataStatistics,
  } = useBoundStore();

  React.useEffect(() => {
    setInitializedDataStatistics({ data, section: "benefits-made" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const PIES_VALUES = React.useMemo(
    () => [
      {
        title: translations?.medical_insurance,
        data: values?.med_ins_amount_data_group,
        data_key: "medical_insurance_amount",
        total_amount: numberToCurrency(values?.total_amount_medical_insurance),
      },
      {
        title: "Pacientes",
        data: values?.patients_amount_data_group,
        data_key: "patient_amount",
        total_amount: numberToCurrency(values?.total_amount_patient),
      },
    ],
    [values]
  );

  return (
    <div className="mb-10 lg:grid lg:placeholder lg:grid-cols-12 gap-5 flex flex-col">
      {PIES_VALUES.map((item: any, index: number) => (
        <Card
          className="mb-4 lg:mb-0 col-span-6 border flex flex-col border-slate-300 rounded-md p-8 px-6 w-auto !shadow-md h-auto"
          key={index}
        >
          <h3 className="text-slate-800 text-lg font-bold">{item.title}</h3>
          {item.data ? (
            <>
              <p className="text-blue-500 text-lg">
                {item.total_amount} Totales
              </p>
              <ChartPie
                values={item.data}
                dataKey={item.data_key}
                device={device}
              />
            </>
          ) : (
            <EmptyState title="Ups!" copy="No hay datos para mostrar" />
          )}
        </Card>
      ))}
    </div>
  );
};
