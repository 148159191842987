"use client";

import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Badge, Checkbox } from "@/components/atoms";
import { useBoundStore } from "@/hooks";

type RowDataT = {
  original: any;
};

export const Columns = (): ColumnDef<any>[] => {
  const {
    general_params: { translations },
  } = useBoundStore();
  const cols = [
    {
      id: "actions",
      header: ({ table }: { table: any }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => {
            table.toggleAllPageRowsSelected(!!value);
          }}
          aria-label="Seleccionar todo"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }: { row: any }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Seleccionar fila"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "code_med_ins",
      header: "Código",
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { code_med_ins } = row.original;

        return <Badge className="w-auto">{`Cód. ${code_med_ins}`}</Badge>;
      },
    },
    {
      accessorKey: "medical_insurance",
      header: translations?.medical_insurance,
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { medical_insurance } = row.original;

        return (
          <p className="font-semibold max-w-48 text-sm text-slate-800 overflow-hidden text-ellipsis text-nowrap 2xl:text-wrap lg:text-sm my-1">
            {medical_insurance}
          </p>
        );
      },
    },
  ];

  return cols;
};
