import React from "react";
import { useRouter } from "next/navigation";
import {
  HiPencil,
  HiTrash,
  HiOutlineIdentification,
  HiOutlineCheckCircle,
} from "react-icons/hi";
import {
  LuCalendar,
  LuCircleSlash,
  LuClock,
  LuUserPlus,
  LuUsers,
} from "react-icons/lu";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DialogTrigger,
} from "@/components/atoms";
import { Appointment } from "@/types/general";

export const ContextMenu: React.FC<{
  type: string;
  appointment: Appointment;
  actions: any;
  permissions: any;
}> = ({ type, appointment, permissions, actions }) => {
  const router = useRouter();
  const {
    giveOverturn,
    disableAppointment,
    editObservation,
    newTurnExistingPatient,
    newTurnNewPatient,
    viewPatientData,
  } = permissions;
  const SHOW_SECTION_PATIENT_EXIST =
    giveOverturn || editObservation || disableAppointment;
  const { id_patient } = appointment;

  const renderActions: { [key: string]: any } = {
    free: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          {(newTurnExistingPatient || newTurnNewPatient) && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger id="new_appointment">
                <LuClock className="mr-2 h-4 w-4" />
                <span>Nuevo turno</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <>
                    <DialogTrigger asChild>
                      {newTurnExistingPatient && (
                        <DropdownMenuItem
                          id="patient_exist"
                          className="hover:cursor-pointer"
                          onSelect={() =>
                            actions({
                              appointment,
                              type: "new_appointment_patient_exist",
                              attendance: appointment.attendance,
                            })
                          }
                        >
                          <LuUsers type="user" className="mr-2 size-4" />
                          <span>Paciente existente</span>
                        </DropdownMenuItem>
                      )}
                    </DialogTrigger>
                    <DialogTrigger asChild>
                      {newTurnNewPatient && (
                        <DropdownMenuItem
                          id="new_patient"
                          className="hover:cursor-pointer"
                          onSelect={() =>
                            actions({
                              appointment,
                              type: "new_appointment_new_patient",
                              attendance: appointment.attendance,
                            })
                          }
                        >
                          <LuUserPlus className="mr-2 size-4" />
                          <span>Paciente nuevo</span>
                        </DropdownMenuItem>
                      )}
                    </DialogTrigger>
                  </>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}
          <DialogTrigger asChild>
            {giveOverturn && (
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "new_over_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <LuCalendar className="mr-2 size-4" />
                <span>Nuevo sobreturno</span>
              </DropdownMenuItem>
            )}
          </DialogTrigger>
          {disableAppointment && (
            <DialogTrigger asChild>
              <DropdownMenuItem
                className="hover:cursor-pointer text-red-500"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "disabled_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <LuCircleSlash className="mr-2 size-4" />
                <span>Anular horario</span>
              </DropdownMenuItem>
            </DialogTrigger>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    ),
    patient_exist: () => (
      <DropdownMenuContent className="w-56" align="start">
        {SHOW_SECTION_PATIENT_EXIST && (
          <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        )}
        <DialogTrigger asChild>
          <DropdownMenuGroup>
            {giveOverturn && (
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "new_over_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <LuCalendar className="mr-2 size-4" />
                <span>Nuevo sobreturno</span>
              </DropdownMenuItem>
            )}
            {editObservation && (
              <DropdownMenuItem
                id="edit_observation"
                className="hover:cursor-pointer"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "edit_observation",
                    attendance: appointment.attendance,
                  })
                }
              >
                <HiPencil className="mr-2 size-4" />
                <span>Modificar observación</span>
              </DropdownMenuItem>
            )}
            {disableAppointment && (
              <DropdownMenuItem
                id="delete_appointment"
                className="hover:cursor-pointer text-red-500"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "delete_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <HiTrash className="mr-2 size-4" />
                <span>Eliminar turno</span>
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DialogTrigger>
        {id_patient && viewPatientData && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>Paciente</DropdownMenuLabel>
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() =>
                  router.push(
                    `/dashboard/patients/personal-data?id_patient=${id_patient}`
                  )
                }
              >
                <HiOutlineIdentification className="mr-2 size-4" />
                <span>Ir a datos del paciente</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </>
        )}
      </DropdownMenuContent>
    ),
    patient_first_time: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DialogTrigger asChild>
          <DropdownMenuGroup>
            {giveOverturn && (
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "new_over_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <LuCalendar className="mr-2 size-4" />
                <span>Nuevo sobreturno</span>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() =>
                actions({
                  appointment,
                  type: "new_appointment_new_patient",
                  attendance: appointment.attendance,
                })
              }
            >
              <HiPencil className="mr-2 size-4" />
              <span>Modificar turno</span>
            </DropdownMenuItem>
            {disableAppointment && (
              <DropdownMenuItem
                id="delete_appointment"
                className="hover:cursor-pointer text-red-500"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "delete_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <HiTrash className="mr-2 size-4" />
                <span>Eliminar turno</span>
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DialogTrigger>
      </DropdownMenuContent>
    ),
    canceled: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DialogTrigger asChild>
          <DropdownMenuGroup>
            {giveOverturn && (
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "new_over_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <LuCalendar className="mr-2 size-4" />
                <span>Nuevo sobreturno</span>
              </DropdownMenuItem>
            )}
            {disableAppointment && (
              <DropdownMenuItem
                className="hover:cursor-pointer text-green-500"
                onSelect={() =>
                  actions({
                    appointment,
                    type: "delete_appointment",
                    attendance: appointment.attendance,
                  })
                }
              >
                <HiOutlineCheckCircle className="mr-2 size-4" />
                <span>Habilitar horario</span>
              </DropdownMenuItem>
            )}
          </DropdownMenuGroup>
        </DialogTrigger>
      </DropdownMenuContent>
    ),
  };

  return renderActions[type]();
};
