"use client";

import React from "react";
import { Holyday } from "@/types/general";
import { useBoundStore } from "@/hooks";
import { Skeleton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{
  data?: Holyday[];
}> = ({ data }) => {
  const { loading } = useBoundStore();

  return loading ? (
    <div className="grid grid-cols-4 gap-4 mx-auto my-4">
      <Skeleton className="col-span-5 h-[calc(100vh-153px)] rounded-xl" />
    </div>
  ) : !data?.length ? (
    <EmptyState
      title="Ups! no tienes cargado ningun feriado."
      copy="Puedes cargar un feriado para empezar."
    />
  ) : (
    <Data columns={columns} data={data} />
  );
};
