import React from "react";
import {
  Button,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms";
import { ButtonLoading, FreemiumForm } from "@/components/molecules";
import { deleteArticle } from "@/actions/laboratories";
import { toast, useDialog } from "@/hooks";
import { LaboratoryConcept } from "@/types/general";
import { useBoundStore } from "@/hooks";

export const DeleteWork = ({
  labConcept,
}: {
  labConcept: LaboratoryConcept;
}) => {
  const {
    startLoading,
    stopLoading,
    loading,
    user: { plan_version },
  } = useBoundStore();
  const { closeDialog, openDialog } = useDialog();

  const onSubmit = async () => {
    if (["lender", "freemium"].includes(plan_version)) {
      return openDialog({
        content: <FreemiumForm />,
      });
    }

    startLoading();
    const response = await deleteArticle(labConcept);

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Se ha eliminado con éxito",
        description: `El trabajo de ${labConcept?.code} se ha eliminado de base de datos.`,
      });
      closeDialog();
    }

    stopLoading();
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Eliminar prestación</DialogTitle>
      </DialogHeader>
      <span className="text-slate-500">
        Estás por eliminar este grupo y esta acción es irreversible.
      </span>
      <div className="flex justify-end gap-x-5">
        <DialogClose asChild>
          <Button type="button" variant="destructive">
            Cancelar
          </Button>
        </DialogClose>
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button onClick={onSubmit}>Eliminar</Button>
        )}
      </div>
    </DialogContent>
  );
};
