import React from "react";
import classNames from "classnames";
import { Financier } from "@/types/recipes";
import { Form, Offices, Professional, User } from "@/types/general";
import { useBoundStore } from "@/hooks";
import { Button, CardTitle } from "@/components/atoms";
import { useStateAcordeon } from "@/hooks";
import { ButtonLoading } from "@/components/molecules";
import { Header } from "./card-header";
import { SummarySteps } from "./card-summary";
import { formSchema } from "../schema";

export const Fourth: React.FC<{
  data: {
    financiers: Financier[];
    offices: Offices[];
    professionals: Professional[];
    users: User[];
    recipe: any;
  };
  onClick: () => void;
  viewLoadMedicine: {
    hiddenLoadMedicine: boolean;
    setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
  };
  setStep: (value: number) => void;
  onSubmit: () => void;
  form: Form<typeof formSchema>;
  resetSteps: () => void;
  onCancel: () => void;
}> = ({
  setStep,
  data: { professionals, offices, financiers, users },
  viewLoadMedicine,
  onSubmit,
  form,
  resetSteps,
  onCancel,
}) => {
  const {
    id_professional,
    id_office,
    id_financier,
    general_diagnosis,
    date_of_recipe,
    plan,
    affiliate_number,
    list_medications,
  } = form.watch();
  const { ref_header, loading, prescription_selected } = useBoundStore();
  const STATE_ACCORDION = useStateAcordeon(ref_header);
  const professional_name: string =
    id_professional && professionals.length > 0
      ? professionals?.find((p: any) => p.id_professional === id_professional)
          ?.name || ""
      : "-";
  const office_name =
    id_office &&
    offices?.length > 0 &&
    offices?.find((o: Offices) => o.id_office === id_office)?.name;
  const med_ins_name =
    id_financier &&
    financiers.length > 0 &&
    financiers?.find((f: Financier) => f.id_financier === id_financier)?.name;

  const emmittedBy = users?.find(
    (u: User) => u?.id_user === prescription_selected?.id_user
  )?.name;
  const dataSummary = {
    header: {
      professional_name,
      date_of_recipe,
      setStep,
      prescription_selected,
      office_name,
      plan,
      affiliate_number,
      med_ins_name,
    },
    summarySteps: {
      viewLoadMedicine,
      list_medications,
      general_diagnosis,
      user_name: emmittedBy,
      prescription_selected,
    },
  };
  React.useMemo(() => {
    if (list_medications?.length <= 0) {
      resetSteps();
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list_medications]);

  return (
    <>
      <div
        className={classNames(
          "rounded-md overflow-y-auto !w-full h-[calc(100vh-310px)]",
          {
            "!h-[calc(100vh-375px)]":
              STATE_ACCORDION && STATE_ACCORDION !== "closed",
          }
        )}
      >
        <CardTitle
          className={classNames("text-lg mt-6 mb-2", {
            hidden: prescription_selected,
          })}
        >
          Detalles de recetas
        </CardTitle>
        <span>
          {prescription_selected
            ? "Vea los detalles de la prescripción. Los datos no podrán ser editados porque la receta ya fue generada."
            : "Vea los detalles de la prescripción y edite de ser necesario."}
        </span>
        {[Header, SummarySteps].map((Component: any, index: number) => (
          <Component
            key={index}
            data={dataSummary}
            setStep={setStep}
            form={form}
          />
        ))}
      </div>
      <div className="flex justify-end mt-5 gap-5">
        {prescription_selected && (
          <Button
            variant="ghost"
            type="button"
            onClick={() =>
              window.open(prescription_selected.file_link, "_blank")
            }
          >
            Descargar PDF
          </Button>
        )}
        {!prescription_selected && (
          <Button
            onClick={onCancel}
            className="flex justify-center w-max px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
          >
            Cancelar
          </Button>
        )}
        {loading ? (
          <ButtonLoading />
        ) : (
          <Button type="button" onClick={onSubmit}>
            {prescription_selected ? "Volver" : "Generar receta"}
          </Button>
        )}
      </div>
    </>
  );
};
