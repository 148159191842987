"use client";

import * as React from "react";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogClose,
  Button,
} from "@/components/atoms";
import { Form, ButtonLoading } from "@/components/molecules";
import { setCloseDailyCashflow } from "@/actions/administration";
import { useBoundStore } from "@/hooks";
import { toast, useKeypress } from "@/hooks";
import { Form as FormT } from "@/types/general";
import { formSchema } from "@/components/organisms/forms/administration/dailycashflow";

export const CashReconciliationForm = ({
  form,
  idUser,
}: {
  form: FormT<typeof formSchema>;
  idUser: number;
}) => {
  const { loading, startLoading, stopLoading } = useBoundStore();

  async function onSubmit() {
    startLoading();
    const { date_from, id_office } = form.getValues();

    const response = await setCloseDailyCashflow({
      date_from,
      id_user: idUser,
      id_office,
    });

    if (response?.error) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response.error,
      });
    } else {
      toast({
        variant: "successful",
        title: "Cierre realizado con éxito!",
        description: "El cierre de la caja se realizó con éxito.",
      });
    }

    stopLoading();
  }

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Cierre de caja diaria</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <p className="text-sm text-slate-600">
            Se realizará el cierre de la caja diaria. Esta acción cerrará la
            fechas marcada y todas las anteriores y no se podrán editar, ni
            agregar más pagos, ingresos o egresos a estas fechas.
          </p>
          <p className="text-sm text-slate-800 font-bold">
            ¿Estás seguro que desea cerrar la caja diaria?
          </p>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="destructive">Cancelar</Button>
            </DialogClose>
            <DialogClose asChild>
              {loading ? (
                <ButtonLoading />
              ) : (
                <Button type="submit">Cerrar caja</Button>
              )}
            </DialogClose>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
};
