"use client";

import React from "react";
import { z } from "zod";
import { Button, SheetClose, Separator } from "@/components/atoms";
import { toast, useKeypress } from "@/hooks";
import { Form, DatePicker, ButtonLoading } from "@/components/molecules";
import { Suppliers } from "@/components/molecules/params";
import { getSuppliersTransactions } from "@/actions/administration";
import { useBoundStore } from "@/hooks";
import { Supplier, Form as FormProp } from "@/types/general";
import { formSchema } from "./schema";

export function SuppliersForm({
  form,
  values,
}: {
  form: FormProp<typeof formSchema>;
  values: {
    suppliers: Supplier[];
  };
  device: string;
}) {
  const { setDataAdministration, startLoading, stopLoading, loading } =
    useBoundStore();
  const { suppliers } = values;

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    startLoading();
    const response = await getSuppliersTransactions(values);

    if ("error" in response) {
      toast({
        variant: "destructive",
        title: "Ups! Parece que hubo un error",
        description: response?.error,
      });
    } else {
      setDataAdministration({
        data: response.suppliers,
        params: values,
        section: "suppliers",
      });
    }

    stopLoading();
  };

  useKeypress({ key: "Enter", action: form.handleSubmit(onSubmit) });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className="bg-transparent rounded-md space-y-5 justify-start flex flex-col h-dvh"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)" }}
        >
          <DatePicker label="Fecha desde" name="date_from" form={form} />
          <DatePicker label="Fecha hasta" name="date_to" form={form} />
          <Separator className="bg-slate-200 h-4" />
          <Suppliers suppliers={suppliers} form={form} />
        </div>
        <div className="w-full lg:w-auto flex flex-row justify-center items-center lg:justify-end gap-x-5 mt-8">
          <SheetClose asChild>
            <Button
              type="button"
              onClick={() => form.reset()}
              className="w-full"
              variant="destructive"
            >
              Cancelar
            </Button>
          </SheetClose>
          {loading ? (
            <ButtonLoading />
          ) : (
            <SheetClose asChild>
              <Button type="submit" className="w-full">
                Guardar
              </Button>
            </SheetClose>
          )}
        </div>
      </form>
    </Form>
  );
}
