import React from "react";
import { useBoundStore } from "@/hooks";
import { EmptyState } from "@/components/molecules";
import { Loading } from "@/components/organisms/reports/loading";
import { PaymentBudget } from "@/types/patients";
import { columns } from "./columns";
import { Data } from "./data";

export const Table: React.FC<{ data: PaymentBudget[] }> = ({ data }) => {
  const { loading } = useBoundStore();

  return (
    <div className="w-auto col-span-full">
      {loading ? (
        <Loading heigth="300px" />
      ) : data.length ? (
        <Data columns={columns} data={data} />
      ) : (
        <EmptyState
          title="No hay pagos para mostrar."
          copy="Puedes hacer un pago desde la sección de pagos."
          className="col-span-full"
        />
      )}
    </div>
  );
};
